import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Resource } from '../models/resource';
import { AuthService } from './auth.service';
import { ResourceApiService } from './resource-api.service';

@Injectable({
  providedIn: 'root'
})
export class InstructorGuard implements Resolve<Resource> {

  constructor(
    private resourceService: ResourceApiService,
    private authService: AuthService,
    ){}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Resource | Observable<Resource> | Promise<Resource> {
    const username = this.authService.getUser().username;
    const roles = this.authService.getUser().roles;
    if(roles.includes('ROLE_INSTRUCTOR') || roles.includes('ROLE_SITE_MANAGER_INSTRUCTOR')){
      return this.resourceService.findResourceByUsername(username);
    }
    return of(null);
  }

}
