import { OnInit, Component, Input } from '@angular/core';

@Component({
    selector: 'progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.css']
})
export class ProgressBar implements OnInit {
    @Input() bars: any[] = [];
    @Input() bottomLabels: any[] = [];
    @Input() pointer: any;

    constructor() {}
    ngOnInit() {
        for(let bottomLabel of this.bottomLabels) {
            let position: string = bottomLabel.position.split("%")[0];
            bottomLabel.position = (+position - (position.length - 1) * 2) + "%";
        }
    }
}