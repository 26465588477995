import {Component, Input} from '@angular/core';

export interface SkillData {
  identifier: string;
  label: string;
  tooltip?: string;
}

export interface HeaderData {
  identifier: string;
  label: string;
  tooltip?: string;
  skills?: SkillData[];
}

interface CellData {
  tooltip: string;
  spans: {
    label: string,
    color: string
  }[];
}

@Component({
  selector: 'app-cross-table',
  templateUrl: './cross-table.component.html',
  styleUrls: ['./cross-table.component.css']
})
export class CrossTableComponent {
  constructor() {
  }

  @Input() data: any[];
  @Input() columnsGroup: HeaderData[] = [];
  @Input() columnsSubgroup: HeaderData[] = [];
  leftHeaderHeight = '4em';
  groupData: any[];
  subgroupData: any[];

  matchCellToHeader(resourceSkills: SkillData[], skillIdentifier: string): CellData {
    const matchedSkill = resourceSkills.find(skill => skill.identifier === skillIdentifier);
    return {tooltip: matchedSkill ? matchedSkill.tooltip : '', spans: this.text(matchedSkill)};
  }

  text(matchedSkill?: SkillData): CellData['spans'] {
    const splitedCellData = matchedSkill ? matchedSkill.label.split('-').map(text => ({
      label: text,
      color: this.color(text)
    })) : [{label: 'n/a', color: '#a1a1a1'}];
    if (splitedCellData.length > 1) {
      splitedCellData[2] = splitedCellData[1];
      splitedCellData[1] = {label: '-', color: 'black'};
    }
    return splitedCellData;
  }

  class(identifier: string): string {
    switch (identifier) {
      case 'resourceName':
        return 'col-5';
      case 'positionName':
        return 'col-5';
      case 'modality':
        return 'col-2';
    }
  }

  color(text: string): string {
    switch (text) {
      case 'D':
        return 'rgb(19, 181, 243)';
      case 'O':
        return 'rgb(0, 68, 132)';
      case 'NR':
        return 'rgb(244, 67, 54)';
      case 'R':
        return 'rgb(46, 211, 101)';
    }
  }
}
