import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {environment} from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'talents-frontend';

  constructor(translate: TranslateService) {
    translate.setDefaultLang(environment.defaultLang);
    const lang = localStorage.getItem('lang');
    if(lang) {
      translate.use(lang);
    } else {
      translate.use(environment.defaultLang);
    }
  }

}
