import { InputComponent } from './../../components/dialog/input/input.component';
import { DialogService } from 'src/app/shared/dialog.service';
import { SKILL_MATRIX_REPORT } from './../../api-urls';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SearchField } from 'src/app/models/search-field.';
import { TalentApiService } from 'src/app/shared/api.service';
import { skillLevelTypes } from 'src/app/util-json.data';
import { BreadcrumbAction } from 'src/app/components/breadcrumb/breadcrumb-action';
import { BreadcrumbComponent } from 'src/app/components/breadcrumb/breadcrumb.component';

@Component({
  selector: 'app-skill-matrix',
  templateUrl: './skill-matrix.component.html',
  styleUrls: ['./skill-matrix.component.css']
})
export class SkillMatrixComponent implements OnInit {
  paths: string[] = ['menu.reports','/','reportMenu.skillMatrix']
  breadcrumbActions: BreadcrumbAction[] = [
    {
			label: 'label.exportExcel',
      style: 'generic-button',
      disabled: true,
			do: () => {
				this.generateExcel();
			}
		},
    {
			label: 'label.exportPdf',
      style: 'generic-button',
      disabled: true,
			do: () => {
				this.generatePdf();
			}
		},
    {
      label: 'label.back',
      router: '..',
      style: 'generic-button'
    },
  ]
  showTable = false;
  searchUrl = SKILL_MATRIX_REPORT;

  resources: any = [];
  columns: any[] = [];
  filter: SearchField[];
  types = skillLevelTypes;
  filterFields = [
    'companyUnitRequired',
    'department',
    'position',
    'resource'
  ];

  @ViewChild(BreadcrumbComponent) breadcrumb: BreadcrumbComponent

  changeDisableButton(disable: boolean){
    this.breadcrumb.changeDisabledButton({ indice: 1, disable: disable });
    this.breadcrumb.changeDisabledButton({ indice: 0, disable: disable });
  }

  constructor(private apiService: TalentApiService<void>,
    private dialogService: DialogService) { }

  ngOnInit() {
  }

  results(response) {
    const { result, filter } = response;

    if (!Array.isArray(result) || result.length === 0) {
      this.clear();
      this.dialogService.openInformationDialog("reportMenu.emptyData");
      return;
    }

    if (result.length === 1 && !result[0].skillsData) {
      this.clear();
      this.dialogService.openInformationDialog("reportMenu.emptyProcedure");
      return;
    }

    this.columns = ["label.resourceName", "label.positionName"];
    this.filter = filter;
    this.resources = result;

    for (const resource of result) {
      const { skillsData } = resource;

      if (skillsData) {
        Object.keys(skillsData).forEach((skill) => {
          resource[skill] = skillsData[skill];
          const columnKey = `style.${skill}`;
          if (!this.columns.includes(columnKey)) {
            this.columns.push(columnKey);
          }
        });
      }
    }

    this.showTable = true;
    this.changeDisableButton(false);
  }

  generateExcel() {
    this.dialogService.openCustomSizeDialog('390px',InputComponent, true, {
      title: 'label.generateExcel',
      subtitle: 'label.subtitleGenerateFile',
      subtitleRightButton: "label.generate",
      inputName: 'label.fileName'
    }).afterClosed().subscribe(fileName => {
      if(!!fileName)
       this.apiService.buildUrl(SKILL_MATRIX_REPORT + "/xlsx/" + fileName, this.filter,fileName,'xlsx');
    });
  }

  generatePdf(){
    this.dialogService.openCustomSizeDialog('390px',InputComponent, true, {
      title: 'label.generatePdf',
      subtitle: 'label.subtitleGenerateFile',
      subtitleRightButton: "label.generate",
      inputName: 'label.fileName'
    }).afterClosed().subscribe(fileName => {
      if(!!fileName)
       this.apiService.buildUrl(SKILL_MATRIX_REPORT + "/pdf/"+fileName, this.filter,fileName,'pdf');
    });
  }
  clear(){
    this.showTable = false;
    this.resources = [];
    this.columns = [];
    this.changeDisableButton(true);
  }
}
