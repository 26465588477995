import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {
  extraMessage: any;
  showComplementaryMessage:boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<AlertComponent>) {
      this.extraMessage = { value: data.extraMessage };
      if(data.complementaryMessage != undefined){
        this.showComplementaryMessage = true;
      }
     }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

}
