import { ProcedimentoFormComponent } from "./../../views/registration/procedimento/procedimento-form/procedimento-form.component";
import { DepartamentoFormComponent } from "./../../views/registration/departamento/departamento-form/departamento-form.component";
import { DialogService } from "src/app/shared/dialog.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { TableAction } from "../table/table-action";
import { TalentApiService } from "src/app/shared/api.service";
import { User } from "src/app/models/user";
import { PageEvent } from "@angular/material";
import { TableComponent } from "../table/table.component";
import { debounceTime } from "rxjs/operators";
import { SiteFormComponent } from "src/app/views/registration/site/site-form/site-form.component";
import { CargoFormComponent } from "src/app/views/registration/cargo/cargo-form/cargo-form.component";
import { ConhecimentoFormComponent } from "src/app/views/registration/conhecimento/conhecimento-form/conhecimento-form.component";
import { Router } from "@angular/router";

@Component({
  selector: "app-base-form",
  template: "<h1></h1>",
})
export class BaseRegistrationComponent<T> implements OnInit {
  actions: TableAction[] = [
    {
      name: "label.edit",
      icon: "create",
      color: "",
      roles: ["ROLE_ADMIN", "ROLE_MANAGER"],
      do: (row: T) => {
        this.edit(row);
      },
    },
    {
      name: "label.delete",
      icon: "delete",
      color: "warn",
      roles: ["ROLE_ADMIN"],
      do: (row: T) => {
        this.delete(row);
      },
    },
  ];

  user: User;

  public data: T[] = [];
  public companyInfo: string[] = [];

  objectApi: string;
  objectType: string;
  editComponent: any;

  totalLength = 0;
  pageSize = 5;
  pageNumber = 0;
  queryValue = "";
  router: Router;
  @ViewChild(TableComponent) table: TableComponent;

  constructor(
    public dialogService: DialogService,
    public apiService: TalentApiService<T>,
    public useDatabasePagination: boolean = false
  ) {}

  ngOnInit() {
    this.refresh();
    this.table.query.pipe(debounceTime(1000)).subscribe((query) => {
      if (this.pageSize == undefined) {
        this.pageSize = 5;
      }
      this.doQuery(query);
    });
  }

  refresh() {
    if (this.useDatabasePagination) {
      let pageSize = this.pageSize == undefined ? 5 : this.pageSize;

      this.apiService
        .listPaginatedObject(this.objectApi, pageSize, this.pageNumber)
        .subscribe((response: any) => {
          this.data = response.content;
          this.totalLength = response.totalElements;
          this.pageSize = response.pageSize;
          this.table.paginator.pageIndex = response.number;
        });
    } else {
      this.apiService
        .listAllObjects(this.objectApi)
        .subscribe((response: any) => {
          this.data = response;
          this.totalLength = response.totalElements;
          this.pageSize = response.pageSize;
          this.pageNumber = 0;
        });
    }
  }

  edit(row: T) {
    if (this.editComponent == null) {
      this.editObject(row);
    } else {
      this.dialogService
        .openCustomDialog(this.editComponent, true, row)
        .afterClosed()
        .subscribe(() => this.refresh());
    }
  }

  pageChange(event: PageEvent) {
    if (this.queryValue === "") {
      this.apiService
        .listPaginatedObject(this.objectApi, event.pageSize, event.pageIndex)
        .subscribe((response: any) => {
          this.data = response.content;
          this.totalLength = response.totalElements;
          this.pageSize = event.pageSize;
        });
    } else {
      this.apiService
        .doQuery(
          this.objectApi,
          this.queryValue,
          event.pageSize,
          event.pageIndex
        )
        .subscribe((response) => {
          this.data = response.content;
          this.totalLength = response.totalElements;
          this.pageSize = event.pageSize;
        });
    }
  }

  doQuery(query) {
    this.queryValue = query;
    if (query === "") {
      this.refresh();
    } else {
      this.apiService
        .doQuery(this.objectApi, query, this.pageSize, this.pageNumber)
        .subscribe((response) => {
          this.data = response.content;
          this.totalLength = response.totalElements;
          this.table.paginator.pageIndex = response.number;
        });
    }
  }

  delete(row: T) {
    console.log(this.objectType);
    const field = this.getField(row);
    this.dialogService
      .openConfirmDialog("message.remove" + this.objectType, field)
      .afterClosed()
      .subscribe((confirm) => {
        if (confirm) {
          this.apiService
            .deleteObject(this.objectApi, row["id"])
            .subscribe((): void => {
              this.dialogService.openInformationDialog(
                "message.deleted" + this.objectType,
                field
              );
              this.refresh();
            });
        }
      });
  }

  getField(row: T) {
    switch (this.objectType) {
      case "Skill":
        return row["acronym"];
      case "UserRegister":
        return row["username"];
      case "CompanyUnit":
        return row["reference"];
      case "Course":
        return row["reference"];
      case "Proficiency":
        return row["level"];
      default:
        return row["name"];
    }
  }

  @ViewChild(SiteFormComponent) siteForm: SiteFormComponent;
  @ViewChild(DepartamentoFormComponent)
  departmentForm: DepartamentoFormComponent;
  @ViewChild(ProcedimentoFormComponent) skillForm: ProcedimentoFormComponent;
  @ViewChild(CargoFormComponent) positionForm: CargoFormComponent;
  @ViewChild(ConhecimentoFormComponent)
  proficiencyForm: ConhecimentoFormComponent;
  editObject(row: T) {
    switch (this.objectType) {
      case "CompanyUnit":
        this.siteForm.fillForm(row);
        break;
      case "Department":
        this.departmentForm.fillForm(row);
        break;
      case "Skill":
        this.skillForm.fillForm(row);
        break;
      case "Position":
        this.positionForm.fillForm(row);
        break;
      case "Proficiency":
        this.proficiencyForm.fillForm(row);
        break;
    }
  }
  create() {
    if (this.objectType == "Course") {
      this.router.navigate(["/main/registration/curso/create"]);
    } else {
      this.dialogService
        .openCustomDialog(this.editComponent, true)
        .afterClosed()
        .subscribe(() => this.refresh());
    }
  }

  savedObject(response: any, pageSize?: any) {
    if (response) {
      if (this.useDatabasePagination) {
        this.pageSize = 5;
      }
      this.refresh();
    }
  }
}
