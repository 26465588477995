import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.states';
import { LogOut } from 'src/app/store/actions/user.actions';
import { AuthService } from 'src/app/shared/auth.service';
import { User } from 'src/app/models/user';
import {Router} from "@angular/router";
import {TrainingHelpService} from '../../shared/training-help.service';
import {ResourceRegisterHelpService} from '../../shared/resource-register-help.service';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.css']
})
export class RootComponent implements OnInit {

  user: User;

  constructor(
    private translate: TranslateService,
    private authStore: Store<AppState>,
    private authService: AuthService,
    private router: Router,
    private trainingHelpService: TrainingHelpService,
    private resourceRegisterHelpService: ResourceRegisterHelpService
  ) {
    this.user = this.authService.getUser();
  }

  changeTranslation(translation) {
    localStorage.setItem('lang', translation);
    this.translate.use(translation);
  }

  ngOnInit() {
    this.isExpanded = (localStorage.getItem('expandedMenu') === 'true');
  }

  isExpanded = true;

  get someHelpEnabled(){
    return this.trainingHelpService.isEnabled
      || this.resourceRegisterHelpService.isEnabled
  }

  expand() {
    this.isExpanded = !this.isExpanded;
    localStorage.setItem('expandedMenu', `${this.isExpanded}`);
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  help() {
    console.log('yo');
  }

  showHelpForCurrent() {
    this.trainingHelpService.openCurrentHelp();
    this.resourceRegisterHelpService.openCurrentHelp();
  }
}
