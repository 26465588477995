import {Component, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableAction } from 'src/app/components/table/table-action';
import { PositionHistoryDialogComponent } from 'src/app/dialogs/position-history-dialog/position-history-dialog.component';
import { PositionHistory } from 'src/app/models/position-history';
import { DialogService } from 'src/app/shared/dialog.service';
import { ResourceApiService } from 'src/app/shared/resource-api.service';
import {ResourceRegisterHelpService} from '../../../../shared/resource-register-help.service';

@Component({
  selector: 'app-position-history',
  templateUrl: './position-history.component.html',
  styleUrls: ['./position-history.component.css']
})
export class PositionHistoryComponent implements OnInit, OnDestroy {

  id;
	positionHistory: PositionHistory[] = []
	positionHistoryColumns = ['fromDate', 'toDate', 'positionId', 'positionName', 'departmentName', 'companyUnitInfo', 'actions'];
  positionHistoryActions: TableAction[] = [
		{
			name: 'label.edit',
			icon: 'create',
			color: '',
			do: (row) => {
				this.editPositionHistory(row)
			}
		}
	];
  constructor(
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private resourceService: ResourceApiService,
    private resourceRegisterHelpService: ResourceRegisterHelpService,
    private router: Router) { }

  ngOnDestroy() {
    this.resourceRegisterHelpService.disable();
  }

  ngOnInit() {
    this.resourceRegisterHelpService.enable('POSITION_HISTORY');
    this.route.parent.params.subscribe( params => {
      this.id = params['id'];
      this.resourceService.findPositionHistoryFromId(this.id).subscribe( positionHistory => {
        this.positionHistory = positionHistory;
      });
    });
  }

  editPositionHistory(row) {
		this.dialogService.openCustomDialog(PositionHistoryDialogComponent, true, row)
			.afterClosed().subscribe(({fromDate,toDate,id}) => {
        this.resourceService.updatePositionHistory(id,{fromDate,toDate}).subscribe(success => {
          this.ngOnInit();
        })
			});
	}

}
