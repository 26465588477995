import { getMonth } from "date-fns";

export enum Month {
  'jan'='label.JAN',
  'fev'='label.FEV',
  'mar'='label.MAR',
  'abr'='label.ABR',
  'mai'='label.MAI',
  'jun'='label.JUN',
  'jul'='label.JUL',
  'ago'='label.AGO',
  'set'='label.SET',
  'out'='label.OUT',
  'nov'='label.NOV',
  'dez'='label.DEZ',
}
