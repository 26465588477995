import * as auth from './reducers/auth.reducers';
import { createSelector, ActionReducerMap } from '@ngrx/store';

export interface AppState {
    authData: auth.State;
}

export const reducers: ActionReducerMap<AppState> = {
    authData: auth.reducer
};

export const getUserState = (state: AppState) => state.authData;
export const getUserData = createSelector(getUserState, auth.getUser);