import { Component, OnInit, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css']
})
export class InputComponent implements OnInit {

  inputResult: any;
  inputName: string;
  subtitleRightButton: string = 'OK';
  subtitleLeftButton: string;
  @Input() title: string = 'title.input';
  subtitle: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<InputComponent>
  ) {
    this.title = data.title;
    this.subtitle = data.subtitle;
    this.subtitleRightButton = data.subtitleRightButton;
    this.subtitleLeftButton = data.subtitleLeftButton;
  }

  ngOnInit() {
  }

  close() {
    if(!!this.inputResult)
      this.dialogRef.close(this.inputResult);
  }

}
