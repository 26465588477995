import { Component } from '@angular/core';
import { DEPARTMENT_API } from '../../../api-urls';
import { BaseRegistrationComponent } from '../../../components/base-registration/base-registration.component';
import { DepartmentDialogComponent } from '../../../dialogs/department-dialog/department-dialog.component';
import { TalentApiService } from '../../../shared/api.service';
import { DialogService } from '../../../shared/dialog.service';
import { AuthService } from '../../../shared/auth.service';
import { Department } from '../../../models/department';
import { BreadcrumbAction } from 'src/app/components/breadcrumb/breadcrumb-action';

@Component({
  selector: 'app-departamento',
  templateUrl: './departamento.component.html',
  styleUrls: ['./departamento.component.css']
})
export class DepartamentoComponent extends BaseRegistrationComponent<Department> {

  columns: string[] = ['departmentName', 'description', 'actions'];
  analystBasedColumns: string[] = ['departmentName', 'description'];
  paths: string[] = ['title.mainRegistration','/','title.department'];
  breadcrumbActions: BreadcrumbAction[] = [
    {
      label: 'label.back',
      router: '..',
      style: 'generic-button'
    }
  ]
  constructor(dialogService: DialogService, apiService: TalentApiService<Department>, authService: AuthService) {
    super(dialogService, apiService);
    this.editComponent = null;
    this.objectApi = DEPARTMENT_API;
    this.objectType = 'Department';
    this.user = authService.getUser();
  }

}
