import { Injectable } from '@angular/core';
import {driver} from 'driver.js';

type TrainingPage = 'TRAINING_INFO' | 'ADD_RESOURCES' | 'PRESENCE_LIST' | 'GRADES' | 'SEND_LIST' | 'CALENDAR' | 'COURSE_REGISTER';

@Injectable({
  providedIn: 'root'
})
export class TrainingHelpService {

  public isEnabled = false;

  page!: TrainingPage;

  constructor() { }

  enable(page: TrainingPage){
    this.page = page;
    this.isEnabled = true;
  }

  disable(){
    this.isEnabled = false;
  }

  openCurrentHelp() {
    if(this.isEnabled){
      switch (this.page){
        case 'TRAINING_INFO':
          this.trainingInfoHelp();
          break;
        case 'ADD_RESOURCES':
          this.addResourcesHelp();
          break;
        case 'PRESENCE_LIST':
          this.addPresenceListHelp();
          break;
        case 'GRADES':
          this.gradesHelp();
          break;
        case 'SEND_LIST':
          this.sendListHelp();
          break;
        case 'CALENDAR':
          this.calendarHelp();
          break;
        case 'COURSE_REGISTER':
          this.courseRegisterHelp();
          break;
      }
    }
  }

  private trainingInfoHelp() {
    const driverObj = driver({
      showProgress: true,
      nextBtnText: 'Próximo',
      prevBtnText: 'Anterior',
      doneBtnText: 'Concluir',
      progressText: '{{current}} de {{total}}',
      steps: [
        { element: '.first-step-help', popover: { title: 'Informações Gerais', description: 'Preencha as informações sobre Departamento, Site, Curso, Instrutor, Tipo do Treinamento, Datas Inicial e Final.'}},
        { element: '.second-step-help', popover: { title: 'Informações Gerais', description: 'Automaticamente, a aplicação exibirá a descrição do treinamento e os procedimentos que devem ser abordados.'}},
        { element: '.third-step-help', popover: { title: 'Informações Gerais', description: 'A data de vencimento do treinamento é calculada automaticamente, a partir da data final do treinamento. Portanto, se o curso instanciado é semestral, a validade do treinamento aplicado será de 6 meses.'}},
        { element: '.fourth-step-help', popover: { title: 'Informações Gerais', description: 'O status de um treinamento é criado automaticamente como Programado. Quando um treinamento é finalizado, o seu status muda para Realizado. Você poderá cancelar um treinamento em qualquer momento.'}},
      ]
    });

    driverObj.drive();
  }

  private addResourcesHelp() {
    const driverObj = driver({
      showProgress: true,
      nextBtnText: 'Próximo',
      prevBtnText: 'Anterior',
      doneBtnText: 'Concluir',
      progressText: '{{current}} de {{total}}',
      steps: [
        { element: '.first-step-help', popover: { title: 'Participantes', description: 'Busque o nome do participante e clique em Adicionar. Somente os recursos do site previamente informado serão listados.'}},
        { element: '.second-step-help', popover: { title: 'Participantes', description: 'Você poderá excluir participantes enquanto o treinamento estiver com o status programado. Entretanto, participações e notas eventualmente lançadas também serão excluídas.'}},
      ]
    });

    driverObj.drive();
  }

  private addPresenceListHelp() {
    const driverObj = driver({
      showProgress: true,
      nextBtnText: 'Próximo',
      prevBtnText: 'Anterior',
      doneBtnText: 'Concluir',
      progressText: '{{current}} de {{total}}',
      steps: [
        { element: '.first-step-help', popover: { title: 'Controle de Presença', description: 'Automaticamente, a aplicação exibirá o intervalo do treinamento (datas de início e fim)'}},
        { element: '.second-step-help', popover: { title: 'Controle de Presença', description: 'O lançamento da participação (presença ou ausência) deverá ser feito em todos os dias do treinamento. Selecione neste campo o dia que deseja efetuar o lançamento.'}},
        { element: '.third-step-help', popover: { title: 'Controle de Presença', description: 'Através do botão "Marcar Presença" você poderá atribuir a presença de todos os participantes no dia selecionado no campo "Data de Lançamento".'}},
        { element: '.fourty-step-help', popover: { title: 'Controle de Presença', description: 'Através do botão "visto" você poderá lançar presença, e através do botão "cancelar" você poderá lançar ausência.'}},
      ]
    });

    driverObj.drive();
  }

  private gradesHelp() {
    const driverObj = driver({
      showProgress: true,
      nextBtnText: 'Próximo',
      prevBtnText: 'Anterior',
      doneBtnText: 'Concluir',
      progressText: '{{current}} de {{total}}',
      steps: [
        { element: '.first-step-help', popover: { title: 'Notas', description: 'Para os participantes que tiveram presença mínima, você poderá lançar a nota adquirida no treinamento.'}},
        { element: '.second-step-help', popover: { title: 'Notas', description: 'Assim que as participações e notas forem lançadas, você deverá finalizar o treinamento.'}},
      ]
    });

    driverObj.drive();
  }

  private sendListHelp() {
    const driverObj = driver({
      showProgress: true,
      nextBtnText: 'Próximo',
      prevBtnText: 'Anterior',
      doneBtnText: 'Concluir',
      progressText: '{{current}} de {{total}}',
      steps: [
        { element: '.first-step-help', popover: { title: 'Documentos', description: 'Você poderá vincular arquivos em um treinamento. Para isso, insira uma descrição e busque o arquivo.'}},
        { element: '.second-step-help', popover: { title: 'Documentos', description: 'Você poderá excluir documentos previamente inseridos em qualquer momento.'}},
      ]
    });

    driverObj.drive();
  }

  private calendarHelp() {
    const driverObj = driver({
      showProgress: true,
      nextBtnText: 'Próximo',
      prevBtnText: 'Anterior',
      doneBtnText: 'Concluir',
      progressText: '{{current}} de {{total}}',
      steps: [
        { element: '.step1', popover: { title: 'Calendário', description: 'Através do ícone "Agenda" você poderá listar e filtrar todos os treinamentos do mês selecionado no calendário.'}},
        { element: '.step2', popover: { title: 'Calendário', description: 'Ao clicar no ícone "Mês Atual", você é direcionado para o mês vigente.'}},
        { element: '.step3', popover: { title: 'Calendário', description: 'Através do ícone "Selecionar Mês", você poderá escolher um ano e mês anterior para consultar os seus respectivos treinamentos.'}},
        { element: '.step4', popover: { title: 'Calendário', description: 'Através dos botões "<" e ">", você poderá navegar no calendário dos meses anteriores e posteriores ao mês vigente.'}},
        {
          element: '.step5',
          popover: {
            title: 'Calendário',
            description: 'Os treinamentos serão apresentados no calendário com base nessas três categorias e suas respectivas cores.',
            onNextClick: element => {
              this.openCalendar();
              setTimeout(() => {
                driverObj.moveNext();
              },500)
            },
          }
        },
        {
          element: '.cal-open-day-events',
          popover: {
            title: 'Calendário',
            description: 'Ao clicar em um determinado dia do calendário, a aplicação mostrará todos os treinamentos que estão agendados.',
            onPrevClick: element => {
              this.openCalendar();
              setTimeout(() => {
                driverObj.movePrevious();
              }, 500)
            }
          }
        },
        {
          element: '.step7',
          popover: {
            title: 'Calendário',
            description: 'Através do ícone "+" você poderá criar treinamentos especificamente para este dia.',
            onNextClick: element => {
              this.closeCalendar();
              setTimeout(() => {
                driverObj.moveNext();
              }, 500)
            },
          }
        },
        {
          element: '.step8',
          popover: {
            title: 'Calendário',
            description: 'Você poderá exportar o calendário de treinamentos para arquivos com formato XLS ou PDF.',
            onPrevClick: element => {
              this.openCalendar();
              setTimeout(() => {
                driverObj.movePrevious();
              }, 500)
            }
          }
        },
        { element: '.step9', popover: { title: 'Calendário', description: 'Ao clicar neste botão, você poderá adicionar um novo treinamento.'}},
      ]
    });

    driverObj.drive();
  }

  openCalendar: () => void
  closeCalendar: () => void

  registerOpenCalendarCallback(openCallback: () => void, closeCallback: () => void) {
    this.openCalendar = openCallback;
    this.closeCalendar = closeCallback;
  }

  private courseRegisterHelp() {
    const driverObj = driver({
      showProgress: true,
      nextBtnText: 'Próximo',
      prevBtnText: 'Anterior',
      doneBtnText: 'Concluir',
      progressText: '{{current}} de {{total}}',
      steps: [
        { element: '.first-step-help', popover: { title: 'Cadastro de Curso', description: 'Inicialmente, você deverá preencher os campos referência e curso com o nome do curso.'}},
        { element: '.second-step-help', popover: { title: 'Cadastro de Curso', description: 'Em seguida, informe se o curso é geral ou específico. Cursos gerais são cursos que devem ser aplicado para todos os recursos independente do cargo e do site. Já o curso específico poderá ser aplicado somente para um site em questão.'}},
        { element: '.third-step-help', popover: { title: 'Cadastro de Curso', description: 'Depois, é necessário informar o departamento, site e a duração do curso em horas. Para cursos gerais, o site não é informado.'}},
        { element: '.fourth-step-help', popover: { title: 'Cadastro de Curso', description: 'A frequência do curso determina o período em que ele deve ser reaplicado, ou seja, se um curso tem a frequência semestral, o mesmo irá vencer seis meses após a sua realização. Caso o curso não precise ser reaplicado, é necessário selecionar “Único” na frequência.'}},
        { element: '.fifth-step-help', popover: { title: 'Cadastro de Curso', description: 'O campo descrição não é obrigatório, e pode ser utilizado para acrescentar informações adicionais do curso.'}},
      ]
    });

    driverObj.drive();
  }
}
