import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { DialogService } from './dialog.service';
import { TrainingApiService } from './training-api.service';

@Injectable({
  providedIn: 'root'
})
export class NeedResourcesAddedGuard implements CanActivate {

  constructor(
    private trainingApi: TrainingApiService,
    private dialogService: DialogService
  ){}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const trainingId: number = next.params.id;
      return this.trainingApi.hasResources(trainingId)
      .pipe(
        tap(
          hasResources => {
            if(!hasResources){
              this.dialogService.openAlertDialog('message.addResourcesFirst')
            }
          }
        )
      );
  }

}
