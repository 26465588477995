import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { DialogService } from './dialog.service';
import { AUTH_URL } from './utils.const';

@Injectable({
  providedIn: 'root'
})
export class DefinePasswordGuard implements CanActivate {
  constructor(
    private auth:AuthService,
    private dialogService: DialogService,
    private router: Router
  ){}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const token = next.queryParamMap.get('token');
      const isFirstAccess = next.queryParamMap.get('first-access')
      console.log('token',token);
      console.log('isFirstAcces',isFirstAccess)
      return this.auth.verifyToken(token)
      .pipe(
        map( resp => true),
        catchError(error => {
          return of(this.router.parseUrl(`/login?errorCode=${error.status}&first-access=${isFirstAccess}`));
        }),
      );
    }
  
}

