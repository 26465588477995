import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbAction } from 'src/app/components/breadcrumb/breadcrumb-action';
import { ColumnType, TableAction } from 'src/app/components/table/table-action';
import { ResourcePresence } from 'src/app/models/resource-presence';
import { DialogService } from 'src/app/shared/dialog.service';
import { TrainingApiService } from 'src/app/shared/training-api.service';
import { TrainingPresenceService } from 'src/app/shared/training-presence.service';
import { trainingPresence } from 'src/app/util-json.data';
import { NavigationHeaderComponent } from '../navigation-header/navigation-header.component';
import { InputComponent } from 'src/app/components/dialog/input/input.component';
import { switchMap } from 'rxjs/operators';
import {TrainingHelpService} from '../../../shared/training-help.service';

@Component({
  selector: 'app-mark-presence',
  templateUrl: './mark-presence.component.html',
  styleUrls: ['./mark-presence.component.css']
})
export class MarkPresenceComponent implements OnInit, OnDestroy {

  breadcrumbActions: BreadcrumbAction[] = [
    {
      label: 'label.close',
      style: 'cancel-button',
      router: '/main/calendar'
    },
    {
      label: 'label.print',
      style: 'add-button',
      do: () => {
        this.printPresenceList();
      }
    },
    {
      label: 'label.sendPresenceInstructor',
      style: 'add-button',
      do: () => {
        this.sendEmailToInstructor();
      }
    }
  ];

  trainingId: number;
  hasTrainingDays = false;
  trainingDaysQuantity = new FormControl(null, [Validators.required]);
  firstDate: Date = new Date();
  lastDate: Date = new Date();
  dateSelected = new FormControl(null, [Validators.required]);
  filter = new FormControl('');
  trainingDatesCount: Date[] = [];
  hasLoaded = false;
  isLoadingPresences = false;
  resources = [];
  maxDays = 1;
  presenceResources: ResourcePresence[] = [];
  originalPresenceResources: ResourcePresence[] = [];

  displayedColumns: string[] = [];

  pageSize = 5;
  pageIndex = 0;

  everyoneHasPresence = false;

  presenceResourcesColumns: ColumnType[] = [
    {name: 'label.date', property: 'date', isDate: true},
    {name: 'label.registry', property: 'registry'},
    {name: 'label.resourceName', property: 'resourceName'},
    {name: 'label.positionId', property: 'positionId'},
    {name: 'label.positionName', property: 'positionName'},
    {name: 'label.present', property: 'present'}
  ];


  presenceResourceAction: TableAction[] = [
    {
      name: 'label.markPresence',
      icon: 'done',
      color: '#08C792',
      do: (row) => {
        this.markPresence(row.id);
      }
    },
    {
      name: 'label.markAbsence',
      icon: 'clear',
      color: '#B41E2A',
      do: (row) => {
        this.markAbsence(row.id);
      }
    },
  ];

  constructor(
    private routeSnapshot: ActivatedRoute,
    private trainingPresenceService: TrainingPresenceService,
    private trainingApi: TrainingApiService,
    private trainingHelpService: TrainingHelpService,
    private dialogService: DialogService,
    private router: Router
  ) {}

  ngOnDestroy() {
    this.trainingHelpService.disable();
  }

  ngOnInit() {
    this.trainingHelpService.enable('PRESENCE_LIST');
    this.trainingId = this.routeSnapshot.snapshot.params.id;
    this.loadPresences(true);
    this.updateNavigateHeader(this.trainingId);
    this.trainingPresenceService.getMaxDaysOfTraining(this.trainingId).subscribe(response => {
      this.maxDays = response;
    });
    this.filter.valueChanges.subscribe({
      next: (value: string) => {
        this.filterColumns(value);
      }
    });
    this.mapColumns(this.presenceResourcesColumns);
  }

  filterColumns(filter: string) {
    const value = filter.toLowerCase();
    if (value !== '') {
      this.presenceResources = this.originalPresenceResources.filter(presenceResource =>
        presenceResource.resourceName.toLowerCase().includes(value) ||
        presenceResource.positionName.toLowerCase().includes(value) ||
        presenceResource.positionId.toString().includes(value) ||
        presenceResource.present.toLowerCase().includes(value) ||
        presenceResource.registry.toLowerCase().includes(value)
      );
    } else {
      this.pageChange({pageSize: this.pageSize, pageIndex: this.pageIndex});
    }
  }

  loadPresences(isFirstLoad?: boolean) {
    this.trainingPresenceService.getTrainingDates(`${this.trainingId}`).pipe(
      switchMap((resp: any[]) => {
        if (isFirstLoad) {
          this.dateSelected.patchValue(resp[0]);
        }
        this.firstDate = resp[0];
        this.lastDate = resp[resp.length - 1];
        this.hasLoaded = true;
        this.hasTrainingDays = true;
        this.trainingDaysQuantity.patchValue(resp);
        this.trainingDaysQuantity.disable();
        this.trainingDatesCount = resp;
        return this.trainingPresenceService.getPresencesByDate(`${this.trainingId}`, this.dateSelected.value);
      })
    ).subscribe(
      (response: any) => {
        this.isLoadingPresences = false;
        this.everyoneHasPresence = response.presences.every(r => r.isPresent);
        this.originalPresenceResources = response.presences.map(({resource, isPresent, date, canChangePresence}: any) => {
          return new ResourcePresence(
            date,
            resource.registry,
            resource.name,
            resource.positionId,
            resource.positionName,
            isPresent === true ? 'Presente' : isPresent === false ?'Ausente':'-',
            resource.id,
            canChangePresence
          );
        }).sort((a: ResourcePresence, b: ResourcePresence) => {
          if (a.resourceName < b.resourceName) {
            return -1;
          }
          if (b.resourceName < a.resourceName) {
            return 1;
          }
          return 0;
        });
        this.presenceResources = this.originalPresenceResources.slice(0, this.pageSize);
        this.breadcrumbActions.map((button)=>{
          if (button.label.includes("sendPresenceInstructor")) {
            button.disabled = this.presenceResources.every((presenceResource)=>!presenceResource.canChangePresence)
          }
        })
      },
      () => {
        this.hasTrainingDays = false;
      }
    );
  }

  markPresenceForAll() {
    this.trainingPresenceService.markPresenceForAll(this.trainingId, this.dateSelected.value).subscribe(response => {
      const [year, month, day] = `${this.dateSelected.value}`.split('-')
      this.dialogService.openInformationDialog('message.markPresenceForAll', `${day}/${month}`);
      this.loadPresences(false);
    }, () => {
      this.dialogService.openAlertDialog('error.presenceFail');
    });
  }

  markPresence(resourceId: any) {
    this.trainingPresenceService.changePresence(resourceId, this.trainingId, this.dateSelected.value, true).subscribe(response => {
      this.loadPresences(false);
    }, () => {
      this.dialogService.openAlertDialog('error.presenceFail');
    });
  }

  markAbsence(resourceId) {
    this.trainingPresenceService.changePresence(resourceId, this.trainingId, this.dateSelected.value, false).subscribe(() => {
      this.loadPresences(false);
    }, () => {
      this.dialogService.openAlertDialog('error.presenceFail');
    });
  }



  sendEmailToInstructor(){
    this.trainingPresenceService.sendAccessToTrainingInstructor(this.trainingId)
      .subscribe(response => {
        this.dialogService.openInformationDialog('message.instructorMailSended')
      }, error => {
        this.dialogService.openAlertDialog('error.emailSendedFailed')
      });
  }

  handleChangesOnPresences(event) {
    if (event.isUserInput) {
      this.trainingPresenceService.getPresencesByDate(`${this.trainingId}`, event.source.value).subscribe(
        (response: any) => {
          this.everyoneHasPresence = response.presences.every(r => r.isPresent);
          this.originalPresenceResources = response.presences.map(({resource, isPresent, date, canChangePresence}: any) => {
            return new ResourcePresence(
              date,
              resource.registry,
              resource.name,
              resource.positionId,
              resource.positionName,
              isPresent === true ? 'Presente' : isPresent === false ?'Ausente':'-',
              resource.id,
              canChangePresence
            );
          }).sort((a: ResourcePresence, b: ResourcePresence) => {
            if (a.resourceName < b.resourceName) { return -1; }
            if (b.resourceName < a.resourceName) { return 1; }
            return 0;
          });
          this.presenceResources = this.originalPresenceResources.slice(0, this.pageSize);
        }
      );
    }
  }

  next(){
    this.router.navigate(['/main','registration','training-info',this.trainingId,'grades'])
  }
  @ViewChild(NavigationHeaderComponent) navHeader: NavigationHeaderComponent

  updateNavigateHeader(idTraining){
    this.navHeader.changetoUpdate(idTraining);
  }

  mapColumns(columns: any) {
    this.displayedColumns = columns.map((column: any) => column.property);
    this.displayedColumns.push('action');
  }

  getTableCell(e: any, c: any) {
    return e[c.property];
  }

  getTableCellStyle(e: any, c: any) {
    if (c.property === 'present') {
      if (e[c.property] === 'Presente') {
        return 'green';
      }
      if (e[c.property] === 'Ausente') {
        return 'red';
      }
    }
    return '';
  }
  printPresenceList() {
    this.dialogService.openCustomDialog(InputComponent, true, {
      title: 'label.exportPresenceList',
      subtitle: 'label.subtitleGenerateFile',
      subtitleRightButton: "label.export",
      inputName: 'label.fileName' }).afterClosed().subscribe(response => {
      if(response != undefined){
        this.trainingPresenceService.printPresenceList(this.trainingId, response).subscribe( data => {
          const blob = new Blob([data], {type: 'application/pdf'})

          var downloadURL = window.URL.createObjectURL(data);
          var link = document.createElement('a');
          link.href = downloadURL;
          link.download = response + '.pdf';
          link.click();

        });
      }
    });
  }

  pageChange(event: any) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.presenceResources = this.originalPresenceResources
      .slice(this.pageIndex * this.pageSize,
        (this.pageIndex * this.pageSize) + this.pageSize);
  }
}
