import { ProficiencyDialogComponent } from './../../dialogs/proficiency-dialog/proficiency-dialog.component';
import { BaseRegistrationComponent } from './../../components/base-registration/base-registration.component';
import { DialogService } from './../../shared/dialog.service';
import { Component } from '@angular/core';
import { TalentApiService } from 'src/app/shared/api.service';
import { PROFICIENCY_API } from 'src/app/api-urls';
import { Proficiency } from 'src/app/models/proficiency';

@Component({
  selector: 'app-proficiency',
  templateUrl: './proficiency.component.html',
  styleUrls: ['./proficiency.component.css']
})
export class ProficiencyComponent extends BaseRegistrationComponent<Proficiency> {

  columns: string[] = ['label.proficiencyName', 'label.level', 'actions'];

  constructor(dialogService: DialogService, apiService: TalentApiService<Proficiency>) {
    super(dialogService, apiService);
    this.editComponent = ProficiencyDialogComponent;
    this.objectApi = PROFICIENCY_API;
    this.objectType = 'Proficiency';
  }
}
