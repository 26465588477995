import { COURSE_API } from './../../api-urls';
import { BaseRegistrationComponent } from './../../components/base-registration/base-registration.component';
import { CourseDialogComponent } from './../../dialogs/course-dialog/course-dialog.component';
import { DialogService } from './../../shared/dialog.service';
import { Component } from '@angular/core';
import { TalentApiService } from 'src/app/shared/api.service';
import { Course } from 'src/app/models/course';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.css']
})
export class CourseComponent extends BaseRegistrationComponent<Course> {

  columns: string[] = ['reference', 'courseName', 'frequency', 'departmentName', 'companyUnitName', 'actions'];

  constructor(dialogService: DialogService, apiService: TalentApiService<Course>) {
    super(dialogService, apiService);
    this.editComponent = CourseDialogComponent;
    this.objectApi = COURSE_API;
    this.objectType = 'Course';
  }

}
