import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';

import { User } from '../models/user';
import { AppState } from '../store/app.states';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  user: User;
  isAuthenticated: boolean;
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private authService: AuthService) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.user = this.authService.getUser();
    this.isAuthenticated = this.authService.getIsAuthenticated();
    if (!this.isAuthenticated) {
      if (state.url === '/') {
        this.router.navigate(['/login']);
      } else {
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
      }

      return false;
    }
    // Verificar roles e dar acesso
    if((this.user.roles.includes('ROLE_INSTRUCTOR'))
        && !(state.url.toString().endsWith('calendar')
            || state.url.toString().endsWith('main')
            || state.url.toString().includes('training-info')
            )
    ) {
      this.router.navigate(['/login']);
      return false;
    }
    if (
      this.user.roles.includes('ROLE_SITE_MANAGER') &&
      !(state.url.toString().endsWith('reports')
        || state.url.toString().endsWith('trainingPlan')
        || state.url.toString().endsWith('trainingDashboard')
        || state.url.toString().endsWith('skillAnalysis')
        || state.url.toString().endsWith('generalRadar')
        || state.url.toString().endsWith('skillMatrix')
        || state.url.toString().endsWith('courseMatrix')
        || state.url.toString().endsWith('accessHistory')
        || state.url.toString().endsWith('pendingCourseList')
        || state.url.toString().endsWith('expiredCourseList')
        || state.url.toString().endsWith('completedCourseList')
        || state.url.toString().endsWith('courseList')
      )
    ) {
      if (this.user.roles.includes('ROLE_SITE_MANAGER') && state.url.toString().endsWith('user')
        || state.url.toString().endsWith('calendar')
      ) {
        this.router.navigate(['/login']);
        return false;
      }
    }
    if (this.user.roles.includes('ROLE_SITE_MANAGER_INSTRUCTOR')
      && !(
        state.url.toString().endsWith('trainingPlan')
        || state.url.toString().endsWith('skillAnalysis')
        || state.url.toString().endsWith('main')
        || state.url.toString().endsWith('generalRadar')
        || state.url.toString().endsWith('skillMatrix')
        || state.url.toString().endsWith('courseMatrix')
        || state.url.toString().endsWith('pendingCourseList')
        || state.url.toString().endsWith('expiredCourseList')
        || state.url.toString().endsWith('courseList')
        || state.url.toString().endsWith('reports')
        || state.url.toString().endsWith('calendar')
        || state.url.toString().endsWith('main')
        || state.url.toString().includes('training-info')
      )
    ) {
      if(
        (this.user.roles.includes('ROLE_INSTRUCTOR')
        || this.user.roles.includes('ROLE_MANAGER')
        || this.user.roles.includes('ROLE_SITE_MANAGER')
        || this.user.roles.includes('ROLE_SITE_MANAGER_INSTRUCTOR')
        ) && state.url.toString().endsWith('user')
      ){
      this.router.navigate(['/login']);
      return false;
      }
    }
    return true;
  }

}
