import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DEPARTMENT_API } from 'src/app/api-urls';
import { Department } from 'src/app/models/department';
import { TalentApiService } from 'src/app/shared/api.service';
import { DialogService } from 'src/app/shared/dialog.service';

@Component({
  selector: 'app-departamento-form',
  templateUrl: './departamento-form.component.html',
  styleUrls: ['./departamento-form.component.css']
})
export class DepartamentoFormComponent implements OnInit {

  formGroup: FormGroup;
  active: FormControl = new FormControl(true);
  name= new FormControl('');
  description= new FormControl('');

  isEdit: boolean = false;
  @Output() sucessfullySaved: EventEmitter<any> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private dialogService: DialogService,
    private departmentApiService: TalentApiService<Department>
  ) {}

  ngOnInit() {
    this.formGroup = this.fb.group({
      id: null,
      name: new FormControl('', [Validators.required, Validators.maxLength(60)]),
      active: this.active,
      description: new FormControl('')
    });

    this.name.valueChanges.subscribe(value => {
      if(value != null){
        this.formGroup.controls['name'].patchValue(value);
      }
    });

    this.description.valueChanges.subscribe(value => {
      if (value!=null) {
        this.formGroup.controls['description'].patchValue(value);
      }
    });
  }

  save() {
    this.departmentApiService.saveObject(DEPARTMENT_API, this.formGroup.value).subscribe(_response => {
      this.dialogService.openInformationDialog('message.savedDepartment',this.formGroup.get('name').value);
      this.clear();
      this.sucessfullySaved.emit(true);
    });
  }

  clear(){
    this.isEdit = false;
    this.formGroup.reset();
    this.formGroup.controls['active'].patchValue(true);
    this.name.patchValue('',{emitEvent:false});
    this.description.patchValue('',{emitEvent:false});
  }

  fillForm(row:any){
    this.isEdit = true;
    this.formGroup.patchValue(row);
    this.name.patchValue(row.name);
    this.description.patchValue(row.description);
  }
}
