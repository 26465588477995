import { BASE_URL, UPLOAD_FILE, DELETE_FILE, DOWNLOAD_FILE } from './../api-urls';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SearchField } from '../models/search-field.';
import { Observable } from 'rxjs';
import { Attachment } from '../models/attachment';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TalentApiService<T> {

  constructor(private http: HttpClient) { }

  saveObject(url: string, object: T): Observable<T> {
    return this.http.post<T>(url, object);
  }

  updateObject(url: string, id: number, object: T): Observable<T> {
    return this.http.put<T>(`${url}/${id}`, object);
  }

  getObjectById(url: string, id: number): Observable<any> {
    return this.http.get(`${url}/${id}`);
  }

  getObjecstById(url: string, id: number): Observable<any> {
    return this.http.get<T[]>(`${url}/${id}`);
  }

  deleteObject(url: string, id: number): Observable<void> {
    return this.http.delete<void>(`${url}/${id}`);
  }

  listAllObjects(url: string): Observable<T[]> {
    const lang = localStorage.getItem('lang');
    return this.http.get<T[]>(`${url}`);
  }

  listObject(url: string): Observable<any> {
    return this.http.get<any>(`${url}`);
  }

  doQuery(url: string, query: string,pageSize: number, pageNumber: number) {
	  const lang = localStorage.getItem('lang');
    return this.http.get<any>(`${url}/paginated?query=${query}&pageSize=${pageSize}&pageNumber=${pageNumber}`);
  }

  listPaginatedObject(url: string,pageSize: number, pageNumber: number): Observable<any>{
    const lang = localStorage.getItem('lang');
    return this.http.get<T[]>(`${url}/paginated?pageSize=${pageSize}&pageNumber=${pageNumber}`);
  }

  searchObjects(url: string, fields: SearchField[]): Observable<T[]> {
    const searchUrl = this.buildUrlForSearch(url, fields);

    return this.http.get<T[]>(searchUrl);
  }

  uploadFile(formData: FormData): Observable<Attachment> {
    return this.http.post<Attachment>(UPLOAD_FILE, formData);
  }

  downloadFile(attachment): Observable<any> {
    return this.http.post<any>(DOWNLOAD_FILE, attachment);
  }

  deleteFile(attachment): Observable<void>{
    return this.http.delete<void>(DELETE_FILE + attachment.id);
  }

  buildUrlForSearch(url: string, fields: SearchField[]){
    const lang = localStorage.getItem('lang') || environment.defaultLang;

    let searchUrl: string = url + "?lang=" + encodeURIComponent(lang) + "&";
    for (let field of fields) {
      searchUrl += field.name + "=" + encodeURIComponent(field.value) + "&";
  }
    searchUrl = searchUrl.slice(0, -1);
    return searchUrl;
  }

  buildUrl(url: string, fields: SearchField[],fileName: string, extension: string): void {
    const searchUrl = this.buildUrlForSearch(url,fields);

    this.http.get<any>(searchUrl,{responseType: 'blob' as 'json'}).subscribe( data => {
      const blob = new Blob([data], {type: 'application/' + extension})

      var downloadURL = window.URL.createObjectURL(data);
       var link = document.createElement('a');
      link.href = downloadURL;
      link.download = fileName + '.' + extension;
      link.click();

    });
  }
}
