import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PRESENCE_API } from '../api-urls';

@Injectable({
  providedIn: 'root'
})
export class PresenceGuard implements CanActivate {

  constructor(
    private http: HttpClient
  ){}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const token = next.queryParamMap.get('token');
      localStorage.setItem("TrainingToken",token);
      console.log('token',token);
      return this.http.get(PRESENCE_API + `/training-token/${token}`)
      .pipe(
        tap(console.log),
        map( resp => !!resp)
      );
    }
  
}
