import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-loader-dialog',
  templateUrl: './loader-dialog.component.html',
  styleUrls: ['./loader-dialog.component.css']
})
export class LoaderDialogComponent implements OnInit {

  message = '';
  awaitMessage = 'general.await';
  dots = ''
  awaitMessages = ['.','..','...']

  constructor(@Inject (MAT_DIALOG_DATA) data: string) {
    this.message =  data;
  }

  ngOnInit() {
    let i = 0;
    setInterval(() => {
      this.dots = this.awaitMessages[i++ % 3]
    }, 1000);
  }

}
