import { ScheduleDialogComponent } from './schedule-dialog/schedule-dialog.component';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { CalendarEvent } from 'calendar-utils';
import { statusTypes } from 'src/app/util-json.data';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/shared/auth.service';
import { Training } from 'src/app/models/training';
import { Router } from '@angular/router';
import { TrainingApiService } from 'src/app/shared/training-api.service';
import { Moment } from 'moment';
import { MatDatepicker, MatDialog } from '@angular/material';
import { FormControl } from '@angular/forms';
import { CalendarComponent } from 'src/app/components/calendar/calendar.component';
import { BreadcrumbAction } from 'src/app/components/breadcrumb/breadcrumb-action';
import {TrainingHelpService} from '../../shared/training-help.service';
import {TranslateService} from "@ngx-translate/core";

const colors: any = {
  basic: {
    primary: '#2EBCD4',
    secondary: '#baeaf2'
  }
};

@Component({
  selector: 'app-training-calendar',
  templateUrl: './training-calendar.component.html',
  styleUrls: ['./training-calendar.component.css']
})
export class  TrainingCalendarComponent implements OnInit, OnDestroy {

  columns: string[] = ['courseName', 'fromPlanningDate', 'toPlanningDate', 'expireDate', 'instructorName', 'companyUnitName', 'actions'];

  user: User;

  trainings: Training[] = [];
  trainingsEvents: CalendarEvent[] = [];
  types = statusTypes;

  dateFilter = new FormControl();
  breadcrumbActions: BreadcrumbAction[] = [
    {
			label: 'title.addTraining',
      style: 'generic-button step9',
			do: () => {
				this.add();
      }
    }
  ]

  actualDate: any;

  @ViewChild('calendar') calendar: CalendarComponent;

  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private trainingService: TrainingApiService,
    private trainingHelpService: TrainingHelpService,
    private router: Router,
    private translateService: TranslateService,
    ) { this.user = authService.getUser(); }

  ngOnDestroy() {
    this.trainingHelpService.disable();
  }

  ngOnInit() {
    this.trainingHelpService.enable('CALENDAR');
    let today = this.format(new Date());
    this.refresh(today);
    this.actualDate = today;
    this.translateService.onLangChange.subscribe(lang => {
      this.refresh(this.actualDate);
    })
  }

  monthChanged(newViewDate){
    this.refresh(newViewDate)
    this.actualDate = newViewDate;
  }

  refresh(date) {
    let trainingGetRequest;
    if(this.user.roles.includes('ROLE_INSTRUCTOR') || this.user.roles.includes('ROLE_SITE_MANAGER_INSTRUCTOR')){
      trainingGetRequest = this.trainingService.getFromSpecificInstructor(this.user.username,date);
    }else{
      trainingGetRequest =  this.trainingService.getAllFromMonthOfDate(date);
    }
    trainingGetRequest.subscribe(async response => {
      this.trainings = response;
      this.trainingsEvents = [];
      for(let training of this.trainings) {
        const typeColor = this.getColor(training.status);

        let startDate = new Date(training.fromPlanningDate);
        startDate.setDate(startDate.getDate() + 1);

        let endDate = new Date(training.toPlanningDate);
        endDate.setDate(endDate.getDate() + 1);

        const event = {
          start: startDate,
          end: endDate,
          title:`
          ID ${await this.translate('label.training')}: ${training['id']} ||
          ${await this.translate('label.course')}: ${training['courseName']} ||
          ${await this.translate('label.department')}: ${training['department'].name} ||
          ${await this.translate('title.site')}: ${training['companyUnitName']} ||
          CC: ${training.companyUnit.costCenter} ||
          ${await this.translate('label.instructor')}: ${training['instructorName']} ||
          ${await this.translate('label.typeTraining')}: ${(training['type'] || '')} ||
          ${await this.translate('label.start')}: ${training['fromPlanningDate']} ||
          ${await this.translate('label.end')}: ${training['toPlanningDate']}`,
          color: { primary: typeColor, secondary: '#EDEDED' },
          allDay: false,
          meta: {
            training: training,
            click: this.clickCalendar.bind(this)
          }
        };

        this.trainingsEvents.push(event);
      }
    });
  }

  private async translate(key: string) {
    return await this.translateService.get(key).toPromise();
  }

  clickCalendar = (event) => {
    const trainingId: number = event.meta.training.id;
    this.router.navigate(['main','registration','training-info',trainingId])
  };

  getColor(type) {
    return statusTypes.find(t => t.name === type)['color'];
  }

  add() {
    this.router.navigate(['/main','registration','training-info'])
  }

  format(date: Date): string {
    let day: string = date.getDate().toString();
    day = +day < 10 ? '0' + day : day;
    let month: string = (date.getMonth() + 1).toString();
    month = +month < 10 ? '0' + month : month;
    let year = date.getFullYear();
    return year+'-'+month+'-'+day;
  }

  chosenMonthHandler(normalizedMonth: Date, datepicker: MatDatepicker<Moment>) {
    const formattedDate = this.format(normalizedMonth);
    this.refresh(formattedDate)
    this.calendar.setDate(normalizedMonth);
    this.actualDate = formattedDate;
    datepicker.close();
  }

  openSchedule(){
    this.dialog.open(ScheduleDialogComponent, {
      width:'80vw',
      panelClass: 'custom-dialog',
      disableClose: true,
      position: { top: '' },
      data: {
        date: this.actualDate
      }
    }).afterClosed().subscribe(()=>{
      this.refresh(this.actualDate);
    });
  }

}
