import { BaseRegistrationComponent } from './../../components/base-registration/base-registration.component';
import { ResourceDialogComponent } from './../../dialogs/resource-dialog/resource-dialog.component';
import { DialogService } from './../../shared/dialog.service';
import { Component } from '@angular/core';
import { RESOURCE_API } from 'src/app/api-urls';
import { TalentApiService } from 'src/app/shared/api.service';
import { Resource } from 'src/app/models/resource';
@Component({
  selector: 'reg-resources',
  templateUrl: './resource.component.html',
  styleUrls: ['./resource.component.css']
})
export class ResourceComponent extends BaseRegistrationComponent<Resource> {

  columns: string[] = ['resourceName', 'departmentName', 'companyUnitName', 'positionName', 'modality', 'actions'];

  constructor(dialogService: DialogService, apiService: TalentApiService<Resource>) {
    super(dialogService, apiService);
    this.editComponent = ResourceDialogComponent;
    this.objectApi = RESOURCE_API;
    this.objectType = 'Resource';
  }

}
