import { Course } from 'src/app/models/course';
import { ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DialogService } from 'src/app/shared/dialog.service';
import { TalentApiService } from 'src/app/shared/api.service';
import { COMPANY_API, SKILL_API, PROFICIENCY_API, SKILL_COMPANY_UNIT_API, POSITION_BY_COMPANY_API, COURSE_BY_COMPANY_API, POSITION_API, POSITION_SITE_API } from 'src/app/api-urls';
import { MatButtonToggleGroup, MatAutocompleteTrigger, MatPaginator, MatTableDataSource } from '@angular/material';
import { forkJoin, Observable } from 'rxjs';
import { TableAction } from 'src/app/components/table/table-action';
import { FormControl, Validators } from '@angular/forms';
import { map, startWith, tap } from 'rxjs/operators';
import { SkillCompanyUnit } from 'src/app/models/skill-company-unit';
import { Skill } from 'src/app/models/skill';
import { Proficiency } from 'src/app/models/proficiency';
import { Position } from 'src/app/models/position';
import { CompanyUnit } from 'src/app/models/company-unit';
import { PositionSite } from 'src/app/models/position-site';
import { PositionSkillProficiency } from 'src/app/models/position-skill-proficiency';
import { skillLevelTypes } from 'src/app/util-json.data';

@Component({
	selector: 'app-position-skill-site',
	templateUrl: './position-skill-site.component.html',
	styleUrls: ['./position-skill-site.component.css']
})
export class PositionSkillSiteComponent implements OnInit,OnChanges {
	@ViewChild('group') companyUnit: MatButtonToggleGroup;
	@ViewChild('secondGroup') position: MatButtonToggleGroup;
	@ViewChild(MatPaginator) paginator: MatPaginator;

	loadingCompanies: boolean = true;
  loadingProceedings: boolean = false;
  loadingPositions: boolean = false;

  showInsertProceedings: boolean = false;
  showProceedingsTable: boolean = false;

	companyUnits: CompanyUnit[] = [];
	proceedings: Skill[] = [];
  proceedingsCourses: Skill[]=[];
	proficiencies: Proficiency[] = [];
	positions: Position[] = [];
  courses: Course[]=[];
	proficiency: FormControl = new FormControl(0, [Validators.required]);
	selectedPosition: FormControl = new FormControl(null);
	selectedSkill: FormControl = new FormControl(null);
  selectedCourse: FormControl = new FormControl(null);
  selectedMethod: any = null;
	selectedProficiency: any = null;
	tableSkills: MatTableDataSource<SkillCompanyUnit> = new MatTableDataSource();
	filteredTableSkills: SkillCompanyUnit[] = [];

	toogleGroupPositions: Position[] = [];
  filteredPositionOptions: Position[] =[];
	filteredProceedingsOptions: Skill[]=[];
  filteredCoursesOptions: Observable<Course[]>;

  byCourse: boolean = false;
  byDetached: boolean = false;

	proceedingColumns = ['skillName', 'mandatory', 'select-proficiencyLevel', 'remove'];
  methodsProceeding = [
    { 'value': 1, 'description': 'label.proceendingByDetached'},
    { 'value': 2, 'description': 'label.proceendingByCourse'}
  ]
	proceedingTableAction: TableAction[] = [
		{
			name: 'label.delete',
			icon: 'delete',
			color: 'warn',
			do: (row) => {
				// this.removeSkill(row)
			}
		},
	];

	constructor(private apiService: TalentApiService<PositionSite>,
		private skillCompanyUnitApiService: TalentApiService<any>,
		private companyUnitApiService: TalentApiService<CompanyUnit>,
		private skillApiService: TalentApiService<Skill>,
		private proficiencyApiService: TalentApiService<Proficiency>,
		private positionApiService: TalentApiService<Position>,
    private courseApiService: TalentApiService<Course>,
    private dialogService : DialogService,
    private cdr: ChangeDetectorRef
		) {}

  ngOnChanges(_changes: SimpleChanges) {
      console.log('changes')
      this.cdr.detectChanges();
  }

	ngOnInit() {
    forkJoin(
      this.companyUnitApiService.listAllObjects(COMPANY_API),
    ).subscribe(([companyUnits]) => {
      this.companyUnits = companyUnits.map(unit => ({
        ...unit,
        displayName: `${unit.costCenter} - ${unit.name}`
      }));
      this.loadingCompanies = false;
      this.companyUnits.sort(this.sort);
    });

    this.companyUnit.valueChange.subscribe((value: CompanyUnit) => {
      this.toogleGroupPositions = [];
      this.positions = [];
      //this.selectedSkill.setValue(null);
      this.position.writeValue(null);
      this.clear();

      if (value) {
        this.loadingPositions = true;
        this.positionApiService.listAllObjects(POSITION_API).subscribe(response => {
          this.positions = response;
          this.positionApiService.getObjecstById(POSITION_BY_COMPANY_API, value.id).subscribe(response => {
            this.toogleGroupPositions = response;
            this.toogleGroupPositions.sort(this.sort);
            this.loadingPositions = false;
            if (response) {
              response.forEach((positionResponse: Position) => {
                this.positions.splice(this.positions.findIndex(position => position.id === positionResponse.id), 1);
                this.positions = [...this.positions];
                this.positions.sort(this.sort);
                this.filteredPositionOptions = this.positions;
              });
            }
          });
        });

        this.courseApiService.getObjecstById(COURSE_BY_COMPANY_API, this.companyUnit.value.id).subscribe(response => {
          this.courses = response;
          this.showInsertProceedings = true;
        });
      }
    });

    this.position.valueChange.subscribe((value: Position) => {
      this.clear();
      if (value) {
        this.loadingProceedings = true;
        this.skillApiService.listAllObjects(SKILL_API + '/linking-panel').subscribe(skills => {
          this.proceedings = skills;
        });
        const url = SKILL_COMPANY_UNIT_API + '/companyUnit/' + this.companyUnit.value.id + '/position/' + value.id;
        this.skillCompanyUnitApiService.listAllObjects(url).subscribe((response: SkillCompanyUnit[]) => {
          if (response && response.length > 0) {
            response.forEach(skillSitePosition => {
              this.proceedings.splice(this.proceedings.findIndex(proceeding => proceeding.id === skillSitePosition.skill.id), 1);
            });
            this.proceedings.sort(this.sort);
            this.filteredProceedingsOptions = this.proceedings;

            this.tableSkills.data = response;
            this.showProceedingsTable = true;
            this.showInsertProceedings = true;
            this.cdr.detectChanges();
            this.tableSkills.paginator = this.paginator;
          }
          this.loadingProceedings = false;
        });
        this.proficiencyApiService.listAllObjects(PROFICIENCY_API).subscribe(response => {
          this.proficiencies = response;
        });
      }
    });

    this.selectedPosition.valueChanges.subscribe(value => {
      this.filteredPositionOptions = this.filterPosition(value);
    });

    this.selectedSkill.valueChanges.subscribe(value => {
      this.filteredProceedingsOptions = this.filterSkill(value);
    });

    this.filteredCoursesOptions = this.selectedCourse.valueChanges
      .pipe(
        startWith(''),
        map((value: string) => this.filterCourse(value))
      );
  }

	removeSkill(row: SkillCompanyUnit) {
		if (row) {
			this.apiService.deleteObject(SKILL_COMPANY_UNIT_API, row.id).subscribe(
				() => {
					this.filteredTableSkills = this.tableSkills.data;
					this.filteredTableSkills.splice(this.filteredTableSkills.findIndex(skillSitePosition => skillSitePosition.id == row.id), 1);
					this.filteredTableSkills = [...this.filteredTableSkills];
					this.tableSkills.paginator = this.paginator;
          this.proceedings.push(row.skill);
          this.proceedings.sort(this.sort);
          this.filteredProceedingsOptions = this.proceedings;
				}
			);
		}
	}

	addPosition() {
		if (this.selectedPosition.value && this.companyUnit.value) {
			const saveObject = new PositionSite();
			saveObject.companyUnit = this.companyUnit.value;
			saveObject.position = this.selectedPosition.value;

			this.apiService.saveObject(POSITION_SITE_API, saveObject)
				.subscribe(_position => {
					this.toogleGroupPositions.push(_position['position']);
					this.toogleGroupPositions.sort(this.sort);
					this.position.writeValue(_position['position']);
					this.positions.splice(this.positions.findIndex(position => position.id === _position.position.id),1);
					this.positions.sort(this.sort);
          this.filteredPositionOptions = this.positions;
				});
		}
		this.selectedPosition.setValue(null);

		// this.refreshFilter();
	}

	addSkill() {
		this.skillCompanyUnitApiService.saveObject(SKILL_COMPANY_UNIT_API+'/linking-panel',{
			skillId: this.selectedSkill.value.id,
			companyUnitId: this.companyUnit.value.id,
			positionId: this.position.value.id,
			proficiencyId: this.selectedProficiency.id
		}).subscribe(
			response => {
        this.tableSkills.data.push(response);
        this.cdr.detectChanges();
				this.tableSkills.paginator = this.paginator;
				this.proceedings.splice(this.proceedings.findIndex(proceeding => proceeding.id === response.skill.id), 1);
        this.proceedings.sort(this.sort);
        this.filteredProceedingsOptions = this.proceedings;
        this.showProceedingsTable = true;
			}
		);
		this.selectedSkill.setValue('');
		this.selectedProficiency = null;
	}

  addSkillCourse(){
    this.proceedingsCourses = [];
    const course = this.courses.find(course => course.id == this.selectedCourse.value.id);
    let currentSkills = [];
    currentSkills=this.tableSkills.data;
    course.skills.forEach( skill => {
      const skillFind = currentSkills.find(skillTable => skillTable.skill.id == skill.id);
        if(skillFind==undefined){
          this.proceedingsCourses.push(skill);
        }
     });

    const courseProceedings = new Set(this.proceedingsCourses);
    const proeficiency = this.proficiencies.find(proeficiency => proeficiency.id = this.selectedProficiency.id);
    if(courseProceedings.size == 0){
      this.dialogService.openAlertSkillDialog();
      this.selectedCourse.setValue('');
    } else if(courseProceedings.size>0){
      this.dialogService.openAddSkillDialog(courseProceedings).afterClosed().subscribe(response =>{
        if(response == true){
          for (var item of Array.from(courseProceedings.values())) {
            this.selectedSkill.setValue(item);
            this.selectedProficiency = proeficiency;
            this.addSkill();
          }
        }
        this.selectedCourse.setValue('');

      });
    }
  }

	updatePositionSkillSite(row: SkillCompanyUnit, event?) {
		if (event) row.proficiency = this.proficiencies.find(proficiency => proficiency.level == event.value);
		this.skillCompanyUnitApiService.updateObject(SKILL_COMPANY_UNIT_API+'/linking-panel', row.id, {
      proficiencyId: row.proficiency.id,
      mandatory: row.mandatory
    }).subscribe();
	}

	@ViewChild(MatAutocompleteTrigger) trigger;

	clickAuto() {
		this.trigger._onChange('');
		this.trigger.openPanel();
	}

	sort(a: Skill | Position | CompanyUnit, b: Skill | Position | CompanyUnit): -1 | 0 | 1 {
		if (a.name.trim().toLowerCase() < b.name.trim().toLowerCase())
			return -1;
		if (a.name.trim().toLowerCase() > b.name.trim().toLowerCase())
			return 1;
		return 0;
	}

	filterPosition(value: string): Position[] {
    let filterValue:string;
    try {
      filterValue = value.toLowerCase();
    } catch (error) {
      filterValue = '';
    }
		return this.positions.filter(position => position.name.toLowerCase().includes(filterValue));
	}

	filterSkill(value: string): Skill[] {
		let filterValue:string;
    try {
      filterValue = value.toLowerCase();
    } catch (error) {
      filterValue = '';
    }
		return this.proceedings.filter(skill => !filterValue || skill['identifier'].toLowerCase().includes(filterValue));
	}

	filterCourse(value: string): Course[] {
    let filterValue:string;
    try {
      filterValue = value.toLowerCase();
    } catch (error) {
      filterValue = '';
    }
		return this.courses.filter(course => course.name.toLowerCase().includes(filterValue));
	}

	displayCourse(course: any){
		return course? course.name : '';
	}

	displayPosition(position: any){
		return position? position.name : '';
	}

	displayProceeding(proceeding: any){
		return proceeding? proceeding.identifier : '';
	}

  methodProceeding(event:any){
    if(event ==1){
      this.byDetached = true;
		  this.byCourse = false;
    } else if(event==2){
      this.byCourse = true;
		  this.byDetached = false;
    }
  }

  clear(){
    this.showInsertProceedings = false;
    this.showProceedingsTable = false;
    this.selectedMethod = null;
    this.selectedCourse.setValue('');
    this.selectedSkill.setValue('');
		this.byCourse = false;
		this.byDetached = false;
    this.selectedProficiency = null;
    this.filteredTableSkills = [];
		this.tableSkills = new MatTableDataSource([]);
    this.cdr.detectChanges();
  }

}
