import { AUTH_URL, USER_API_URL } from './utils.const';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { USER_REGISTER_API } from '../api-urls';
import { take } from 'rxjs/operators';
import { User } from '../models/user';
import { UserRegister } from '../models/user-register';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  
  constructor(private http: HttpClient) { }
  
  save(user: { username: string; role: string; resourceId: string; }) {
    return this.http.post(`${USER_REGISTER_API}`,user).pipe(take(1));
  }

  getAllUsers() {
    const lang = localStorage.getItem('lang');
    return this.http.get<UserRegister[]>(`${USER_REGISTER_API}?lang=${lang}`).pipe(take(1));
  }

  update(user: { username: any; role: any; id: string}) {
    return this.http.put(`${USER_REGISTER_API}`,user).pipe(take(1));
  }
}
