import { DialogService } from 'src/app/shared/dialog.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { DEPARTMENT_API } from 'src/app/api-urls';
import { TalentApiService } from 'src/app/shared/api.service';
import { Department } from 'src/app/models/department';

@Component({
  selector: 'app-department-dialog',
  templateUrl: './department-dialog.component.html',
  styleUrls: ['./department-dialog.component.css']
})
export class DepartmentDialogComponent implements OnInit {

  title: string = 'title.addDepartment';
  extraTitle: string = '';

  formGroup: FormGroup;

  name: FormControl = new FormControl('', [Validators.required, Validators.maxLength(60)]);
  active: FormControl = new FormControl(true);
  description: FormControl = new FormControl('');

  existing;

  constructor(private fb: FormBuilder,
    private departmentApiService: TalentApiService<Department>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DepartmentDialogComponent>,
    private dialogService: DialogService) {
      this.existing = data;
    }

  ngOnInit() {
    this.formGroup = this.fb.group({
      id: null,
      name: this.name,
      active: this.active,
      description: this.description
    });

    if(this.existing) {
      this.formGroup.patchValue(this.existing);
      this.title = 'title.editDepartment'
      this.extraTitle = 'ID #' + this.existing.id;
    }

  }

  save() {
    this.departmentApiService.saveObject(DEPARTMENT_API, this.formGroup.value).subscribe(_response => {
      this.dialogService.openInformationDialog('message.savedDepartment',this.formGroup.get('name').value);
      this.close();
    });
  }

  close(): void {
    this.dialogRef.close();
  }

}
