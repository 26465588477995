import { POSITION_API } from './../../../api-urls';
import { BaseRegistrationComponent } from './../../../components/base-registration/base-registration.component';
import { DialogService } from './../../../shared/dialog.service';
import { Component } from '@angular/core';
import { TalentApiService } from 'src/app/shared/api.service';
import { PositionDialogComponent } from 'src/app/dialogs/position-dialog/position-dialog.component';
import { AuthService } from 'src/app/shared/auth.service';
import { Position } from 'src/app/models/position';
import { BreadcrumbAction } from 'src/app/components/breadcrumb/breadcrumb-action';

@Component({
  selector: 'app-cargo',
  templateUrl: './cargo.component.html',
  styleUrls: ['./cargo.component.css']
})
export class CargoComponent extends BaseRegistrationComponent<Position> {

  columns: string[] = ['id', 'positionName', 'departmentName', 'actions'];
  analystBasedColumns: string[] = ['id', 'positionName', 'departmentName'];
  paths: string[] = ['title.mainRegistration','/','title.position'];
  breadcrumbActions: BreadcrumbAction[] = [
    {
      label: 'label.back',
      router: '..',
      style: 'generic-button'
    }
  ]
  constructor(dialogService: DialogService, apiService: TalentApiService<Position>, authService: AuthService) {
    super(dialogService, apiService);
    this.editComponent = null;
    this.objectApi = POSITION_API;
    this.objectType = 'Position';
    this.user = authService.getUser();
  }

}

