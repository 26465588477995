import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { User } from "../models/user";
import { AppState, getUserState } from "../store/app.states";
import { AuthService } from "./auth.service";
import { DialogService } from "./dialog.service";

@Injectable({
  providedIn: "root",
})
export class CanOpenTrainingGuard implements CanActivate {
  user: User;
  isAuthenticated: boolean;
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private authService: AuthService,
    private dialogService: DialogService
  ) {
    this.store.select(getUserState).subscribe((response) => {
      this.isAuthenticated = Boolean(localStorage.getItem("authenticated"));
      if (!this.isAuthenticated) this.router.navigate(["/login"]);
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = JSON.parse(localStorage.getItem("currentUser"));
    const trainingId = route.params["id"];
    if (
      user.roles.includes("ROLE_INSTRUCTOR") ||
      user.roles.includes("ROLE_SITE_MANAGER_INSTRUCTOR")
    ) {
      return this.authService.canOpenTraining(trainingId).pipe(
        map((value) => true),
        catchError((err) => {
          this.dialogService.openAlertDialog("error.resourceNotInstructor");
          return of(false);
        })
      );
    }
    return true;
  }
}
