import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SKILL_API } from 'src/app/api-urls';
import { TableAction } from 'src/app/components/table/table-action';
import { Skill } from 'src/app/models/skill';
import { TalentApiService } from 'src/app/shared/api.service';
import {element} from "@angular/core/src/render3";

@Component({
  selector: 'app-skill-course',
  templateUrl: './skill.component.html',
  styleUrls: ['./skill.component.css']
})
export class SkillCourseComponent implements OnInit {
  formGroup: FormGroup;

  companyUnitId: any;

  skills: Skill[] = [];
  selectedSkills: Skill[] = [];
  filteredSkills: Skill[] = [];
  querySkills: Skill[] = [];
  selectedSkill: FormControl = new FormControl('');
  skillsLoaded = false;

  skillTableAction: TableAction[] = [
    {
      name: 'label.delete',
      icon: 'delete',
      color: 'warn',
      do: (row: Skill) => {
        this.removeSkill(row)
      }
    },
  ];
  columns: string[] = ['proceedingName', 'actions'];
  @Output() sucessfullFilled: EventEmitter<any> = new EventEmitter();

  isEdit:boolean= false;
  skillsData: any;
  previewCompanyUnit: any = null;

  constructor(private fb: FormBuilder,
    private skillApiService: TalentApiService<Skill>) {
      this.formGroup = this.fb.group({
        skills: new FormControl([], Validators.required)
      });
  }

  ngOnInit() {
    this.formGroup.valueChanges.subscribe( value => {
      this.validSkills();
    });
  }

  setCompanyUnit(companyUnit: any) {
    if (!this.skillsLoaded) {
      this.skillsLoaded = true;
      if (companyUnit != null && companyUnit.id != undefined) {
        this.companyUnitId = companyUnit.id;

        this.skillApiService.listAllObjects(SKILL_API + '/companyUnit/' + this.companyUnitId).subscribe(skills => {
          this.skills = skills;
          this.skills.sort(this.sort);
          this.filteredSkills = this.skills;
          this.querySkills = this.filteredSkills;
          this.fillEdit();
        });
      } else {
        this.skillApiService.listAllObjects(SKILL_API + '/linking-panel').subscribe(skills => {
          this.skills = skills;
          this.skills.sort(this.sort);
          this.filteredSkills = this.skills;
          this.filteredSkills.sort(this.sort);
          this.querySkills = this.filteredSkills;
          this.fillEdit();
        });
      }
    }
  }

  fillEdit(){
    if(this.isEdit){
      if(this.previewCompanyUnit == null && this.companyUnitId != null ||
        this.previewCompanyUnit != null && this.companyUnitId !=  this.previewCompanyUnit.id){
        this.skillsData = [];
        this.selectedSkills = [];
      }
      this.selectedSkill.patchValue(this.skillsData);
      this.addSkill();
    }
  }

  sort(a: Skill, b: Skill): -1 | 0 | 1 {
    const valueA: string = a['position'] ? a['position'].name : a['name'];
    const valueB: string = b['position'] ? b['position'].name : b['name'];
    if (valueA.trim().toLowerCase() < valueB.trim().toLowerCase())
      return -1;
    if (valueA.trim().toLowerCase() > valueB.trim().toLowerCase())
      return 1;
    return 0;
  }

  filterS(value: string): Skill[] {
    const filterValue = value ? value.toLowerCase() : '';
    return this.filteredSkills.filter(skill => skill.name.toLowerCase().includes(filterValue));
  }

  @ViewChild(MatAutocompleteTrigger) trigger;
  clickAuto() {
    this.trigger._onChange('');
    this.trigger.openPanel();
  }

  onOpenChange(searchInput: any) {
    searchInput.value = '';
    this.querySkills = this.filteredSkills;
  }

  onInputChange(event: any) {
    const searchInput = event.target.value.toLowerCase();
    if(searchInput){
      this.querySkills = this.filterS(searchInput);
    }else {
      this.querySkills = this.filteredSkills;
    }
  }

  onSelectEvent($event:any, skill:Skill){
    let selectedSkill = [];
    selectedSkill=this.selectedSkill.value;
    if(selectedSkill && selectedSkill != undefined){
      const skillFound = selectedSkill.find(selectedSkill => selectedSkill.id == skill.id);
      if(skillFound==undefined){
        selectedSkill.push(skill);
        this.selectedSkill.patchValue(selectedSkill);
      }
    }
  }

  addSkill() {
    this.selectedSkill.value.forEach(element => {
      const skill = this.skills.find(s => s.id === element.id);
      if (element && skill) {
        this.insertSkill(skill);
      }
    });
    this.selectedSkill.patchValue('');
  }

  insertSkill(skill:any){
    const skillFound = this.selectedSkills.find(skillSelected => skillSelected.id == skill.id);
    if(skillFound == undefined){
      this.selectedSkills.push(skill);
      this.filteredSkills = this.filteredSkills.filter(s => s.id !== skill.id);
    }
    this.formGroup.controls['skills'].patchValue(this.selectedSkills);
  }

  removeSkill(skill: Skill) {
    this.selectedSkills.splice(this.selectedSkills.indexOf(skill), 1);
    this.formGroup.controls['skills'].patchValue(this.selectedSkills);
    this.skills.push(skill);
    this.skills.sort(this.sort);
  }

  validSkills(){
    if(this.formGroup.valid){
      this.sucessfullFilled.emit(this.formGroup.value);
    } else {
      this.sucessfullFilled.emit(null);
    }
  }

  fillForm(row){
    this.isEdit = true;
    this.skillsData = row.course.skills;
    row.course.companyUnit != undefined ?
    this.previewCompanyUnit = row.course.companyUnit
    : this.previewCompanyUnit= null;
    this.setCompanyUnit(row.course.companyUnit);
  }
}
