import { CompanyUnit } from './../../models/company-unit';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material';
import { forkJoin, merge } from 'rxjs';
import { DEPARTMENT_API, POSITION_SITE_API, RESOURCE_API } from 'src/app/api-urls';
import { Department } from 'src/app/models/department';
import { PositionSite } from 'src/app/models/position-site';
import { Resource } from 'src/app/models/resource';

import { CustomHeaderComponent } from '../custom-header/custom-header.component';
import { COMPANY_API } from './../../api-urls';
import { SearchField } from './../../models/search-field.';
import { TalentApiService } from './../../shared/api.service';

@Component({
  selector: 'app-default-filter',
  templateUrl: './default-filter.component.html',
  styleUrls: ['./default-filter.component.css']
})
export class DefaultFilterComponent implements OnInit {
  customHeader = CustomHeaderComponent;
  @Input() searchUrl: string;
  @Input() filtersToShow: string[];
  @Output() searchResult: EventEmitter<any> = new EventEmitter();
  @Output() clearTable: EventEmitter<any> = new EventEmitter();
  @Input() nextLine: boolean = true;
  @Input() enableSearch: boolean = false;
  @Input() OnInitSearch: boolean = false;
  @Input() hasDefaultDate: boolean=false;
  companyUnit: FormControl = new FormControl();
  selectedCompanyUnit: FormControl = new FormControl();
  companyUnits: CompanyUnit[];
  filteredCompanyUnits: CompanyUnit[] = [];

  department: FormControl = new FormControl();
  departments: Department[];

  position: FormControl = new FormControl();
  positions: PositionSite[];
  filteredPositions: PositionSite[] = [];

  resource: FormControl = new FormControl();
  resources: Resource[];
  filteredResources : Resource[] =[];

  active: FormControl = new FormControl(true);

  fromDate: FormControl = new FormControl();
  toDate: FormControl = new FormControl();

  referenceYear: FormControl = new FormControl();
  referenceYears: any[] = [];

  @Input() fromDateName: string = 'label.from';
  @Input() toDateName: string = 'label.to';
  @Input() startFromDateName: string = 'label.startFromDate';
  @Input() startToDateName: string = 'label.startToDate';
  @Input() referenceYearsName: string = 'label.referenceYear'
  disableButton = true;
  constructor(private companyUnitApiService: TalentApiService<CompanyUnit>,
    private departmentApiService: TalentApiService<Department>,
    private positionSiteApiService: TalentApiService<PositionSite>,
    private resourceApiService: TalentApiService<Resource>,
    private apiService: TalentApiService<any>) {}

  ngOnInit() {
    if(this.hasDefaultDate){
      const date = new Date;
      this.fromDate.patchValue(new Date(date.getFullYear(), date.getMonth(), 1));
      this.toDate.patchValue(new Date(date.getFullYear(), date.getMonth() + 1, 0));
    }
    if(this.enableSearch == true){
      this.disableButton = false;
    }
    if(this.OnInitSearch){
      this.search();
    }
    forkJoin(
      this.companyUnitApiService.listAllObjects(COMPANY_API),
      this.departmentApiService.listAllObjects(DEPARTMENT_API)
    ).subscribe(([companyUnits, departments]) => {
      this.companyUnits = companyUnits;
      this.filteredCompanyUnits = this.companyUnits;
      this.departments = departments;
    });
    merge(this.selectedCompanyUnit.valueChanges, this.department.valueChanges).subscribe(() => {
      if(this.selectedCompanyUnit.value && this.department.value) {
        if(this.selectedCompanyUnit.value!=undefined && this.department.value!=undefined){
          this.disableButton = false;
        }
        let fields: SearchField[] = [
          {
            name: 'department',
            value: this.department.value.id
          },
          { name: 'companyUnit',value: this.selectedCompanyUnit.value.id}
        ];
        this.positionSiteApiService.searchObjects(POSITION_SITE_API+'/searchByCompanyUnitAndDepartment',fields).subscribe(response => {
          this.positions = response;
          this.resource.setValue(undefined);
          this.resources = [];
          this.filteredPositions = this.positions;
        });
      }
    });

    if(this.filtersToShow.includes("referenceYears")){
      let fields: SearchField[] = this.getFields();
      let currentYear = new Date().getFullYear();

      this.apiService.searchObjects(this.searchUrl+'/referenceYears', fields).subscribe(response => {
        this.referenceYears = response;
        this.referenceYear.setValue(currentYear);
      });
    }

    this.department.valueChanges.subscribe(department=>{
      this.companyUnit.setValue(undefined);
      this.selectedCompanyUnit.setValue(undefined);
      this.position.setValue(undefined);
      this.resource.setValue(undefined);
      if(!this.enableSearch)
        this.disableButton = true;
    });

    this.companyUnit.valueChanges.subscribe(companyUnit => {
      if (companyUnit && companyUnit.id === undefined) {
        this.disableButton = true;

        const query = companyUnit.toLowerCase();
        this.filteredCompanyUnits = this.companyUnits.filter(unit => {
          const companyUnitInfo = `${unit.costCenter} - ${unit.name}`.toLowerCase();
          return companyUnitInfo.includes(query);
        });
      } else if (companyUnit && companyUnit.id !== undefined) {
        this.selectedCompanyUnit.setValue(companyUnit);
        if (this.enableSearch === true) {
          this.disableButton = false;
        }
      }
    });

    this.selectedCompanyUnit.valueChanges.subscribe(selected=>{
      this.position.setValue(undefined);
      this.resource.setValue(undefined);
    })

    this.position.valueChanges.subscribe(positionSite => {
      this.resource.setValue(undefined);
      if(positionSite && positionSite.id == undefined){
        this.filteredPositions = this.filterPosition(positionSite);
      }else if(positionSite && positionSite.id != undefined) {
        let fields: SearchField[] = [
          {
            name: 'positionId',
            value: positionSite.id
          }
        ];
        this.resourceApiService.searchObjects(RESOURCE_API + "/search", fields).subscribe((response: any[]) => {
          this.resources = response;
          this.filteredResources = this.resources;
        });
      }
    });

    this.resource.valueChanges.subscribe( resource => {
      if(resource && resource.id == undefined){
        this.filteredResources = this.filterResource(resource);
      }
    })
  }

  show(name) {
    if(!Array.isArray(this.filtersToShow) || !this.filtersToShow.length) {
      return true;
    }

    return this.filtersToShow.includes(name);
  }

  search() {
    let fields: SearchField[] = this.getFields();

    this.apiService.searchObjects(this.searchUrl, fields).subscribe(response => {
      this.searchResult.emit({
        filter: fields,
        result: response,
        length: length
      });
    });
  }

  clear() {
    if(!this.enableSearch)
      this.disableButton = true;
    this.companyUnit.setValue(undefined);
    this.selectedCompanyUnit.setValue(undefined);
    this.department.setValue(undefined);
    this.position.setValue(undefined);
    this.resource.setValue(undefined);
    this.active.setValue(true);
    this.fromDate.setValue(undefined);
    this.toDate.setValue(undefined);
    this.referenceYear.setValue(undefined);
    this.filteredCompanyUnits = this.companyUnits;
    this.positions = [];
    this.resources = [];
    this.clearTable.emit();
    if(this.OnInitSearch){
      this.search()
    }
  }

  getFields() {
    let fields = [];

    if(this.position.value) {
      fields.push({
        name: 'positionId',
        value: this.position.value.id
      });
    }

    if(this.selectedCompanyUnit.value) {
      fields.push({
        name: 'companyUnitId',
        value: this.selectedCompanyUnit.value.id
      });
    }

    if(this.resource.value) {
      fields.push({
        name: 'resourceId',
        value: this.resource.value.id
      });
    }

    if(this.department.value) {
      fields.push({
        name: 'departmentId',
        value: this.department.value.id
      });
    }

    if(this.active.value) {
      fields.push({
        name: 'active',
        value: this.active.value
      });
    }

    if(this.fromDate.value) {
      let from = null;
      if(this.fromDate){
        from = this.format(this.fromDate.value);
      }

      fields.push({
        name: 'fromDate',
        value: from
      });
    }

    if(this.toDate.value) {
      let to = null;
      if(this.toDate){
        to = this.format(this.toDate.value);
      }

      fields.push({
        name: 'toDate',
        value: to,
      });
    }

    if(this.referenceYear.value) {
      fields.push({
        name: 'referenceYear',
        value: this.referenceYear.value
      });
    }

    return fields;
  }

  format(date: Date): string {
      let day: string = date.getDate().toString();
      day = +day < 10 ? '0' + day : day;
      let month: string = (date.getMonth() + 1).toString();
      month = +month < 10 ? '0' + month : month;
      let year = date.getFullYear();
      return year+'-'+month+'-'+day;
    }

    @ViewChild(MatAutocompleteTrigger) trigger;

    clickAuto() {
      this.trigger._onChange('');
      this.trigger.openPanel();
    }

    filterPosition(value: string): PositionSite[] {
      const filterValue = value ? value.toLowerCase() : '';
      return this.positions.filter(positionSite => positionSite.position.name.toLowerCase().includes(filterValue));
    }

    filterResource(value: string): Resource[] {
      const filterValue = value ? value.toLowerCase() : '';
      return this.resources.filter(resources => resources.name.toLowerCase().includes(filterValue));
    }

    displayCompanyUnit(value: any){
      return value? value.companyUnitInfo : '';
    }

    displayPosition(value: any){
      return value? value.position.name : '';
    }

    displayResource(value: any){
      return value? value.name : '';
    }

}
