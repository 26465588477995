import { CompanyUnit } from './company-unit';
import { Position } from './position';
import { PositionSkillProficiency } from './position-skill-proficiency';
import { SkillCompanyUnit } from './skill-company-unit';

export class PositionSite {
    id?: number;
    companyUnit: CompanyUnit;
    position: Position;
    skillCompanyUnits?: SkillCompanyUnit[];
}