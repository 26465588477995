import { PositionSite } from './position-site';
import { CompanyUnit } from './company-unit';
import { ResourceSkillProficiency } from './resource-skill-proficiency';
import { PositionHistory } from './position-history';

export class Resource {
    id?: number;
    name: string;
    document: string;
    registry: string;
    admissionDate: Date;
    resignationDate: Date;
    modality: string;
    email: string;
    phone: string;
    position: PositionSite;
    companyUnit: CompanyUnit;
    performance: number;
    skillProficiencies: ResourceSkillProficiency[];
    positionHistory: PositionHistory[];
    tempCourseId: number;
    targetPosition: PositionSite;
    positionSites: PositionSite;
    hasUsername: boolean;
    positionName: string;
    positionId: number | string;
}
