import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {User} from 'src/app/models/user';
import {TRAINING_API} from './../../../api-urls';
import {Router} from '@angular/router';
import {TrainingApiService} from 'src/app/shared/training-api.service';
import {AuthService} from 'src/app/shared/auth.service';
import {TalentApiService} from 'src/app/shared/api.service';
import {DialogService} from 'src/app/shared/dialog.service';
import {TableAction} from 'src/app/components/table/table-action';
import {Training} from 'src/app/models/training';
import {Component, Inject, OnInit} from '@angular/core';

@Component({
  selector: 'app-schedule-dialog',
  templateUrl: './schedule-dialog.component.html',
  styleUrls: ['./schedule-dialog.component.css']
})
export class ScheduleDialogComponent implements OnInit {
  columns: string[] = ['trainingId', 'courseName', 'fromPlanningDate', 'toPlanningDate', 'expireDate', 'instructorName', 'companyUnitName', 'actions'];
  trainings: Training[] = [];
  user: User;
  actions: TableAction[] = [
    {
      name: 'label.edit',
      icon: 'create',
      color: '',
      do: (row: Training) => {
        this.editTraining(row)
      }
    },
    {
      name: 'label.delete',
      icon: 'delete',
      color: 'warn',
      do: (row: Training) => {
        this.removeTraining(row)
      }
    },
  ]

  constructor(@Inject(MAT_DIALOG_DATA) public data,
    private dialogService: DialogService,
    private apiService: TalentApiService<Training>,
    private authService: AuthService,
    private trainingService: TrainingApiService,
    private router: Router,
    private dialogRef: MatDialogRef<ScheduleDialogComponent>) {this.user = authService.getUser(); }

  ngOnInit() {
    this.refresh(this.data.date);
    console.log('date.monthNames.'+this.getMonthName(this.data.date.toString().substring(5,7)).substring(0,3))

  }

  editTraining(row: Training) {
    this.dialogRef.close();
    this.router.navigate(['main','registration','training-info',row.id])
  }

  removeTraining(row: Training) {
    this.dialogService.openConfirmDialog('message.removeTraining', row.courseName)
      .afterClosed().subscribe(confirm => {
        if(confirm) {
          this.apiService.deleteObject(TRAINING_API, row.id).subscribe(() => {
            this.dialogService.openInformationDialog('message.deletedTraining');
            this.refresh(this.format(new Date()));
          },error => {
            switch(error.error.message){
              case 'finishedTraining':
                this.dialogService.openAlertDialog('error.finishedTraining');
                break;
              case 'resourcesConstraintViolation':
                this.dialogService.openAlertDialog('error.resourcesConstraintViolation');
                break;
              case 'attachmentsConstraintViolation':
                this.dialogService.openAlertDialog('error.attachmentsConstraintViolation');
                break;
            }
          });
        }
      });
  }

  refresh(date) {
    let trainingGetRequest;
    if(this.user.roles.includes('ROLE_INSTRUCTOR') || this.user.roles.includes('ROLE_SITE_MANAGER_INSTRUCTOR')){
      trainingGetRequest = this.trainingService.getFromSpecificInstructor(this.user.username,date);
    }else{
      trainingGetRequest =  this.trainingService.getAllFromMonthOfDate(date);
    }
    trainingGetRequest.subscribe(response => {
      this.trainings = response.map(training => {
        return {
          ...training,
          trainingId: training.id,
          companyUnitName: `${training.companyUnit.costCenter} - ${training.companyUnit.name}`,
          fromPlanningDate: this.formatToBrazilianDate(training.fromPlanningDate),
          toPlanningDate: this.formatToBrazilianDate(training.toPlanningDate),
          expireDate: this.formatToBrazilianDate(training.expireDate)
        };
      });
    });
  }

  formatToBrazilianDate(date: Date){
    const dateParts = date.toString().split("-");
    return dateParts[2] + "/" + dateParts[1] + "/" + dateParts[0];
  }

  format(date: Date): string {
    let day: string = date.getDate().toString();
    day = +day < 10 ? '0' + day : day;
    let month: string = (date.getMonth() + 1).toString();
    month = +month < 10 ? '0' + month : month;
    let year = date.getFullYear();
    return year+'-'+month+'-'+day;
  }

  closeDialog(){
    this.dialogRef.close();
  }

  getMonthName(monthNumber: number) {
    return `${new Date(2022, monthNumber - 1, 1).
      toLocaleString('default', {
      month: 'long',
    })}`;
  }
}
