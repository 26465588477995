export class User {
    username: string = null;
    password: string | null = null;
    token: string | null = null;
    roles: string[] | null = [];

    constructor(username: string, password: string | null, token?: string | null, roles?: string[] | null) {
        this.username = username;
        this.password = password;
        this.token = token;
        this.roles = roles;
    }

    // constructor(
    //     public username: string,
    //     public password: string,
    //     public token: string,
    //     public roles: string[]
    // ) {}
}
