import { SendDailyControlComponent } from './../components/dialog/send-daily-control/send-daily-control.component';
import { LoaderState } from '../components/dialog/loader/loader';
import { AlertComponent } from '../components/dialog/alert/alert.component';
import { ConfirmComponent } from '../components/dialog/confirm/confirm.component';
import { Injectable, TemplateRef, Component } from '@angular/core';
import { MatDialog, MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { Subject, Observable } from 'rxjs';
import { ComponentType } from '@angular/core/src/render3';
import { TranslateService } from '@ngx-translate/core';
import { AddSkillComponent } from '../components/dialog/add-skill/add-skill.component';
import { Skill } from '../models/skill';
import { AlertSkillComponent } from '../components/dialog/alert-skill/alert-skill.component';
import { InformationComponent } from '../components/dialog/information/information.component';
import { LoaderDialogComponent } from '../components/dialog/loader-dialog/loader-dialog.component';
import { ConfirmInnerHTMLComponent } from '../components/dialog/confirm-inner-html/confirm-inner-html.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(public snackBar: MatSnackBar, private dialog: MatDialog, private translate: TranslateService) {}

  snackConfig: MatSnackBarConfig = {
    duration: 3000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };

  showSuccessSnack(message,extraMessage?: any) {
    this.translate.get(message, {value: extraMessage}).subscribe((translateMessage: string) => {
      console.log(translateMessage);
      this.snackConfig['panelClass'] = ['notification', 'success'];
      this.snackBar.open(translateMessage, '', this.snackConfig);
    });
  }

  showWarnSnack(message, duration?: number) {
    this.translate.get(message).subscribe((translateMessage: string) => {
      this.snackConfig['panelClass'] = ['notification', 'warn'];
      if(duration != undefined){
        this.snackConfig['duration'] = duration;
      } else {
        this.snackConfig['duration'] = 5000;
      }
      this.snackBar.open(translateMessage, '', this.snackConfig);
    });
  }

  openConfirmDialog(message, extraMessage?: any, complement?: string) {
    return this.dialog.open(ConfirmComponent, {
      width: '390px',
      panelClass: 'custom-dialog',
      disableClose: true,
      position: { top: '280px' },
      data: {
        message: message,
        extraMessage: extraMessage,
        complement: complement
      }
    });
  }

  openConfirmInnerHtmlDialog({message, param}: { message: string; param?: any }) {
    return this.dialog.open(ConfirmInnerHTMLComponent, {
      width: '390px',
      panelClass: 'custom-dialog',
      disableClose: true,
      data: {message, param},
    });
  }

  openInformationDialog(message: string, value?:string, complementaryMessage?: string){
    return this.dialog.open(InformationComponent, {
      width: '390px',
      panelClass: 'custom-dialog',
      disableClose: false,
      hasBackdrop: false,
      position: { top: '280px' },
      data: {
        extraMessage: value,
        message: message,
        complementaryMessage: complementaryMessage
      }
    });
  }

  showCustomPositionAlertDialog(width:string, position:string, message: string, value?:string, complementaryMessage?: string){
    return this.dialog.open(AlertComponent, {
      width: width,
      panelClass: 'custom-dialog',
      disableClose: false,
      hasBackdrop: false,
      position: { top: position },
      data: {
        extraMessage: value,
        message: message,
        complementaryMessage: complementaryMessage
      }
    });
  }

  openAlertDialog(message,value?:string, complementaryMessage?: string) {
    return this.dialog.open(AlertComponent, {
      width: '390px',
      panelClass: 'custom-dialog',
      disableClose: false,
      hasBackdrop: false,
      position: { top: '280px' },
      data: {
        extraMessage: value,
        message: message,
        complementaryMessage: complementaryMessage
      }
    });
  }

  openCustomDialog(template, disableClickClose, data?: any) {
    let dialogData = null;
    if(data) {
      dialogData = data;
    }
    return this.dialog.open(template, {
      disableClose: disableClickClose,
      hasBackdrop: true,
      position: { top: '50px' },
      data: dialogData
    });
  }

  openCustomSizeDialog(size,template, disableClickClose, data?: any) {
    let dialogData = null;
    if(data) {
      dialogData = data;
    }
    return this.dialog.open(template, {
      width: size,
      disableClose: disableClickClose,
      hasBackdrop: true,
      position: { top: '50px' },
      data: dialogData
    });
  }

  openAlertSkillDialog() {
    return this.dialog.open(AlertSkillComponent, {
      width: '390px',
      panelClass: 'custom-dialog',
      disableClose: false,
      hasBackdrop: false,
      position: { top: '10px' },
    });
  }

  openAddSkillDialog(data: Set<Skill>) {
    let dialogData = null;
    if(data) {
      dialogData = data;
    }
    return this.dialog.open(AddSkillComponent, {
      width: '500px',
      panelClass: 'custom-dialog',
      disableClose: false,
      position: { top: '50px' },
      data: dialogData
    });
  }

  private loaderSubject = new Subject<LoaderState>();
  loaderState: Observable<LoaderState> = this.loaderSubject.asObservable();

  showLoader() {
    document.body.style.cursor = 'wait';
    this.loaderSubject.next(<LoaderState>{ show: true });
  }

  hideLoader() {
    document.body.style.cursor = 'inherit';
    this.loaderSubject.next(<LoaderState>{ show: false });
  }

  showDialogLoader(message: string){
    return this.dialog.open(LoaderDialogComponent, {data: message, disableClose: true});
  }

  showSendDailyControl(){
    return this.dialog.open(SendDailyControlComponent,{
      width: '600px',
      position: { top: '50px' }
    });
  }
}
