import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { COMPANY_API } from '../api-urls';

@Injectable({
  providedIn: 'root'
})
export class PositionService {
  
  constructor(private http: HttpClient) { }

  findAllByCompanyUnitAndDepartmentId(companyUnitId,departmentId) {
    return this.http.get<any>(`${COMPANY_API}/${companyUnitId}/departments/${departmentId}/positions`).pipe(take(1));
  }
}
