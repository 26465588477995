import { COURSE_LIST_REPORT, PRINT_COURSE_MASTER_LIST } from './../../api-urls';
import { Component, OnInit } from '@angular/core';
import { SearchField } from 'src/app/models/search-field.';
import { TalentApiService } from 'src/app/shared/api.service';
import { DialogService } from 'src/app/shared/dialog.service';
import { InputComponent } from 'src/app/components/dialog/input/input.component';
import { downloadFromUrl } from 'src/app/shared/downloader';
import { AuthService } from 'src/app/shared/auth.service';
import { BreadcrumbAction } from 'src/app/components/breadcrumb/breadcrumb-action';

@Component({
  selector: 'app-course-master-list',
  templateUrl: './course-master-list.component.html',
  styleUrls: ['./course-master-list.component.css']
})
export class CourseMasterListComponent implements OnInit {
  paths: string[] = ['menu.reports','/','reportMenu.courseList']
  breadcrumbActions: BreadcrumbAction[] = [
    {
			label: 'label.exportExcel',
      style: 'generic-button',
			do: () => {
				this.generateExcel();
			}
		},
    {
			label: 'label.exportPdf',
      style: 'generic-button',
			do: () => {
				this.generatePdf();
			}
		},
    {
      label: 'label.back',
      router: '..',
      style: 'generic-button'
    },
  ]
  searchUrl = COURSE_LIST_REPORT;

  courses: any = [];
  columns: any[] = [];
  filter: SearchField[];
  filterFields = [
    'companyUnitRequired',
    'department'
  ];
  showTable = false;
  constructor(
    private apiService: TalentApiService<void>,
    private dialogService: DialogService,
    private authService: AuthService) { }

  token = this.authService.getToken();

  ngOnInit() {
  }

  results(response) {
    if(response.result.length>0){
      let result = response.result;
      this.filter = response.filter;

      this.columns = [];
      this.courses = [];

      if(Array.isArray(result) && result.length) {
        this.showTable = true;
        this.columns.push('reference');
        this.columns.push('course');
        this.columns.push('courseType');
        this.columns.push('site');
        this.columns.push('department')
        this.columns.push('frequency');
        this.columns.push('proceeding');
        this.columns.push('acronym');

        this.courses = result;
      }
    }else{
      this.clear();
      this.dialogService.openInformationDialog('reportMenu.emptyData');
    }
  }

  generateExcel() {
    this.dialogService.openCustomSizeDialog('390px',InputComponent, true, {
      title: 'label.generateExcel',
      subtitle: 'label.subtitleGenerateFile',
      subtitleRightButton: "label.generate",
      inputName: 'label.fileName'
    }).afterClosed().subscribe(fileName => {
      if(!!fileName)
       this.apiService.buildUrl(COURSE_LIST_REPORT + "/xlsx/" + fileName, this.filter,fileName,'xlsx');
    });
  }

  generatePdf(){
    this.dialogService.openCustomSizeDialog('390px',InputComponent, true, {
      title: 'label.generatePdf',
      subtitle: 'label.subtitleGenerateFile',
      subtitleRightButton: "label.generate",
      inputName: 'label.fileName'
    }).afterClosed().subscribe(fileName => {
      if(!!fileName)
       this.apiService.buildUrl(COURSE_LIST_REPORT + "/pdf/" + fileName, this.filter,fileName,'pdf');
    });
  }
  clear(){
    this.showTable = false;
    this.courses = [];
    this.columns = [];
  }
}
