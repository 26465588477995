import { Component, OnInit } from '@angular/core';
import { BreadcrumbAction } from 'src/app/components/breadcrumb/breadcrumb-action';
import { InputComponent } from 'src/app/components/dialog/input/input.component';
import { SearchField } from 'src/app/models/search-field.';
import { TalentApiService } from 'src/app/shared/api.service';
import { DialogService } from 'src/app/shared/dialog.service';
import { traningPlan, traningPlanPattern, traningPlanRecycling } from 'src/app/util-json.data';

import { BASE_URL, TRAINING_DASHBOARD_REPORT } from './../../api-urls';

@Component({
  selector: "app-training-dashboard",
  templateUrl: "./training-dashboard.component.html",
  styleUrls: ["./training-dashboard.component.css"],
})
export class TrainingDashboardComponent implements OnInit {
  paths: string[] = ["menu.reports", "/", "reportMenu.trainingDashboard"];
  breadcrumbActions: BreadcrumbAction[] = [
    {
      label: "label.exportExcel",
      style: "generic-button",
      do: () => {
        this.generateExcel();
      },
    },
    {
      label: "label.exportPdf",
      style: "generic-button",
      do: () => {
        this.generatePdf();
      },
    },
    {
      label: "label.back",
      router: "..",
      style: "generic-button",
    },
  ];

  infos: any[] = [
    { icon: "school", amount: 0, description: "reportMenu.plannedCourses" },
    { icon: "school", amount: 0, description: "reportMenu.completedCourses" },
    { icon: "school", amount: 0, description: "reportMenu.canceledCourses" },
    { icon: "watch_later", amount: 0, description: "reportMenu.trainingHours" },
    { icon: "groups", amount: 0, description: "reportMenu.trainedPeople" },
    {
      icon: "work_history",
      amount: 0,
      description: "reportMenu.manHoursTotal",
    },
  ];
  searchUrl = BASE_URL + "trainings/dashboard";

  trainings: any = [];
  columns: any[] = [];
  filter: SearchField[];
  types = traningPlan;
  typesPattern = traningPlanPattern;
  typesRecycling = traningPlanRecycling;
  filterFields = ["companyUnit", "startFromDate", "startToDate"];
  showTable = false;
  constructor(
    private apiService: TalentApiService<void>,
    private dialogService: DialogService
  ) {}

  results(response?: any) {
    if(response.result.length>0){
      this.infos.forEach((info) => (info.amount = 0)); //zerar as infos do dashboard
      let result = response.result;
      this.filter = response.filter;
      this.trainings = [];
      this.trainings = result;

      result.forEach((info) => {
        if (info.status == "Realizado") {
          this.infos[1].amount++;
          this.infos[3].amount += info.workload;
          this.infos[4].amount += info.participants;
          this.infos[5].amount += info.manHours;
        }
        if (info.status == "Programado") this.infos[0].amount++;
        if (info.status == "Cancelado") this.infos[2].amount++;
      });
      this.infos.forEach((info) => (info.amount = Math.ceil(info.amount)));
    }else{
      this.clear();
      this.infos.forEach((info) => (info.amount = 0));
      this.dialogService.openInformationDialog('reportMenu.emptyData');
    }
  }

  generateExcel() {
    this.dialogService
      .openCustomSizeDialog("390px", InputComponent, true, {
        title: "label.generateExcel",
        subtitle: "label.subtitleGenerateFile",
        subtitleRightButton: "label.generate",
        inputName: "label.fileName",
      })
      .afterClosed()
      .subscribe((fileName) => {
        if (!!fileName)
          this.apiService.buildUrl(
            TRAINING_DASHBOARD_REPORT + "/xlsx/" + fileName,
            this.filter,
            fileName,
            "xlsx"
          );
      });
  }

  generatePdf() {
    this.dialogService
      .openCustomSizeDialog("390px", InputComponent, true, {
        title: "label.generatePdf",
        subtitle: "label.subtitleGenerateFile",
        subtitleRightButton: "label.generate",
        inputName: "label.fileName",
      })
      .afterClosed()
      .subscribe((fileName) => {
        if (!!fileName)
          this.apiService.buildUrl(
            TRAINING_DASHBOARD_REPORT + "/pdf/" + fileName,
            this.filter,
            fileName,
            "pdf"
          );
      });
  }

  ngOnInit() {
    this.columns = [
      "label.date",
      "label.training",
      "label.workload",
      "label.participants",
      "label.manHours",
      "label.type",
      "label.status",
    ];
  }

  clear() {
    this.showTable = false;
    this.trainings = [];
  }
}
