import { Injectable } from "@angular/core";
import * as Chart from "chart.js";
import 'chartjs-plugin-labels';

@Injectable({
  providedIn: "root",
})
export class CourseListService {
  constructor() {}

  rankByCourseId(resources) {
    // Cria um objeto para armazenar as contagens de cada courseId
    const counts = {};
    for (const resource of resources) {
      const courseId = resource.courseId;
      counts[courseId] = (counts[courseId] || 0) + 1;
    }
    // Converte o objeto de contagens em um array de objetos com o courseId e a contagem
    const countArray = Object.keys(counts).map((courseId) => {
      return { courseId: courseId, count: counts[courseId] };
    });
    // Ordena o array de contagens em ordem decrescente de contagem
    countArray.sort((a, b) => b.count - a.count);
    // Adiciona o campo 'count' com o número de ocorrências de cada objeto
    for (const obj of resources) {
      const courseId = obj.courseId;
      const count = counts[courseId];
      obj.count = count || 0;
    }
    // Calcula a soma total das chamadas dos seis objetos mais repetidos
    let total = 0;
    const topObjects = countArray.slice(0, 6).map((obj) => {
      total += obj.count;
      return resources.find((resource) => resource.courseId == obj.courseId);
    });
    // Retorna um objeto com os seis objetos mais repetidos, as contagens de cada courseId e a soma total das chamadas dos seis objetos
    return {
      objects: topObjects,
      counts: counts,
      total: total,
      resources: resources,
    };
  }

  countMandatoryCourses(resources: any[]) {
    let mandatory: number = 0;
    let allMandatory = resources.filter((r) => r.isMandatoryCourse).length;
    return {
      mandatoryCourses: allMandatory,
      notMandatoryCourses: resources.length - allMandatory,
      total: resources.length,
    };
  }

  plotCharts(resources: any[], pieId: string, pieTitle:string , doughnutId: string, doughnutTitle:string) {
    const rankedCourses = this.rankByCourseId(resources);
    const totalMandatory = this.countMandatoryCourses(resources);
    console.log(totalMandatory);
    const pieChart = this.plotPieCharts(rankedCourses, pieId,pieTitle);
    const doughnutChart = this.plotDoughnutChart(totalMandatory, doughnutId,doughnutTitle);
    return {
      pieChart: pieChart,
      doughnutChart: doughnutChart,
    };
  }

  plotPieCharts(rankedCourses, idContainer: string, pieTitle:string) {
    let chartData: number[] = [];
    let chartLabels: string[] = [];
    rankedCourses.objects.forEach((course) => {
      chartData.push(course.count);
      chartLabels.push("(" + course.count + ") " + course.training);
    });
    let chartDataSet: any[] = [
      {
        label: "Titulo",
        data: chartData,
        backgroundColor: [
          "#36A2EB",
          "#FF6384",
          "#4BC0C0",
          "#FF9F40",
          "#9966FF",
          "#FFCD56",
        ],
      },
    ];
    return new Chart(idContainer, {
      type: "pie",
      data: {
        labels: chartLabels,
        datasets: chartDataSet,
      },
      options: {
        plugins: {
          labels: {
            render: 'percentage',
            precision: 1,
            fontColor: '#fff',
            fontSize: 10,
            textShadow: true,
          },
          },
          aspectRatio: 2.5,
          legend: {
            position: "right",
            align:'center',
            labels: {
              boxWidth: 20,
              padding: 5
            }
          },
          title: {
            display: false,
            text: pieTitle,
            fullWidth:true,
          },
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            },
          },
      },
    });
  }

  plotDoughnutChart(
    totalMandatory: {
      mandatoryCourses: number;
      notMandatoryCourses: number;
      total: number;
    },
    idContainer: string,
    doughnutTitle:string
  ) {
    let chartDataSet: any[] = [
      {
        label: "Titulo",
        data: [
          totalMandatory.mandatoryCourses,
          totalMandatory.notMandatoryCourses,
        ],
        backgroundColor: [
          "#36A2EB",
          "#FF6384",
          "#4BC0C0",
          "#FF9F40",
          "#9966FF",
          "#FFCD56",
        ],
      },
    ];
    return new Chart(idContainer, {
      type: "doughnut",
      data: {
        labels: [
          `(${totalMandatory.mandatoryCourses}) Obrigatório`,
          `(${totalMandatory.notMandatoryCourses}) Não Obrigatório`,
        ],
        datasets: chartDataSet,
      },
      options: {
        plugins: {
          labels: {
            render: 'percentage',
            precision: 1,
            fontColor: '#fff',
            fontSize: 12,
            textShadow: true
          }
        },
        aspectRatio: 2.5,
        legend: {
          position: "right",
          align:'center',
          labels: {
            boxWidth: 20,
            padding: 5
          }
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          },
        },
        title: {
          display: false,
          text: doughnutTitle,
          fullWidth:true,
        },
      },
    });
  }
}
