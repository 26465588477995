import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { COMPANY_API, SKILL_API } from 'src/app/api-urls';
import { CompanyUnit } from 'src/app/models/company-unit';
import { Skill } from 'src/app/models/skill';
import { SkillCompanyUnit } from 'src/app/models/skill-company-unit';
import { TalentApiService } from 'src/app/shared/api.service';
import { DialogService } from 'src/app/shared/dialog.service';

@Component({
  selector: 'app-procedimento-form',
  templateUrl: './procedimento-form.component.html',
  styleUrls: ['./procedimento-form.component.css']
})
export class ProcedimentoFormComponent implements OnInit {

  formGroup: FormGroup;

  name: FormControl = new FormControl('');

  active: FormControl = new FormControl(true);
  description: FormControl = new FormControl('');
  acronym: FormControl = new FormControl('');
  document: FormControl = new FormControl('');
  companyUnits: CompanyUnit[];
  filteredCompanyUnits: CompanyUnit[];
  isEdit:boolean = false;
  skillId:any = null;

  allCompanyUnit: CompanyUnit = {
    id : 0,
    name: 'Todos',
    description: '',
    costCenter: '',
    reference: '',
    active: false
  }
  companyUnit: FormControl = new FormControl([]);
  selectedAllCompanyUnit: boolean = false;
  @Output() sucessfullySaved: EventEmitter<any> = new EventEmitter();
  constructor(
    private fb: FormBuilder,
    private dialogService: DialogService,
    private skillApiService: TalentApiService<Skill>,
    private companyUnitApiService: TalentApiService<CompanyUnit>
  ) { }

  ngOnInit() {
    this.formGroup = this.fb.group({
      id: null,
      name: new FormControl('', [Validators.required, Validators.maxLength(255)]),
      active: this.active,
      description: new FormControl(''),
      acronym: new FormControl('', [Validators.required, Validators.maxLength(20)]),
      type: new FormControl('HARD', Validators.required),
      document: new FormControl('', [Validators.maxLength(255)]),
      skillCompanyUnits: new FormControl('')
    });

    this.companyUnitApiService.listAllObjects(COMPANY_API).subscribe(response => {
      this.companyUnits = response;
    });

    this.name.valueChanges.subscribe(value => {
      if(value != null){
        this.formGroup.controls['name'].patchValue(value);
      }
    });
    this.description.valueChanges.subscribe(value => {
      if(value != null){
        this.formGroup.controls['description'].patchValue(value);
      }
    });
    this.acronym.valueChanges.subscribe(value => {
      if(value != null){
        this.formGroup.controls['acronym'].patchValue(value);
      }
    });
    this.document.valueChanges.subscribe(value => {
      if(value != null){
        this.formGroup.controls['document'].patchValue(value);
      }
    });
    this.companyUnit.valueChanges.subscribe(value => {
      this.companyUnitVerify(value);
    })
  }

  save() {
    this.formGroup.controls['skillCompanyUnits'].patchValue(this.companyUnit.value);
    if(this.isEdit && this.skillId != null) {
      this.skillApiService.updateObject(SKILL_API, this.skillId,  this.formGroup.value).subscribe(_response => {
        this.dialogService.openInformationDialog('message.savedSkill',this.formGroup.get('acronym').value);
        this.clear()
        this.sucessfullySaved.emit(true);
      });
    } else {
      this.skillApiService.saveObject(SKILL_API, this.formGroup.value).subscribe(_response => {
        this.dialogService.openInformationDialog('message.savedSkill',this.formGroup.get('acronym').value);
        this.clear()
        this.sucessfullySaved.emit(true);
      });
    }
  }

  clear(){
    this.isEdit = false;
    this.skillId = null;
    this.formGroup.reset();
    this.formGroup.controls['active'].patchValue(true);
    this.formGroup.controls['type'].patchValue('HARD');
    this.name.patchValue('',{emitEvent:false});
    this.description.patchValue('',{emitEvent:false});
    this.acronym.patchValue('',{emitEvent:false});
    this.document.patchValue('',{emitEvent:false});
    this.companyUnit.patchValue('',{emitEvent:false});
    this.selectedAllCompanyUnit = false;
  }

  fillForm(row:any){
    this.isEdit = true;
    this.skillId = row.id;
    this.formGroup.patchValue(row);
    this.name.patchValue(row.name);
    this.description.patchValue(row.description);
    this.acronym.patchValue(row.acronym);
    this.document.patchValue(row.document);
    if (this.companyUnits.length === row.skillCompanyUnits.length){
      this.addAllProceeding();
    } else {
      this.companyUnit.setValue(row.skillCompanyUnits);
    }
  }

  compareSkillCompanyUnits(option: SkillCompanyUnit, selected: SkillCompanyUnit): boolean {
    return option.companyUnit.id == (selected.companyUnit && selected.companyUnit.id);
  }

  addAllProceeding() {
    const selectedCompanyUnits = [];
    this.filteredCompanyUnits.forEach(companyUnit => selectedCompanyUnits.push({companyUnit}));
    selectedCompanyUnits.unshift({companyUnit: this.allCompanyUnit});
    this.companyUnit.patchValue(selectedCompanyUnits, {emitEvent: false});
    this.selectedAllCompanyUnit = true;
    const selectedCompanyUnitsForm = selectedCompanyUnits;
    selectedCompanyUnitsForm.shift();
    this.formGroup.controls.skillCompanyUnits.patchValue(selectedCompanyUnitsForm);
  }

  companyUnitVerify(value:any){
    let selectedCompanyUnit = value;
    const allSelected = value.find( value => value.companyUnit.id === 0 );
    if(allSelected!= undefined && !this.selectedAllCompanyUnit){
      this.selectedAllCompanyUnit = true;
      this.addAllProceeding();
    } else if(allSelected == undefined && this.selectedAllCompanyUnit){
      this.companyUnit.patchValue('',{emitEvent:false});
      this.selectedAllCompanyUnit = false;
    } else if(value){
      if(this.selectedAllCompanyUnit && this.companyUnits.length+1 > selectedCompanyUnit.length){
        selectedCompanyUnit.shift()
        this.selectedAllCompanyUnit = false;
        this.companyUnit.patchValue(selectedCompanyUnit);
      }
    }
  }

  onInputChange(event: any) {
    const searchInput = event.target.value.toLowerCase();
    if (searchInput) {
      this.filteredCompanyUnits = this.filterS(searchInput);
    } else {
      this.filteredCompanyUnits = this.companyUnits;
    }
  }

  filterS(value: string): CompanyUnit[] {
    const filterValue = value ? value.toLowerCase() : '';
    return this.companyUnits.filter(c => c.companyUnitInfo.toLowerCase().includes(filterValue));
  }

  onOpenChange(searchInput: any) {
    searchInput.value = '';
    this.filteredCompanyUnits = this.companyUnits;
  }
}
