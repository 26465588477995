import { CompanyUnit } from 'src/app/models/company-unit';
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {POSITION_API, POSITION_SITE_API} from 'src/app/api-urls';
import {TableAction} from 'src/app/components/table/table-action';
import {PositionSite} from 'src/app/models/position-site';
import {SearchField} from 'src/app/models/search-field.';
import {TalentApiService} from 'src/app/shared/api.service';
import {Position} from '../../../../../models/position';

@Component({
  selector: 'app-position-course',
  templateUrl: './position.component.html',
  styleUrls: ['./position.component.css']
})
export class PositionCourseComponent implements OnInit {
  formGroup: FormGroup;

  positions: PositionSite[] = [];
  allPositions: Position[] = [];
  positionsFilter: PositionSite[] = [];
  selectedPositions: any[] = [];
  selectedOriginalPositions: PositionSite[] = [];
  selectedPosition: FormControl = new FormControl(undefined);
  checkedMandatory: FormControl = new FormControl(true);
  positionTableAction: TableAction[] = [
    {
      name: 'label.delete',
      icon: 'delete',
      color:'warn',
      do: (row: PositionSite) => {
        this.removePosition(row)
      }
    },
    {
      name: 'label.edit',
      icon: 'edit',
      do: (row: PositionSite) => {
        this.editPosition(row)
      }
    }
  ];
  companyUnitId:any;
  departmentId:any;
  posColumns: string[] = ['positionId', 'positionName', 'companyUnitInfo', 'obligingnessPosition', 'actions'];
  editPositionIndex: any = null;
  editPositionObject: any = null;
  @Output() sucessfullFilled: EventEmitter<any> = new EventEmitter();

  isEdit:boolean= false;
  firstLoading:boolean= false;
  addOrUpdateButtonDisabled = false;

  positionsData: any;
  constructor(private fb: FormBuilder, private positionSiteApiService: TalentApiService<PositionSite>,
              private positionApiService: TalentApiService<Position>) {
      this.formGroup = this.fb.group({
        positions: new FormControl([], Validators.required)
      });
     }

  ngOnInit() {
    this.formGroup.valueChanges.subscribe(value => {
      this.validPositions();
    });
  }

  loadingPositions(companyUnit:any, department:any){
    this.departmentId = department.id;
    this.companyUnitId = companyUnit != null ? companyUnit.id : undefined;
    if(this.companyUnitId != undefined && this.departmentId != undefined){
      this.clear();
      this.addOrUpdateButtonDisabled = false;
      this.posColumns = ['positionId', 'positionName', 'companyUnitInfo', 'obligingnessPosition', 'actions'];
      let fields: SearchField[] = [
        {
          name: 'department',
          value: department.id
        },
        { name: 'companyUnit',value: companyUnit.id}
      ];
      this.positionSiteApiService.searchObjects(POSITION_SITE_API+'/searchByCompanyUnitAndDepartment',fields).subscribe(response => {
        this.positions = response;
        this.positions.sort(this.sort);
        this.positionsFilter = this.positions;
        this.positionsFilter.sort(this.sort);
        this.fillEdit();
      });
    } else {
      this.clear();
      let fields: SearchField[] = [
        {
          name: 'department',
          value: department.id
        }
      ];
      this.positionApiService.searchObjects(POSITION_API+'/searchByDepartment', fields).subscribe(response => {
        this.allPositions = response;
        this.addAllPositions();
      });
    }


  }

  fillEdit(){
    if(this.isEdit){
      this.firstLoading = true;
      this.selectedPosition.patchValue(this.positionsData);
      this.addPosition();
    }
  }

  sort(a: PositionSite, b: PositionSite): -1 | 0 | 1 {
    const valueA: string = a['position'] ? a['position'].name : a['name'];
    const valueB: string = b['position'] ? b['position'].name : b['name'];
    if (valueA.trim().toLowerCase() < valueB.trim().toLowerCase())
      return -1;
    if (valueA.trim().toLowerCase() > valueB.trim().toLowerCase())
      return 1;
    return 0;
  }

  onOpenChange(searchInput: any) {
    searchInput.value = '';
    this.positionsFilter = this.positions;
  }

  onInputChange(event: any) {
    const searchInput = event.target.value.toLowerCase();
    if(searchInput){
      this.positionsFilter = this.filterP(searchInput);
    }else {
      this.positionsFilter = this.positions;
    }
  }

  filterP(value: string): PositionSite[] {
    const filterValue = value ? value.toLowerCase() : '';
    return this.positions.filter(pos => pos.position.name.toLowerCase().includes(filterValue));
  }

  onSelectEvent($event:any, position:PositionSite){
    let selectedPositions = [];
    selectedPositions=this.selectedPosition.value;
    if(selectedPositions && selectedPositions != undefined){
      const positionFound = selectedPositions.find(selectedPosition => selectedPosition.position.id == position.position.id);
      if(positionFound==undefined){
        selectedPositions.push(position);
        this.selectedPosition.patchValue(selectedPositions);
      }
    }
  }

  addPosition() {
    if(this.selectedPosition.value != undefined){
      this.selectedPosition.value.forEach(element => {
        const position = this.positions.find(pos => pos.id === element['id']);
        let coursePosition = null;
        if(position != null){
          coursePosition = {
            id: position.id,
            positionId: position.position.id,
            name: position.position.name,
            positionName: position.position.name,
            companyUnitInfo: position.companyUnit.companyUnitInfo,
            position: position,
            mandatory: this.checkedMandatory.value
          };
        }
        if(this.firstLoading && element.mandatory != null && coursePosition != null){
          coursePosition.mandatory = element.mandatory;
        }
        if (position && coursePosition.id != undefined) {
          this.insertPosition(coursePosition,position);
          this.changetoCoursePosition();
        }
      });
      this.selectedPosition.setValue('');
      this.checkedMandatory.setValue(true);
    }
  }

  removePosition(position: PositionSite) {
    this.selectedPositions.splice(this.selectedPositions.indexOf(position), 1);
    const positionFound = this.selectedOriginalPositions.find(pos => pos.id === position['id']);
    this.selectedOriginalPositions.splice(this.selectedOriginalPositions.indexOf(positionFound), 1);
    this.positions.push(positionFound);
    this.positions.sort(this.sort);
    this.changetoCoursePosition();
  }

  editPosition(position: PositionSite) {
    this.editPositionIndex = this.selectedPositions.indexOf(position);
    this.editPositionObject =  this.selectedOriginalPositions.find(pos => pos.id === position['id']);
    const coursePosition = this.selectedPositions.find(pos => pos.id === position['id']);
    let selectedPosition = [];
    this.positionsFilter.push(this.editPositionObject);
    selectedPosition.push(this.editPositionObject);
    this.selectedPosition.patchValue(selectedPosition);
    this.checkedMandatory.patchValue(coursePosition.mandatory);
  }

  updatePosition() {
    this.firstLoading = false;
    if(this.selectedPosition.value != undefined){
      let coursePosition = null;
        if(this.editPositionObject != null){
          coursePosition = {
            id: this.editPositionObject.id,
            positionId: this.editPositionObject.position.id,
            name: this.editPositionObject.position.name,
            positionName: this.editPositionObject.position.name,
            companyUnitInfo: this.companyUnitId,
            position: this.editPositionObject,
            mandatory: this.checkedMandatory.value
          };
        }
      let selectedPositions = this.selectedPositions;
      selectedPositions.splice(this.editPositionIndex, 1,coursePosition);
      this.selectedPositions = []
      selectedPositions.forEach( element => {
        this.selectedPositions.push(element);
      });
      this.positions.splice(this.positionsFilter.findIndex(positionSite => this.editPositionObject.id == positionSite.id), 1);
      this.selectedPosition.setValue('');
      this.changetoCoursePosition();
      this.editPositionIndex = null;
      this.editPositionObject = null;
      this.checkedMandatory.patchValue(true);
    }
  }

  insertPosition(coursePosition:any,position:any){
    const positionFound = this.selectedPositions.find(selectedPosition => selectedPosition.position.id == coursePosition.position.id);
    if(positionFound == undefined){
      this.selectedPositions.push(coursePosition);
      this.selectedOriginalPositions.push(position)
      this.positions.splice(this.positionsFilter.findIndex(positionSite => position.id == positionSite.id), 1);
    } else {
      this.positions.splice(this.positionsFilter.findIndex(positionSite => position.id == positionSite.id), 1);
    }
  }

  insertAllPositions(allPositions: any) {
    this.selectedPositions.push(allPositions);
  }

  addAllPositions() {
    this.addOrUpdateButtonDisabled = true;
    this.posColumns = ['positionId', 'positionName'];
    this.allPositions.forEach(position => {
      const coursePosition = {
        positionId: position.id,
        positionName: position.name,
        mandatory: this.checkedMandatory.value
      };
      this.insertAllPositions(coursePosition);
    });
    this.selectedPosition.disable();
    this.changetoCoursePosition();
  }

  changetoCoursePosition(){
    const coursePositions = this.selectedPositions.map(position => Object({ 'position': position, 'mandatory': position.mandatory }));
    this.formGroup.controls['positions'].patchValue(coursePositions);
  }

  clear(){
    this.positionsFilter = this.positions;
    this.selectedPositions = [];
    this.selectedPosition.enable();
    this.selectedOriginalPositions = [];
    this.formGroup.reset();
  }

  validPositions(){
    if(this.formGroup.valid){
      this.sucessfullFilled.emit(this.formGroup.value);
    } else {
      this.sucessfullFilled.emit([]);
    }
  }

  fillForm(row){
    this.isEdit = true;
    this.positionsData = row.course.positions;
    this.loadingPositions(row.course.companyUnit, row.course.department);
  }
}
