import { AbstractControl, ValidatorFn, FormGroup } from '@angular/forms';
import * as BrV from 'br-validations';

export function cpfValidator(control: AbstractControl) {
    let value = control.value;
    if(!value) {
        value = '';
    }
    return !BrV.cpf.validate(value) ? {
        cpfInvalido: true
    } : null;

}

export function customEmailValidator(control: AbstractControl){
  const emailRegex = /^[a-zA-Z0-9_%+-]+(?:\.[a-zA-Z0-9_%+-]+)*@kuehne-nagel\.com$/;
  let value = control.value;
  if(value === ''){
    return null
  }else{
    if(emailRegex.test(value)){
      return null
    }else{
      return {
        errors: {
          email:true
        }
      }
    }
  }

}
