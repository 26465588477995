import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DialogService } from '../shared/dialog.service';

@Injectable()
export class CustomHttpRequestInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService, private dialogService: DialogService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.urlWithParams.includes('async=true') ) {
      this.dialogService.showLoader();
    }
    const trainingToken = localStorage.getItem('TrainingToken');
    const token = this.auth.getToken();
    // const customHeaders: HttpHeaders = request.headers;

    let authReq;
    if (token || trainingToken) {
      if (request.url.includes('download')) {
        authReq = request.clone({
          setHeaders: {
            Authorization: 'Bearer ' + token,
            'X-TrainingHeader': trainingToken || '',
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept,X-TrainingHeader',
            // 'Access-Control-Allow-Methods': 'GET, POST ,PUT,PATCH,DELETE',
            responseType: 'blob',
            observe: 'body'
          }
        });
      } else if (request.url.includes('upload')) {
        authReq = request.clone({
          setHeaders: {
            Authorization: 'Bearer ' + token,
            'X-TrainingHeader': trainingToken || '',
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept,X-TrainingHeader',
            // 'Access-Control-Allow-Methods': 'GET, POST ,PUT,PATCH,DELETE',
            enctype: 'multipart/form-data'
          }
        });
      } else {
        authReq = request.clone({
          setHeaders: {
            Authorization: 'Bearer ' + token,
            'X-TrainingHeader': trainingToken || '',
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            // 'Access-Control-Allow-Methods': 'GET, POST',
            // 'Content-Type': 'application/json',
            // 'Accept': 'application/json'
          }
        });
      }
    } else {
      if (request.url.includes('download')) {
        authReq = request.clone({
          setHeaders: {
            Authorization: 'Bearer ' + token,
            'X-TrainingHeader': trainingToken || '',
            // 'Content-Type': 'application/octet-stream',
            responseType: 'blob',
            observe: 'body'
          }
        });
      } else if (request.url.includes('upload')) {
        authReq = request.clone({
          setHeaders: {
            'X-TrainingHeader': trainingToken || '',
            Authorization: 'Bearer ' + token,
            enctype: 'multipart/form-data'
          }
        });
      } else {
        authReq = request.clone();
      }
    }
    return next.handle(authReq).pipe(
      tap((ev: HttpEvent<any>) => {
        if (ev instanceof HttpResponse) {
          if (!request.urlWithParams.includes('async=true')) { this.dialogService.hideLoader(); }
        }
      },
      (err: any) => {
        if (!request.urlWithParams.includes('async=true')) { this.dialogService.hideLoader(); }
      }));
  }
}
