import { COURSE_API, RESOURCE_API, TRAINING_API, COMPANY_API, DEPARTMENT_API, PRINT_PRESENCE_LIST, DOWNLOAD_FILE, ATTACHMENTS_API } from './../../api-urls';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Inject, ViewChild, AfterViewInit, AfterContentChecked } from '@angular/core';
import { TalentApiService } from 'src/app/shared/api.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatAutocompleteTrigger } from '@angular/material';
import { DialogService } from 'src/app/shared/dialog.service';
import { startWith, map, tap } from 'rxjs/operators';
import { TableAction } from 'src/app/components/table/table-action';
import { trainingPresence, statusTypes } from 'src/app/util-json.data';
import { InputComponent } from 'src/app/components/dialog/input/input.component';
import { FileValidator } from 'ngx-material-file-input';
import { CompanyUnit } from 'src/app/models/company-unit';
import { Department } from 'src/app/models/department';
import { Course } from 'src/app/models/course';
import { Training } from 'src/app/models/training';
import { Resource } from 'src/app/models/resource';
import { Attachment } from 'src/app/models/attachment';
import { ResourcePresence } from 'src/app/models/resource-presence';
import { TrainingPresenceService } from 'src/app/shared/training-presence.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-training-dialog',
  templateUrl: './training-dialog.component.html',
  styleUrls: ['./training-dialog.component.css']
})
export class TrainingDialogComponent implements OnInit {

  title: string = 'title.addTraining';
  extraTitle: string;

  formGroup: FormGroup;

  selected = new FormControl(0);
  hasLoaded = false;


  type = new FormControl('', Validators.required);
  fromPlanningDate = new FormControl('', [Validators.required]);
  toPlanningDate = new FormControl('', [Validators.required]);
  expireDate = new FormControl('', [Validators.required]);

  courseDescription = new FormControl('');
  skillsDescription = new FormControl('');
  selectedCourse = new FormControl('', [Validators.required]);
  course: any;

  selectedResource = new FormControl('');
  selectedInstructor = new FormControl('', [Validators.required]);
  companyUnit = new FormControl('', [Validators.required]);
  department = new FormControl('', [Validators.required]);

  presenceList = new FormControl(undefined, [Validators.required, FileValidator.maxContentSize(35913941)]);

  trainingDaysQuantity = new FormControl(1, [Validators.required]);
  daySelected = new FormControl(1, [Validators.required]);

  companyUnits: CompanyUnit[] = [];
  departments: Department[] = [];

  courses: Course[] = [];

  resources: Resource[] = [];
  resourcesAll: Resource[] = [];

  instructors: Resource[] = [];
  loadingInstructors: boolean = true;
  fillingForms: boolean = true;

  types = statusTypes;

  selectedResources: Resource[] = [];

  presenceResources: ResourcePresence[] = []

  filteredCourses: Course[] = [];
  filteredResources: Resource[] = [];
  filteredInstructors: Observable<Resource[]>;

  existing: Training;

  resColumns = ['registry', 'resourceName', 'positionId', 'positionName', 'actions'];
  resTableAction: TableAction[] = [
    {
      name: 'label.delete',
      icon: 'highlight_off',
      color: 'warn',
      do: (row) => {
        this.removeResource(row)
      }
    },
  ];

  presenceLists: any[] = [];
  presenceListColumns = ['uploadDate', 'fileName', 'actions'];
  presenceListTableAction: TableAction[] = [
    {
      name: 'label.download',
      icon: 'cloud_download',
      color: '',
      do: (row) => {
        this.downloadList(row)
      }
    },
    {
      name: 'label.delete',
      icon: 'delete',
      color: 'warn',
      do: (row) => {
        this.removeList(row)
      }
    }
  ];

  isUpdate;

  trainingDaysCount: number[] = [];

  presenceResourcesColumns;
  presenceResourceAction: TableAction[] = [
    {
      name: 'label.markPresence',
      icon: 'done',
      color: '',
      do: (row) => {
        this.markPresence(row.id)
      }
    },
    {
      name: 'label.markAbsence',
      icon: 'clear',
      color: '',
      do: (row) => {
        this.markAbsence(row.id)
      }
    },
  ]
  trainingPresence = trainingPresence;

  hasTrainingDays = false;

  constructor(
    private fb: FormBuilder,
    private attachmentApiService: TalentApiService<Attachment>,
    private companyUnitApiService: TalentApiService<CompanyUnit>,
    private departmentApiService: TalentApiService<Department>,
    private courseApiService: TalentApiService<Course>,
    private trainingApiService: TalentApiService<Training>,
    private resourceApiService: TalentApiService<Resource>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<TrainingDialogComponent>,
    private dialogService: DialogService,
    private trainingPresenceService: TrainingPresenceService,
    private translate: TranslateService,
    private http: HttpClient
  ) {
    if(data){
      if (data.selectedDate) this.fromPlanningDate.setValue(this.data.selectedDate);
      else if (data) {
        this.existing = data;
        this.isUpdate = true;
      }
    }
  }

  ngOnInit() {
    this.presenceResourcesColumns = [
      'label.registry',
      'label.resourceName',
      'label.positionId',
      'label.positionName',
      'style.present',
      'actions'
    ];

    this.formGroup = this.fb.group({
      id: null,
      course: this.selectedCourse,
      type: this.type,
      fromPlanningDate: this.fromPlanningDate,
      toPlanningDate: this.toPlanningDate,
      expireDate: this.expireDate,
      instructor: null,
      resources: null,
      companyUnit: this.companyUnit,
      department: this.department
    });

    this.courseDescription.disable();
    this.skillsDescription.disable();

    this.getResources();

    forkJoin(
      this.courseApiService.listAllObjects(COURSE_API),
      this.companyUnitApiService.listAllObjects(COMPANY_API),
      this.departmentApiService.listAllObjects(DEPARTMENT_API)
    ).subscribe(([courses, companyUnits, departments]) => {
      this.courses = courses.sort((a,b)=> a.name.localeCompare(b.name));
      this.companyUnits = companyUnits;
      this.departments = departments;

      this.filteredCourses = this.courses;

      if (this.existing) {
        this.getTrainingById();
      }

      this.refreshFilter();
    });

    this.companyUnit.valueChanges.subscribe(value => {
      if (value) {
        this.filteredResources = this.resources.filter(resource =>
          value.id ? resource['companyUnitId'] == value.id : false
        );
        this.filteredCourses = this.courses.filter(course => course.companyUnit.id == value.id);
      } else {
        this.filteredResources = [];
        this.filteredCourses = this.courses;
      };
    });

    this.selectedCourse.valueChanges.subscribe(value => {
      if (value) {
        let course = this.courses.find(course => course.id == value.id);
        this.course = course;
        this.generateCourseDescription(course);
        if (course && course.frequency && this.toPlanningDate.value && !this.fillingForms) {
          this.expireDate.setValue(this.getNewExpirationDate(this.toPlanningDate.value, course.frequency));
        }
      } else this.generateCourseDescription(null);
      if (!this.existing || (this.existing['selectedDate'] || this.expireDate.value == this.existing.expireDate)) this.fillingForms = false;
    });

    this.toPlanningDate.valueChanges.subscribe((value: Date) => {
      if (value && this.selectedCourse.value && !this.fillingForms) {
        let course = this.courses.find(course => course.id == this.selectedCourse.value.id);
        this.expireDate.setValue(this.getNewExpirationDate(value, course.frequency));
      }
      if (!this.existing || (this.existing['selectedDate'] || this.expireDate.value == this.existing.expireDate)) this.fillingForms = false;
    });

    // this.type.valueChanges.subscribe(value => {
    // 	if(value && value == "Realizado") {
    // 		this.selectedResources.map(resource => {
    // 			resource.skillProficiencies = [];
    // 			this.selectedCourse.value.skills.map(skill => {
    // 				resource.skillProficiencies.push({skill: {id: skill.id}, proficiency: {id: 1}, expireDate: this.expireDate.value});
    // 			});
    // 			// resource.skillProficiencies = this.selectedCourse.value.skills ? this.selectedCourse.value.skills : [];
    // 			// resource.skillProficiencies.map(skillProficiency => {
    // 			// 	skillProficiency['skillId'] = skillProficiency.id;
    // 			// 	delete skillProficiency.id;
    // 			// 	skillProficiency.proficiencyId = 1;
    // 			// 	skillProficiency.expireDate = this.expireDate.value;
    // 			// 	return skillProficiency;});
    // 			return resource;
    // 		});
    // 	}
    // });
  }

  getTrainingById() {
    this.trainingApiService.getObjectById(TRAINING_API, this.existing.id).subscribe(data => {
      this.existing = data;

      this.formGroup.patchValue(this.existing);
      this.presenceLists = this.existing.attachments ? this.existing.attachments : [];

      if (this.existing.course) {
        this.generateCourseDescription(this.existing.course);
        this.selectedCourse.setValue(this.existing.course);
      }

      this.companyUnit.setValue(this.existing.companyUnit);

      this.title = 'title.editTraining'
      this.extraTitle = 'ID #' + this.existing.id;

      this.filteredResources = this.resources.filter(resource =>
        companyUnitId ? resource['companyUnitId'] == companyUnitId : false
      );

      this.filteredResources = this.resources;
      if (this.existing.resources) {
        this.selectedResources = this.existing.resources;
        this.existing.resources.forEach((resource: Resource) => {
          this.filteredResources.splice(this.filteredResources.findIndex(
            filteredResource => filteredResource.id == resource.id), 1);
        });
      }
      this.filteredResources.sort(this.sort);

      if (this.existing.instructor) {
        this.selectedInstructor.setValue(this.existing['instructorName']);
      }

      let companyUnitId: number;
      if (this.existing.companyUnit) {
        companyUnitId = this.existing.companyUnit.id;
        if (!this.existing.companyUnit.active) {
          this.companyUnit.disable();
          this.companyUnits.push(this.existing.companyUnit);
        }
      }

      this.attachmentApiService.getObjectById(ATTACHMENTS_API, this.existing.id).subscribe(response => {
        if (response.length > 0) {
          this.presenceList.setValue(response);
        }
      });

    });
  }

  generateCourseDescription(course) {
    let skillsDescription: string = '';
    let courseDescription: string = '';
    if (course) {
      courseDescription = course.description;
      if (course.skills) {
        for (let skill of course.skills) {
          skillsDescription += `\u2022 ${skill.name}\n`;
        }
      }
    }
    this.courseDescription.setValue(courseDescription);
    this.skillsDescription.setValue(skillsDescription);
  }

  printPresenceList() {
    if (this.existing && this.existing.resources && this.selectedResources.length) {
      this.dialogService.openCustomDialog(InputComponent, true, { inputName: 'label.fileName' }).afterClosed().subscribe(response => {
        let url = PRINT_PRESENCE_LIST + this.existing.id + '/' + response;
        this.http.get<any>(url,{responseType: 'blob' as 'json'}).subscribe( data => {
          const blob = new Blob([data], {type: 'application/pdf'})

          var downloadURL = window.URL.createObjectURL(data);
           var link = document.createElement('a');
          link.href = downloadURL;
          link.download = response + '.pdf';
          link.click();

        });
      });
    } else if (this.selectedResources.length) {
      this.dialogService.openConfirmDialog('error.trainingNotSaved')
        .afterClosed().subscribe(confirm => {
          if (confirm) {
            let training = this.getFormValueValidated(this.formGroup);

            if (training) {
              this.trainingApiService.saveObject(TRAINING_API, training).subscribe(_savedObject => {
                this.existing.resources = this.selectedResources;
                this.formGroup.patchValue(this.existing);
                this.printPresenceList();
              });
            } else this.dialogService.openAlertDialog('error.requiredValidationFail');
          }
        });
    } else (this.dialogService.openAlertDialog('error.trainingWithoutResource'));
  }

  addResources() {
    this.selectedResource.value.forEach((resource: Resource) => {
      const _resource_ = this.resources.find(_resource => _resource.id === resource.id);
      this.selectedResources.push(_resource_);
      this.filteredResources.splice(this.filteredResources.findIndex(resources => resource.id == resources.id), 1);
    });
    this.selectedResource.setValue('');
    this.refreshFilter();
    this.save(0, false);
  }

  removeResource(resource: Resource) {
    this.selectedResources.splice(this.selectedResources.findIndex(
      selectedResource => selectedResource.id == resource.id), 1);
    this.filteredResources.push(resource);
    this.filteredResources.sort(this.sort);
    this.refreshFilter();
    this.save(0, false);
  }

  uploadList() {
    const file = this.presenceList.value.files[0];
    const data: FormData = new FormData();

    data.append('training', this.existing.id.toString());
    data.append('file', file, file.name);

    this.attachmentApiService.uploadFile(data).subscribe(response => {
      this.dialogService.openInformationDialog('message.fileUploaded');
      this.formGroup.get('type').setValue('Realizado');
      this.presenceLists.push(response);
      this.presenceLists = [...this.presenceLists];
    });
    this.presenceList.setValue(undefined);
    this.presenceList.markAsUntouched();
  }

  removeList(list) {
    this.dialogService.openConfirmDialog('message.removeFile', list.fileName)
      .afterClosed().subscribe(confirm => {
        if (confirm) {
          this.attachmentApiService.deleteFile(list).subscribe(() => {
            this.dialogService.openInformationDialog('message.removedFile');
            this.presenceLists.splice(this.presenceLists.map(l => l.id).indexOf(list.id), 1);
            this.presenceLists = [...this.presenceLists];
          });
        }
      });

  }

  downloadList(list) {
    let url = DOWNLOAD_FILE + list.id + '/' + 'testUser';
    this.http.get<any>(url,{responseType: 'blob' as 'json'}).subscribe( data => {
      const blob = new Blob([data], {type: 'application/pdf'})

      var downloadURL = window.URL.createObjectURL(data);
       var link = document.createElement('a');
      link.href = downloadURL;
      link.download = 'presenceList.pdf';
      link.click();

    });
  }

  save(selected, withMessage) {
    let training = this.formGroup.value;
    if (this.expireDate.disabled) training.expireDate = this.expireDate.value;

    training.resources = this.selectedResources;
    training.course = this.course;
    training.instructor = this.instructors.find(s => s.name === this.selectedInstructor.value);
    training.attachments = this.presenceLists;
    this.existing = training;

    this.trainingApiService.saveObject(TRAINING_API, training).subscribe((response) => {
      this.existing.id = response.id;
      this.getTrainingById();
      if (withMessage) {
        this.dialogService.openInformationDialog('message.savedTraining');
      }
    });
    if (!this.isUpdate && selected !== 0) {
      this.selected.setValue(selected);
    }

  }

  close(): void {
    this.dialogRef.close();
  }

  sendMessage() {
    this.dialogService.openInformationDialog('message.savedTraining');
  }

  disableSave: boolean;

  changeTab(event) {
    if(event.index == 3){
      this.loadPresences();
    }
    if (event.index == 4) {
      this.trainingPresenceService.triggerEnoughPresencesVerify();
      this.disableSave = true;
    } else {
      this.disableSave = false;
    }
  }

  //
  @ViewChild(MatAutocompleteTrigger) triggerC;
  @ViewChild(MatAutocompleteTrigger) triggerR;
  @ViewChild(MatAutocompleteTrigger) triggerI;

  clickAutoC() {
    this.triggerC._onChange('');
    this.triggerC.openPanel();
  }

  clickAutoR() {
    this.triggerR._onChange('');
    this.triggerR.openPanel();
  }

  clickAutoI() {
    this.triggerR._onChange('');
    this.triggerR.openPanel();
  }

  filterC(value: string): Course[] {
    const filterValue = (value) ? value.toLowerCase() : '';
    return this.courses.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  filterR(value: string): Resource[] {
    const filterValue = (value) ? value.toLowerCase() : '';
    return this.resources.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  filterI(value: string): Resource[] {
    const filterValue = (value) ? value.toLowerCase() : '';
    return this.instructors.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  refreshFilter() {
    this.selectedResources = [...this.selectedResources];

    this.resources = this.resources.filter(resource => this.selectedResources.map(res => res.name).indexOf(resource.name) < 0);
    this.resources = [...this.resources];

    this.filteredInstructors = this.selectedInstructor.valueChanges.pipe(
      startWith(''),
      map(value => this.filterI(value))
    );
  }

  compareFun: ((f1: any, f2: any) => boolean) | null = this.compareByValue;

  compareByValue(f1: any, f2: any) {
    return f1 && f2 && f1.name === f2.name;
  }

  getNewExpirationDate(toPlanningDate: Date, frequency: String): Date {
    let newDate: Date = new Date(toPlanningDate);
    newDate.setDate(frequency == 'Anual' ? newDate.getDate() + 365 :
      (frequency == 'Semestral' ? newDate.getDate() + 182 : newDate.getDate()));
    if (frequency == 'Único') {
      newDate.setFullYear(newDate.getFullYear() + 1000);
      this.expireDate.disable();
    };
    return newDate;
  }

  getFormValueValidated(formGroup: FormGroup): any {
    let value: any = formGroup.value;
    value.resources = this.selectedResources;
    value.course = this.course;
    value.instructor = this.instructors.find(s => s.name === this.selectedInstructor.value);
    for (let formControl in value) {
      if (formControl !== 'id') {
        if (!value[formControl]) return undefined;
      }
    }
    return value;
  }

  async getResources() {
    this.resourceApiService.listAllObjects(RESOURCE_API + '?async=true').subscribe(response => {
      this.resources = response;
      this.resourcesAll = this.resources;
      this.instructors = this.resources;
      this.filteredResources = this.resources;
      if (this.existing && this.existing.companyUnit && this.existing.companyUnit.id) {
        this.filteredResources = this.resources.filter(resource =>
          this.existing.companyUnit.id ? resource['companyUnitId'] == this.existing.companyUnit.id : false
        );
      }
      this.filteredResources.sort(this.sort)
    },
      () => this.loadingInstructors = false,
      () => this.loadingInstructors = false);
  }

  sort(a: Resource, b: Resource): -1 | 0 | 1 {
    if (a.name.trim().toLowerCase() < b.name.trim().toLowerCase())
      return -1;
    if (a.name.trim().toLowerCase() > b.name.trim().toLowerCase())
      return 1;
    return 0;

  }

  nextStep(value) {
    if (!this.isUpdate) {
      this.selected.setValue(value);
    } else {
      this.dialogService.openInformationDialog('message.savedTraining');
      this.close();
    }
  }

  saveTrainingDaysQuantity(){
    this.trainingPresenceService.createTrainingDays(
      this.trainingDaysQuantity.value,
      `${this.existing.id}`
    ).subscribe(response => {
      this.hasTrainingDays = true;
      this.loadPresences();
    });
  }

  handleChangesOnPresences(event){
    if(event.isUserInput){
      this.trainingPresenceService.getPresencesByDay(`${this.existing.id}`,event.source.value).subscribe(
        (response: any) => {
          this.presenceResources = response.presences.map(({resource, isPresent}:any) => {
            console.log(isPresent);
            return new ResourcePresence(
              resource.registry,
              resource.name,
              resource.positionId,
              resource.positionName,
              isPresent? 'P': 'A',
              resource.id
            );
          }).sort( (a: ResourcePresence,b: ResourcePresence) => {
            if(a.resourceName < b.resourceName) return -1;
            if(b.resourceName < a.resourceName) return 1;
            return 0;
          })
        }
      );
    }

  }

  private createTrainingDaysCount(count){
    const numberArray = [];
    for(let i = 1; i<=count;i++){
      numberArray.push(i);
    }
    return numberArray.map(ele => ele);
  }

  loadPresences(){
      this.trainingPresenceService.countDays(`${this.existing.id}`).subscribe(
        (resp: any) => {
          this.hasLoaded = true;
          this.hasTrainingDays = true;
          this.trainingDaysCount = [];
          this.trainingDaysCount = this.createTrainingDaysCount(resp.trainingDaysCount);
          this.trainingPresenceService.getPresencesByDay(`${this.existing.id}`,this.daySelected.value).subscribe(
            (response: any) => {
              this.presenceResources = response.presences.map(({resource, isPresent}:any) => {
                return new ResourcePresence(
                  resource.registry,
                  resource.name,
                  resource.positionId,
                  resource.positionName,
                  isPresent? 'P': 'A',
                  resource.id
                );
              }).sort( (a: ResourcePresence,b: ResourcePresence) => {
                if(a.resourceName < b.resourceName) return -1;
                if(b.resourceName < a.resourceName) return 1;
                return 0;
              })
            }
          );
        },
        error => {
          this.hasTrainingDays = false;
        }
      );


  }

  markPresence(resourceId){
    this.trainingPresenceService.markPresence(resourceId,this.existing.id,this.daySelected.value).subscribe(response => {
      this.loadPresences();
    },error => {
      this.dialogService.openAlertDialog("error.presenceFail");
    });
  }

  markAbsence(resourceId){
    this.trainingPresenceService.markAbsence(resourceId,this.existing.id,this.daySelected.value).subscribe(response => {
      this.loadPresences();
    },error => {
      this.dialogService.openAlertDialog("error.presenceFail");
    });
  }

  async havePresenceEnough(resourceId){
    return this.trainingPresenceService.haveEnoughPresences(resourceId,this.existing.id).toPromise().then((response:any) => response.haveEnoughPresence);
  }

  sendEmailToInstructor(){
    this.trainingPresenceService.sendAccessToTrainingInstructor(this.existing.id)
      .subscribe(response => {
        this.dialogService.openInformationDialog('message.emailSended')
      }, error => {
        this.dialogService.openAlertDialog('error.emailSendedFailed')
      });
  }
}
