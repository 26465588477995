import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/auth.service';
import { DialogService } from 'src/app/shared/dialog.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm = this.fb.group({
    email: ['',Validators.email]
  })

  isFirstAccess = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.route.queryParams.pipe(take(1)).subscribe(queryParams => {
      this.isFirstAccess = queryParams['first-access'] === 'true';
    })

  }

  sendResetPasswordEmail(){
    const lang = localStorage.getItem('lang');
    this.authService.requestSendResetPasswordEmail(this.forgotPasswordForm.value.email,lang).subscribe( success => {
      this.dialogService.showCustomPositionAlertDialog('500px','280px','message.resetPasswordEmail');
      this.router.navigate(['/login']);
    }, error => {
      switch (error.status){
        case 404:
          this.dialogService.showCustomPositionAlertDialog('500px','280px','error.userWithEmailNotFound');
          break;
      }
    });
  }

  enterSubmit(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.sendResetPasswordEmail();
    }
  }
}
