import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.css']
})
export class InformationComponent implements OnInit {
  extraMessage: any;
  showComplementaryMessage:boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
  public dialogRef: MatDialogRef<InformationComponent>) {
    this.extraMessage = { value: data.extraMessage };
    if(data.complementaryMessage != undefined){
      this.showComplementaryMessage = true;
    }
   }

  ngOnInit(): void {
   }

   closeDialog() {
    this.dialogRef.close(false);
  }
}
