import { COMPANY_API } from './../../api-urls';
import { BaseRegistrationComponent } from './../../components/base-registration/base-registration.component';
import { UnitDialogComponent } from './../../dialogs/unit-dialog/unit-dialog.component';
import { DialogService } from './../../shared/dialog.service';
import { Component } from '@angular/core';
import { TalentApiService } from 'src/app/shared/api.service';
import { CompanyUnit } from '../../models/company-unit';

@Component({
  selector: 'app-unit',
  templateUrl: './unit.component.html',
  styleUrls: ['./unit.component.css']
})
export class UnitComponent extends BaseRegistrationComponent<CompanyUnit> {

  columns: string[] = ['reference', 'costCenter', 'site', 'description', 'actions'];

  constructor(dialogService: DialogService, apiService: TalentApiService<CompanyUnit>) {
    super(dialogService, apiService);
    this.editComponent = UnitDialogComponent;
    this.objectApi = COMPANY_API;
    this.objectType = 'CompanyUnit';
  }

}
