import { NavigationExtras, Router } from '@angular/router';
import { Component } from '@angular/core';
import { COURSE_API } from './../../../api-urls';
import { BaseRegistrationComponent } from './../../../components/base-registration/base-registration.component';
import { CourseDialogComponent } from './../../../dialogs/course-dialog/course-dialog.component';
import { DialogService } from './../../../shared/dialog.service';
import { TalentApiService } from 'src/app/shared/api.service';
import { AuthService } from 'src/app/shared/auth.service';
import { TableAction } from 'src/app/components/table/table-action';
import { Course } from 'src/app/models/course';
import { BreadcrumbAction } from 'src/app/components/breadcrumb/breadcrumb-action';

@Component({
  selector: 'app-curso',
  templateUrl: './curso.component.html',
  styleUrls: ['./curso.component.css']
})
export class CursoComponent extends BaseRegistrationComponent<Course> {

  columns: string[] = ['reference', 'courseName','type','frequency','duration','departmentName', 'companyUnitInfo', 'actions'];
  paths: string[] = ['title.mainRegistration','/','title.course'];
  breadcrumbActions: BreadcrumbAction[] = [
    {
      label: 'label.back',
      router: '..',
      style: 'generic-button'
    },
    {
      label: 'label.add',
      style: 'generic-button',
      do: (row) => {
				this.create()
			}
    }
  ]
  courseActions:  TableAction[] = [
		{
			name: 'label.edit',
			icon: 'create',
			color: '',
			do: (row) => {
				this.edit(row)
			}
		},
		{
			name: 'label.delete',
			icon: 'delete',
			color: 'warn',
			roles: ['ROLE_ADMIN'],
			do: (row) => {
				this.delete(row)
			}
		}
	];

  constructor(dialogService: DialogService, apiService: TalentApiService<Course>, authService: AuthService, router:Router) {
    super(dialogService, apiService,true);
    this.editComponent = null;
    this.objectApi = COURSE_API;
    this.objectType = 'Course';
    this.user = authService.getUser();
    this.router = router;
  }

  edit(row) {
    this.router.navigateByUrl(`main/registration/curso/create/${row.id}`);
  }
}
