import {DialogService} from '../../shared/dialog.service';
import {TalentApiService} from 'src/app/shared/api.service';
import {Subject} from 'rxjs';
import {
  Component,
  EventEmitter,
  Input, OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  CalendarEvent,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';
import {isSameDay, isSameMonth} from 'date-fns';
import {TranslateService} from '@ngx-translate/core';
import {MatMenuTrigger} from '@angular/material';
import {InputComponent} from 'src/app/components/dialog/input/input.component';
import {TRAINING_PLAN_REPORT} from 'src/app/api-urls';
import {AuthService} from 'src/app/shared/auth.service';
import {User} from 'src/app/models/user';
import {Router} from '@angular/router';
import {TrainingHelpService} from '../../shared/training-help.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css'],
})
export class CalendarComponent implements OnInit{
  @Input() hideNavigations = false;
  @Input() hideViews = false;
  @Input() calendarView: CalendarView = CalendarView.Month;
  @Input() events: CalendarEvent[] = [];
  @Input() rolePermission: boolean = false;
  @Output() monthChange = new EventEmitter<string>();

  x;
  y;

  user: User;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  refresh: Subject<any> = new Subject();

  locale = 'pt-br';
  dateFormat: string;
  activeDayIsOpen: boolean = false;

  token = this.authService.getToken();

  actualMonth = new Date().getMonth();

  constructor(
    private translate: TranslateService,
    private dialogService: DialogService,
    private apiService: TalentApiService<void>,
    private authService: AuthService,
    private trainingHelpService: TrainingHelpService,
    private router: Router
  ) {
    this.locale = translate.store.currentLang;
    translate.onLangChange.subscribe((res) => {
      this.locale = res.lang;
    });

    document.onmousedown = this.onmouse;
    this.user = authService.getUser();
  }

  ngOnInit() {
    this.trainingHelpService.registerOpenCalendarCallback(() => {
      this.isInStep = true;
      const emittedValue = {
        day: {
          badgeTotal: 0,
          date: new Date(),
          events: this.events,
          inMonth: true,
          isFuture: false,
          isPast: false,
          isToday: true,
          isWeekend: false,
        },
      };
      this.monthView.dayClicked.emit(emittedValue);
    }, () => {
      this.isInStep = false;
      const emittedValue = {
        day: {
          badgeTotal: 0,
          date: new Date(),
          events: this.events,
          inMonth: true,
          isFuture: false,
          isPast: false,
          isToday: true,
          isWeekend: false,
        },
      };
      this.monthView.dayClicked.emit(emittedValue);
    });
  }
  setDate(date) {
    this.viewDate = date;
  }

  onmouse = (e) => {
    this.x = e.target.offsetLeft;
    this.y = e.target.offsetTop;
  };

  @ViewChild('monthView') monthView;

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  openMenu() {
    var menu = document.getElementById("menuButton");
    menu.style.display = "";
    menu.style.position = "absolute";
    menu.style.left = this.x + "px";
    menu.style.top = this.y + "px";

    this.trigger.openMenu();
  }

  addTraining() {
    this.router.navigate([
      "/main",
      "registration",
      "training-info",
      { selectedDate: this.selectedDate },
    ]);
  }

  addTrainingDate(day: any) {
    this.router.navigate([
      "/main",
      "registration",
      "training-info",
      { selectedDate: day.date },
    ]);
  }

  onMenuClosed(): void {
    var menu = document.getElementById("menuButton");
    if (menu) {
      menu.style.display = "none";
    }
  }

  selectedDate;

  set isInStep(isInStep: boolean){
    this._isInStep = isInStep;
  }

  get isInStep(){
    return this._isInStep;
  }

  _isInStep = false;

  dayClicked(event): void {
    console.log(event.day.date);
    if (event.day.events.length != 0) {
      if (
        this.user.roles.includes("ROLE_ADMIN") ||
        this.user.roles.includes("ROLE_MANAGER") ||
        this.user.roles.includes("ROLE_ANALYST") ||
        this.user.roles.includes("ROLE_INSTRUCTOR") ||
        this.user.roles.includes("ROLE_SITE_MANAGER_INSTRUCTOR") ||
        this.user.roles.includes('ROLE_MANAGER_ASSISTANT')
      ) {
        if (isSameMonth(event.day.date, this.viewDate)) {
          this.viewDate = event.day.date;
          if (
            (isSameDay(this.viewDate, event.day.date) &&
              this.activeDayIsOpen === true) ||
            event.length === 0
          ) {
            this.activeDayIsOpen = false;
          } else {
            this.activeDayIsOpen = true;
            this.selectedDate = event.day.date;
          }
        }
      }
    } else {
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent("Dropped or resized", event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    event.meta.click(event);
  }

  setView(view: CalendarView) {
    this.calendarView = view;
  }

  thisMonth() {
    this.monthChange.emit(this.format(this.viewDate));
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
    if (this.viewDate.getMonth() !== this.actualMonth) {
      this.monthChange.emit(this.format(this.viewDate));
      this.actualMonth = this.viewDate.getMonth();
    }
  }

  getViewColor(view) {
    if (view === this.calendarView) {
      return "accent";
    }

    return "";
  }

  generatePdf() {
    this.dateFormat = this.format(this.viewDate);
    this.dialogService
      .openCustomDialog(InputComponent, true, {
        title: "label.generatePdf",
        subtitle: "label.subtitleGenerateFile",
        inputName: "label.fileName",
        subtitleRightButton: "label.generate",
      })
      .afterClosed()
      .subscribe((fileName) => {
        if (!!fileName) {
          this.apiService.buildUrl(
            TRAINING_PLAN_REPORT +
              "/calendar/pdf/" +
              fileName +
              "/" +
              this.dateFormat,
            [],
            fileName,
            "pdf"
          );
        }
      });
  }

  generateExcel() {
    this.dateFormat = this.format(this.viewDate);
    this.dialogService
      .openCustomDialog(InputComponent, true, {
        title: "label.generateExcel",
        subtitle: "label.subtitleGenerateFile",
        subtitleRightButton: "label.generate",
        inputName: "label.fileName",
      })
      .afterClosed()
      .subscribe((fileName) => {
        if (!!fileName) {
          this.apiService.buildUrl(
            TRAINING_PLAN_REPORT +
              "/calendar/xlsx/" +
              fileName +
              "/" +
              this.dateFormat,
            [],
            fileName,
            "xlsx"
          );
        }
      });
  }

  format(date: Date): string {
    let day: string = date.getDate().toString();
    day = +day < 10 ? "0" + day : day;
    let month: string = (date.getMonth() + 1).toString();
    month = +month < 10 ? "0" + month : month;
    let year = date.getFullYear();
    return year + "-" + month + "-" + day;
  }

  getStatus(eventos: any) {
    let realizados: number = 0;
    let programados: number = 0;
    let cancelados: number = 0;

    eventos.forEach((evento) => {
      if (evento.meta.training.status == "Realizado") {
        realizados++;
      }
      if (evento.meta.training.status == "Programado") {
        programados++;
      }
      if (evento.meta.training.status == "Cancelado") {
        cancelados++;
      }
    });
    return {
      realizados: realizados,
      programados: programados,
      cancelados: cancelados,
    };
  }

  isCurrentMonth(date: any) {
    return date.inMonth;
  }

  isToday(date: Date) {
    return isSameDay(date, new Date());
  }
}
