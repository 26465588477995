import { AUTH_URL, LOGIN_URL } from './utils.const';
import { Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';
import * as jwt_decode from 'jwt-decode';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../models/user';
import { Store } from '@ngrx/store';
import { AppState, getUserData } from '../store/app.states';
import { TRAINING_API } from '../api-urls';

@Injectable()
export class AuthService {



  set token(token: string) {
    localStorage.setItem('token', token);
  }

  get token() {
    return localStorage.getItem('token');
  }

  private username: string;

  private currentUser: User;

  constructor(private http: HttpClient, private store: Store<AppState>) {
    this.store.select(getUserData).subscribe((response: User) => {
      if (response) { this.saveUserDetails(response); }
    });
  }

  canOpenTraining(trainingId) {
    return this.http.get(`${TRAINING_API}/${trainingId}/can-open?username=${this.currentUser.username}`).pipe(take(1));
  }

  public login(username: string, password: string) {
    const requestParam = {
      username,
      password
    };

    return this.http.post(LOGIN_URL, requestParam, { headers: this.generateOptions() }).pipe(
      map((response: any) => {
        return {isAuthenticated: true, user: this.deserializeToken(response), errorMessage: null};
      })
    );
  }

  resendFirstAccessEmail(email: any, lang: string) {
    return this.http.post<any>(`${AUTH_URL}/password/first-access/email?language=${lang}`, email).pipe(take(1));
  }

  requestSendResetPasswordEmail(email: any, lang: string) {
    return this.http.post<any>(`${AUTH_URL}/password/request?language=${lang}`, email).pipe(take(1));
  }

  changePassword(password: string, token: string) {
    return this.http.put<any>(`${AUTH_URL}/password`, {password, token}).pipe(take(1));
  }

  verifyToken(token: string) {
    return this.http.post<any>(`${AUTH_URL}/password/verify`, token).pipe(take(1));
  }

  public logout(): void {
    this.token = null;
    this.username = null;
    // this.userId = null;
  }

  public getToken(): string {
    return this.token;
  }

  public getUser(): User {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  public getRoles(): string[] {
    return this.currentUser.roles;
  }

  private deserializeToken(response: {username: string, token: string}): User {
    if (response && response.token) {
      const token = response.token;
      const claims = this.getTokenClaims(token);
      claims.token = token;
      return new User(claims.sub, null, claims.token, claims.role);
    }
  }

  private saveUserDetails(user?: User): void {
    this.token = user.token || null;
    this.username = user.username || null;
    this.currentUser = user;
  }

  private getTokenClaims(token: string): any {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }

  private generateOptions() {
    const headers = new HttpHeaders();

    // headers.append('Access-Control-Allow-Origin', '*');
    // headers.append('Access-Control-Allow-Headers', 'Origin, Authorization, Content-Type');

    return headers;
  }

  getIsAuthenticated() {
    return localStorage.getItem('authenticated') === 'true';
  }

  setIsAuthenticated(isAuthenticated: boolean) {
    localStorage.setItem('authenticated', isAuthenticated ? 'true' : 'false');
  }

  setUser(user: User) {
    this.currentUser = user;
    localStorage.setItem('currentUser', JSON.stringify(user));
  }

  clearSession(){
    window.localStorage.clear();
    location.reload();
  }
}
