import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { BreadcrumbAction } from "src/app/components/breadcrumb/breadcrumb-action";
import { DialogService } from "src/app/shared/dialog.service";
import { TrainingApiService } from "src/app/shared/training-api.service";
import { NavigationHeaderComponent } from "../navigation-header/navigation-header.component";
import { FormBuilder, FormControl } from "@angular/forms";
import {TrainingHelpService} from '../../../shared/training-help.service';

export interface ResourcesGradesProjection {
  registry: string;
  resourceId: number;
  resourceName: string;
  proficiencyLevel: number;
  isCompletedInt: number;
  enoughPresence: boolean;
  isCompleted: boolean;
  enoughPresenceInt: number;
}

export interface ResourcesGradesResponse {
  status: string;
  resourceGradesProjections: ResourcesGradesProjection[];
}

export interface ResourcesGrades {
  enoughPresence: string;
  enoughPresenceInt: number;
  formResourceGrade: FormControl;
  isCompleted: boolean;
  isCompletedInt: number;
  proficiencyLevel: number;
  registry: string;
  resourceId: number;
  resourceName: string;
}

@Component({
  selector: "app-training-grades",
  templateUrl: "./training-grades.component.html",
  styleUrls: ["./training-grades.component.css"],
})
export class TrainingGradesComponent implements OnInit, OnDestroy {
  trainingId: number;

  resourcesGrades: ResourcesGrades[];
  originalResourcesGrades: ResourcesGrades[];
  trainingStatus: string;

  filter: FormControl = new FormControl("");

  saveSubject = new Subject<any>();
  saveSucessfullySubject = new Subject<any>();

  receivedNotifications = 0;
  breadcrumbActions: BreadcrumbAction[] = [
    {
      label: "label.close",
      style: "cancel-button",
      router: "/main/calendar",
    },
    {
      label: "label.update",
      style: "generic-button",
      do: () => {
        this.saveTraining();
      },
    },
    {
      label: "label.finishTraining",
      style: "clear-button second-step-help",
      do: () => {
        this.finishTraining();
      },
    },
  ];
  countSubject: number = 0;
  countTotalResources: number;
  hasSavedObject: boolean = false;

  displayedColumns: string[] = [
    "registry",
    "resourceName",
    "enoughPresence",
    "resourceGrade",
  ];

  proficiencies = [
    { id: 1, description: "1 - Não sabe" },
    { id: 2, description: "2 - Acompanhar" },
    { id: 3, description: "3 - Executa" },
    { id: 4, description: "4 - Executa e multiplica" },
    { id: 5, description: "5 - Apto para sinergia" },
  ];

  constructor(
    private routeSnapshot: ActivatedRoute,
    private trainingApi: TrainingApiService,
    private dialogService: DialogService,
    private form: FormBuilder,
    private trainingHelpService: TrainingHelpService,
    private router: Router
  ) {}

  ngOnDestroy() {
    this.trainingHelpService.disable();
  }

  ngOnInit() {
    this.trainingHelpService.enable('GRADES')
    this.trainingId = this.routeSnapshot.snapshot.params.id;
    this.updateNavigateHeader(this.trainingId);
    this.trainingApi
      .getResourcesGrades(this.trainingId)
      .subscribe((response: ResourcesGradesResponse) => {
        this.trainingStatus = response.status;
        this.changeButtonsVisibility();
        this.originalResourcesGrades = response.resourceGradesProjections.map(
          (resource: ResourcesGradesProjection) => {
            const translatedValue = resource.enoughPresence ? "sim" : "não";
            const form = this.form.control(resource.proficiencyLevel);
            return {
              ...resource,
              enoughPresence: translatedValue,
              formResourceGrade: form,
            };
          }
        );
        this.resourcesGrades = this.originalResourcesGrades;
        this.countTotalResources = this.resourcesGrades.length;
      });

    this.filter.valueChanges.subscribe((filter) => {
      const value = filter.toLowerCase();

      if (value !== "") {
        this.resourcesGrades = this.originalResourcesGrades.filter(
          (resource) =>
            resource.resourceName.toLowerCase().includes(value) ||
            resource.registry.includes(value) ||
            resource.enoughPresence.includes(value)
        );
      } else {
        this.resourcesGrades = this.originalResourcesGrades;
      }
    });
  }

  changeButtonsVisibility() {
    this.breadcrumbActions[1].disabled = this.trainingStatus === "Realizado";
    this.breadcrumbActions[2].disabled = this.trainingStatus === "Realizado";
  }

  finishTraining() {
    const resoucesToSend = this.fillResourcesToSend();
    this.trainingApi.checkFinish(this.trainingId, resoucesToSend).subscribe({
      next: (value) => {
        this.dialogService
        .openConfirmInnerHtmlDialog({ message: "message.finishTrainingInfo" })
        .afterClosed()
        .subscribe((persist) => {
          if (persist) {
            this.requestSaveOrFinishTraining(true);
          }
        });
      },
      error: (err) => {
        this.dialogService.openInformationDialog("message.finishTrainingConflict");
      },
    })
  }

  saveTraining() {
    this.requestSaveOrFinishTraining(false);
  }

  requestSaveOrFinishTraining(isFinishing: boolean) {
    const resoucesToSend = this.fillResourcesToSend();

    const action = isFinishing
      ? this.trainingApi.finishTraining(this.trainingId)
      : this.trainingApi.saveResourcesGrades(this.trainingId, resoucesToSend);

    const message = isFinishing
      ? "message.trainingSuccessfullyFinished"
      : "message.gradesSaved";

      action.subscribe({
      next: () => {
        this.dialogService
          .openInformationDialog(message)
          .afterClosed()
          .subscribe(() => {
            this.router.navigate(["/main", "calendar"]);
          });
      },
      error: (err) => {
        this.dialogService.openInformationDialog("error.persistingData");
      },
    });
  }

  fillResourcesToSend() {
    return this.resourcesGrades
      .filter(
        (resourceGrade: ResourcesGrades) =>
          resourceGrade.enoughPresence == "sim" &&
          !!resourceGrade.formResourceGrade.value
      )
      .map((resourceGrade) => {
        return {
          resourceId: resourceGrade.resourceId,
          gradeId: resourceGrade.formResourceGrade.value,
        };
      });
  }

  compareById = (a, b) => a && b && a === b;

  @ViewChild(NavigationHeaderComponent) navHeader: NavigationHeaderComponent;
  updateNavigateHeader(idTraining) {
    this.navHeader.changetoUpdate(idTraining);
  }
}
