import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-alert-skill',
  templateUrl: './alert-skill.component.html',
  styleUrls: ['./alert-skill.component.css']
})
export class AlertSkillComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AlertSkillComponent>) { }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close(false);
  }
}
