import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/auth.service';
import { DialogService } from 'src/app/shared/dialog.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  //1 Letra maiúscula, 1 letra minúscula, 1 número, 1 caractere especial e no mínimo 6 caracteres
  private pattern = '(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!.%*?&])[A-Za-z\\d@$!%*?&.]{6,}';
  isFirstAccess = false;
  changePasswordForm = this.fb.group({
    password: ['',Validators.pattern(this.pattern)],
    confirmPassword: ['',Validators.pattern(this.pattern)]
  },{validators: this.checkPasswords});

  hidePassword = true;
  hideConfirmPassword = true;
  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private router: Router) { }

  ngOnInit() {
    this.changePasswordForm.valueChanges.subscribe(value => console.log(this.changePasswordForm.errors))
    console.log("procurando parâmetros")
    this.route.queryParams.pipe(take(1)).subscribe( params => {
      this.isFirstAccess = params['first-access'] === 'true';
    })
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    const password = group.get('password').value;
    const confirmPassword = group.get('confirmPassword').value;
    return password === confirmPassword ? null : { notSame: true, }
  }

  changePassword(){
    console.log("procurando parâmetros")
    this.route.queryParams.pipe(take(1)).subscribe( params => {
      console.log("encontrado parâmetros",params)
      this.auth.changePassword(this.changePasswordForm.value.password, params['token']).subscribe(success => {
        this.dialogService.openInformationDialog('message.passwordChangedSucessful');
        this.router.navigate(['/login']);
      }, error => {
        switch (error.status) {
          case 412:
            this.dialogService.showCustomPositionAlertDialog('500px', '280px',
              this.isFirstAccess ? 'error.expiredLinkFirstAccess' : 'error.expiredLinkResetPassword');
            break;
        }
      });
    })
  }
  enterSubmit(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.changePassword();
    }
  }
}
