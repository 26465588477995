import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { RESOURCE_API, SKILL_COMPANY_UNIT_API, TRAINING_API } from '../api-urls';
import { Resource } from '../models/resource';

@Injectable({
  providedIn: 'root'
})
export class ResourceApiService {

  constructor(private http: HttpClient) { }

  createResource(value: any) {
    return this.http.post<any>(`${RESOURCE_API}/general-info`,value,{responseType: 'text' as 'json'});
  }

  updateResource(id: number, value: any) {
    return this.http.put<any>(`${RESOURCE_API}/${id}/general-info`,value);
  }

  updatePositionHistory(id: any, period: { fromDate: any; toDate: any; }) {
    return this.http.put<any>(`${RESOURCE_API}/position-history/${id}`,period);
  }

  findAlocableResources(instructorName: string, trainingId: number){
    const params = new HttpParams()
      .append('name',instructorName)
      .append('async','true')
      .append('trainingId',`${trainingId}`);
    return this.http.get<Resource[]>(`${RESOURCE_API}`,{params});
  }

  findResourceCompetenceById(id) {
    return this.http.get<any>(`${RESOURCE_API}/${id}/competence`);
  }

  findInstructorsCandidatesBySimilarity(instructorName: string){
    return this.http.get<Resource[]>(`${TRAINING_API}/instructors?name=${instructorName}&async=true`);
  }

  findResourceByUsername(username: string){
    return this.http.get<Resource>(`${RESOURCE_API}/username/${username}`);
  }

  findResourcesByCompanyUnit(companyUnitId: string){
    return this.http.get<Resource[]>(`${RESOURCE_API}/companyUnit/${companyUnitId}`);
  }
  findResourceByRegistry(registry: string, userAlreadyRegistered: boolean){
    if(userAlreadyRegistered){
      return this.http.get<Resource>(`${RESOURCE_API}/registry/${registry}?alreadyRegistered=true`).pipe(take(1))
    }else{
      return this.http.get<Resource>(`${RESOURCE_API}/registry/${registry}`).pipe(take(1))
    }
  }

  findAll(): Observable<Resource[]> {
    return this.http.get<Resource[]>(RESOURCE_API);
  }

  findResourceById(id: number) {
    return this.http.get<any>(`${RESOURCE_API}/${id}/general-info`);
  }

  findPositionHistoryFromId(id: any) {
    return this.http.get<any>(`${RESOURCE_API}/${id}/position-history`);
  }

  findAllSkillByResource(id: number){
    return this.http.get<any>(`${SKILL_COMPANY_UNIT_API}/resource/${id}`);
  }

  createSearchCompetence(value: any) {
    return this.http.post<any>(`${RESOURCE_API}/competence`,value);
  }

  clearSearchCompetence(id: number){
    return this.http.delete<any>(`${RESOURCE_API}/${id}/competence`);
  }

  getRegistry(){
    return this.http.get(`${RESOURCE_API}/getRegistry`, {responseType: 'text'});
  }
}
