import { OnInit, Component, ViewChild } from '@angular/core';
import { TRAINING_MATRIX_REPORT, PRINT_TRAINING_MATRIX } from 'src/app/api-urls';
import { SearchField } from 'src/app/models/search-field.';
import { TalentApiService } from 'src/app/shared/api.service';
import { DialogService } from 'src/app/shared/dialog.service';
import { InputComponent } from 'src/app/components/dialog/input/input.component';
import { HeaderData } from 'src/app/components/cross-table/cross-table.component';
import { trainingMatrixTypes } from 'src/app/util-json.data';
import { MatPaginator } from '@angular/material';
import { downloadFromUrl } from 'src/app/shared/downloader';
import { AuthService } from 'src/app/shared/auth.service';
import { HttpClient } from '@angular/common/http';
import { BreadcrumbAction } from 'src/app/components/breadcrumb/breadcrumb-action';

@Component({
	selector: 'app-training-matrix',
	templateUrl: './training-matrix.component.html',
    styleUrls: ['./training-matrix.component.css']
})
export class TrainingMatrixComponent implements OnInit {
  paths: string[] = ['menu.reports','/','reportMenu.courseMatrix']
  breadcrumbActions: BreadcrumbAction[] = [
    {
			label: 'label.exportExcel',
      style: 'generic-button',
			do: () => {
				this.generateExcel();
			}
		},
    {
      label: 'label.back',
      router: '..',
      style: 'generic-button'
    },
  ]
	searchUrl: string = TRAINING_MATRIX_REPORT;

	trainings: any = [];
	pagedTrainings: any = [];
	actualTrainingPage: any = [];
	subtitles = trainingMatrixTypes;
	columns: string[] = [];
	columnsGroup: HeaderData[] = [];
	columnsSubgroup: HeaderData[] = [];
	@ViewChild(MatPaginator) paginator: MatPaginator;
	filter: SearchField[];
	filterFields = [
		'companyUnitRequired',
		'department',
		'position',
		'resource'
	];

	ngOnInit() { }
	constructor(
		private apiService: TalentApiService<void>,
		private dialogService: DialogService,
		private authService: AuthService,
		private http: HttpClient) { }

	token = this.authService.getToken();

	results(response) {
    if(response.result.data!=undefined){
      let result = response.result;
      this.filter = response.filter;

      this.columns = [];
      this.trainings = [];

      if (Array.isArray(result['data']) && result['data'].length) {
        // this.columns = ['resourceName', 'modality', 'positionName'].concat(result['subgroup'].map(headerData => headerData.skills ? headerData.skills.map(skill => skill.label) : 'Course without Skill'));
        this.columnsGroup = result['group'];
        this.columnsSubgroup = result['subgroup']

        this.trainings = result['data'];
        for (let resourceData of this.trainings) {
          for (let skillInfo of resourceData.skills) {
            resourceData[skillInfo.identifier] = skillInfo['label'];
          }
        }
        if (this.trainings.length < 10) {
          this.pagedTrainings[0] = this.trainings;
        } else {
          let j = this.trainings.length / 10;
          let c = Math.floor(j);
          for (let i = 0; i < c; i++) {
            this.pagedTrainings[i] = this.trainings.slice(i * 10, i * 10 + 10);
          }
          if (j % 1 != 0) {
            this.pagedTrainings[c] = this.trainings.slice(c * 10, this.trainings.length);
          }
        }
        this.actualTrainingPage = this.pagedTrainings[0];
        if (this.paginator != undefined) {
          this.paginator.firstPage();
        }
      }
    }else{
      this.clear();
      this.dialogService.openInformationDialog('reportMenu.emptyData');
    }
	}

	pageChange() {
		let index = this.paginator.pageIndex;
		this.actualTrainingPage = this.pagedTrainings[index];
	}

	generateExcel() {
		this.dialogService.openCustomSizeDialog('390px',InputComponent, true, {
      title: 'label.generateExcel',
      subtitle: 'label.subtitleGenerateFile',
      subtitleRightButton: "label.generate",
      inputName: 'label.fileName'
    }).afterClosed().subscribe(fileName => {
      if(!!fileName)
			 this.apiService.buildUrl(TRAINING_MATRIX_REPORT + "/xlsx/" + fileName, this.filter,fileName,'xlsx');

		})
	}

	generatePdf() {
		this.dialogService.openCustomDialog(InputComponent, true, { inputName: 'label.fileName' }).afterClosed().subscribe(fileName => {
      if(!!fileName)
			 this.apiService.buildUrl(TRAINING_MATRIX_REPORT + "/pdf/" + fileName, this.filter,fileName,'pdf');
		});
	}

  clear(){
    this.columns = [];
		this.trainings = [];
  }
}
