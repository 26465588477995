import { COURSE_API, COMPANY_API, POSITION_SKILL_SITE_API } from './../../api-urls';
import { Observable, forkJoin, merge } from 'rxjs';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { TalentApiService } from 'src/app/shared/api.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatAutocompleteTrigger, MatOption, MatOptionSelectionChange } from '@angular/material';
import { PositionDialogComponent } from '../position-dialog/position-dialog.component';
import { DialogService } from 'src/app/shared/dialog.service';
import { startWith, map } from 'rxjs/operators';
import { POSITION_SITE_API, SKILL_API, DEPARTMENT_API } from 'src/app/api-urls';
import { TableAction } from 'src/app/components/table/table-action';
import { PositionSite } from 'src/app/models/position-site';
import { CompanyUnit } from 'src/app/models/company-unit';
import { Department } from 'src/app/models/department';
import { Course } from 'src/app/models/course';
import { Skill } from 'src/app/models/skill';
import { SearchField } from 'src/app/models/search-field.';

@Component({
  selector: 'app-course-dialog',
  templateUrl: './course-dialog.component.html',
  styleUrls: ['./course-dialog.component.css']
})
export class CourseDialogComponent implements OnInit {

  title: string = 'title.addCourse';
  extraTitle: string;

  frequencies = [
    'Anual',
    'Semestral',
    'Único'
  ];

  types = [
    'Geral',
    'Específico'
  ];

  existing;
  formGroup: FormGroup;
  reference: FormControl = new FormControl('', Validators.required);
  name: FormControl = new FormControl('', Validators.required);
  type: FormControl = new FormControl('', Validators.required);
  frequency: FormControl = new FormControl('', Validators.required);
  active: FormControl = new FormControl(true);
  description: FormControl = new FormControl('', Validators.maxLength(3000));
  duration: FormControl = new FormControl(0);

  companyUnit: FormControl = new FormControl();
  companyUnits: CompanyUnit[] = [];
  filteredCompanyUnits: CompanyUnit[] = [];
  selectedCompanyUnit: FormControl = new FormControl('');

  departments: Department[] = [];

  department: FormControl = new FormControl('', Validators.required);

  skills: Skill[] = [];
  selectedSkills: Skill[] = [];
  filteredSkills: Observable<Skill[]>;

  positions: PositionSite[] = [];
  positionsFilter: PositionSite[] = [];
  dropdownPositions: PositionSite[] = [];

  mandatory: FormControl = new FormControl(true);

  selectedPositions: any[] = [];
  //coursePositions: any[] = [];

  selectedSkill: FormControl = new FormControl('');
  selectedPosition: FormControl = new FormControl([]);
  choicedPositions: FormControl = new FormControl([]);

  skillTableAction: TableAction[] = [
    {
      name: 'label.delete',
      icon: 'highlight_off',
      color: 'warn',
      do: (row: Skill) => {
        this.removeSkill(row)
      }
    },
  ];

  positionTableAction: TableAction[] = [
    {
      name: 'label.delete',
      icon: 'highlight_off',
      color: 'warn',
      do: (row: PositionSite) => {
        this.removePosition(row)
      }
    },
  ];

  columns: string[] = ['name', 'actions'];
  posColumns: string[] = ['positionId', 'positionName', 'companyUnitName', 'obligingness', 'actions'];

  constructor(
    private fb: FormBuilder,
    private positionSiteApiService: TalentApiService<PositionSite>,
    private skillApiService: TalentApiService<Skill>,
    private companyUnitService: TalentApiService<CompanyUnit>,
    private departmentApiService: TalentApiService<Department>,
    private courseApiService: TalentApiService<Course>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PositionDialogComponent>,
    private dialogService: DialogService
  ) {
    this.existing = data;
  }

  ngOnInit() {
    this.formGroup = this.fb.group({
      id: null,
      reference: this.reference,
      name: this.name,
      type: this.type,
      department: this.department,
      companyUnit: this.selectedCompanyUnit,
      frequency: this.frequency,
      duration: this.duration,
      active: this.active,
      description: this.description,
      //mandatory: this.mandatory,
      skills: [],
      positions: []
    });

    this.formGroup.controls["type"].valueChanges.subscribe(value => {
      if(value === 'Geral'){
        this.companyUnit.setValue('');
        this.companyUnit.disable();
        this.positionSiteApiService.listAllObjects(POSITION_SKILL_SITE_API).subscribe(response => {
          this.positions = response;
          this.positions.sort(this.sort);
          this.selectedCompanyUnit.setValue(null);
        });
        this.addAllPositions();
      }else if(value === 'Específico'){
        this.companyUnit.enable();
        this.removeAllPositions();
      }
    });

    forkJoin(
      this.departmentApiService.listAllObjects(DEPARTMENT_API),
      this.companyUnitService.listAllObjects(COMPANY_API),
      this.skillApiService.listAllObjects(SKILL_API+'/linking-panel'),
    ).subscribe(([departments,companyUnits,skills]) => {
      this.departments = departments;
      this.companyUnits = companyUnits;
      this.filteredCompanyUnits = this.companyUnits;
      this.skills = skills;
      this.skills.sort(this.sort);
    });

    merge(this.selectedCompanyUnit.valueChanges, this.department.valueChanges).subscribe(() => {
      if(this.selectedCompanyUnit.value && this.department.value) {
        let fields: SearchField[] = [
          {
            name: 'department',
            value: this.department.value.id
          },
          { name: 'companyUnit',value: this.selectedCompanyUnit.value.id}
        ];
        this.positionSiteApiService.searchObjects(POSITION_SITE_API+'/searchByCompanyUnitAndDepartment',fields).subscribe(response => {
          this.positions = response;
          this.positionsFilter = this.positions;
        });
      }
    });

    this.companyUnit.valueChanges.subscribe(companyUnit => {
      if(companyUnit && companyUnit.id == undefined){
        this.filteredCompanyUnits = this.filterCompanyUnit(companyUnit);
      } else if (companyUnit && companyUnit.id != undefined){
        this.selectedCompanyUnit.setValue(companyUnit);
      }
    });

    if (this.existing) {
      this.formGroup.patchValue(this.existing);
      this.title = 'title.editCourse'
      this.extraTitle = 'ID #' + this.existing.id;

      let departmentId: number;
      if (this.existing.department) {
        departmentId = this.existing.department.id;
      }

      let companyUnitId: number;
      if (this.existing.companyUnit) {
        companyUnitId = this.existing.companyUnit.id;
        this.companyUnit.setValue(this.existing.companyUnit);
      } else{
        this.selectedCompanyUnit.setValue(null);
      }

      this.positionsFilter = this.positions.filter(positionSite =>
        companyUnitId
        ? positionSite.companyUnit.id === companyUnitId
        : false
      );
      if (this.existing.skills) {
        this.selectedSkills = this.existing.skills;
      }

      if (this.existing.positions) {
        this.selectedPositions = this.existing.positions;
        this.selectedPositions.forEach(positionSite => {
          this.positionsFilter.splice(this.positionsFilter.findIndex(_positionSite => _positionSite.id == positionSite.id), 1);
        });
        this.refreshFilter();
      }
    }
    this.refreshFilter();
  }

  addSkill() {
    const skill = this.skills.find(s => s.name === this.selectedSkill.value);
    if (this.selectedSkill.value && skill) {
      this.selectedSkills.push(skill);
      this.selectedSkill.setValue('');
      this.refreshFilter();
    }
  }

  addPosition() {
    this.selectedPosition.value.forEach(element => {
      const position = this.positions.find(pos => pos.id === element['id']);
      const coursePosition = {
        id: position.id,
        positionId: position.position.id,
        name: position.position.name,
        positionName: position.position.name,
        companyUnitName: position.companyUnit.name,
        position: position,
        mandatory: true
      };

      if (position && !this.selectedPositions.filter(pos => pos.id == position.id).length) {
        /*position['mandatory'] = this.mandatory.value;
        position['mandatoryString'] = position['mandatory'] ? 'X' : '-';*/
        this.selectedPositions.push(coursePosition);
        //this.coursePositions.push(coursePosition);
        this.selectedPosition.setValue('');
        this.refreshFilter();
        this.positionsFilter.splice(this.positionsFilter.findIndex(positionSite => position.id == positionSite.id), 1);
      } //else this.selectedPosition.setValue('');
    });
  }

  addAllPositions() {
    this.positions.forEach( position => {
      const coursePosition = {
        id: position.id,
        positionId: position.position.id,
        name: position.position.name,
        positionName: position.position.name,
        companyUnitName: position.companyUnit.name,
        position: position,
        mandatory: true
      };
      this.selectedPositions.push(coursePosition);
    });
    this.positionsFilter = [];
    this.selectedPosition.disable();
    this.refreshFilter();
  }

  removeAllPositions(){
    this.positionsFilter = this.positions;
    this.selectedPositions = [];
    this.selectedPosition.enable();
    this.refreshFilter();
  }

  close(): void {
    this.dialogRef.close();
  }

  removeSkill(skill: Skill) {
    this.selectedSkills.splice(this.selectedSkills.indexOf(skill), 1);
    this.skills.push(skill);
    this.skills.sort(this.sort);
    this.refreshFilter();
  }

  removePosition(position: PositionSite) {
    this.selectedPositions.splice(this.selectedPositions.indexOf(position), 1);
    this.positionsFilter.push(this.positions.find(_position => _position.id == position.id));
    this.positionsFilter.sort(this.sort);
    this.refreshFilter();
  }

  save() {
    let course = this.formGroup.value;
    course.skills = this.selectedSkills;
    console.log(course);
    course.coursePositions = this.selectedPositions.map(position => Object({ 'position': position, 'mandatory': position.mandatory }));
    this.courseApiService.saveObject(COURSE_API, course).subscribe(_response => {
      this.dialogService.openInformationDialog('message.savedCourse',course.reference);
      this.close();
    });
  }

  refreshFilter() {
    this.selectedSkills = [...this.selectedSkills];
    this.selectedPositions = [...this.selectedPositions];

    this.skills = this.skills.filter(item => this.selectedSkills.map(s => s.name).indexOf(item.name) < 0);
    this.skills = [...this.skills];

    this.filteredSkills = this.selectedSkill.valueChanges.pipe(
      startWith(''),
      map(value => this.filterS(value))
    );
  }

  // TODO: Criar componente utilitario para workarounds
  @ViewChild(MatAutocompleteTrigger) trigger;

  clickAuto() {
    this.trigger._onChange('');
    this.trigger.openPanel();
  }

  @ViewChild(MatAutocompleteTrigger) trigger2;

  clickAuto2() {
    this.trigger2._onChange('');
    this.trigger2.openPanel();
  }

  compareFun: ((f1: any, f2: any) => boolean) | null = this.compareByValue;

  compareByValue(f1: any, f2: any) {
    return f1 && f2 && f1.name === f2.name;
  }

  filterS(value: string): Skill[] {
    const filterValue = value ? value.toLowerCase() : '';
    return this.skills.filter(skill => skill.name.toLowerCase().includes(filterValue) || (skill.skillCompanyUnits &&
      skill.skillCompanyUnits.some(skillCompanyUnit => skillCompanyUnit.companyUnit.id == this.companyUnit.value.id)));
  }

  filterP(value: string): PositionSite[] {
    const filterValue = value ? value.toLowerCase() : '';
    return this.positions.filter(pos => pos.position.name.toLowerCase().includes(filterValue));
  }

  filterCompanyUnit(value: string): CompanyUnit[] {
    const filterValue = value ? value.toLowerCase() : '';
    return this.companyUnits.filter(companyUnit => companyUnit.name.toLowerCase().includes(filterValue));
  }

  sort(a: PositionSite | Skill, b: PositionSite | Skill): -1 | 0 | 1 {
    const valueA: string = a['position'] ? a['position'].name : a['name'];
    const valueB: string = b['position'] ? b['position'].name : b['name'];
    if (valueA.trim().toLowerCase() < valueB.trim().toLowerCase())
      return -1;
    if (valueA.trim().toLowerCase() > valueB.trim().toLowerCase())
      return 1;
    return 0;
  }

  onInputChange(event: any) {
    const searchInput = event.target.value.toLowerCase();
    if(searchInput){
      this.positionsFilter = this.filterP(searchInput);
    }else {
      this.positionsFilter = this.positions;
    }
  }

  onOpenChange(searchInput: any) {
    searchInput.value = '';
    this.positionsFilter = this.positions;
  }

  displayCompanyUnit(value: any){
    return value? value.name : '';
  }

  onSelectEvent($event:any, position:PositionSite){
    let selectedPositions = [];
    selectedPositions=this.selectedPosition.value;
    if(selectedPositions && selectedPositions != undefined){
      const positionFound = selectedPositions.find(selectedPosition => selectedPosition.position.id == position.position.id);
      if(positionFound==undefined){
        selectedPositions.push(position);
        this.selectedPosition.patchValue(selectedPositions);
      }
    }
  }
}
