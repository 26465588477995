import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { concatMap, debounceTime } from 'rxjs/operators';
import { USER_REGISTER_API } from 'src/app/api-urls';
import { UserRegister } from 'src/app/models/user-register';
import { TalentApiService } from 'src/app/shared/api.service';
import { DialogService } from 'src/app/shared/dialog.service';
import { ResourceApiService } from 'src/app/shared/resource-api.service';
import { UsersService } from 'src/app/shared/users.service';

@Component({
  selector: 'app-user-register-dialog',
  templateUrl: './user-register-dialog.component.html',
  styleUrls: ['./user-register-dialog.component.css']
})
export class UserRegisterDialogComponent implements OnInit {

  title: string = 'title.addUser';
  extraTitle: string = '';

  userRegisterForm = this.fb.group({
	  username: ['',Validators.required],
	  role: ['',Validators.required],
	  registry: ['',Validators.required],
	  resource: ['', Validators.required],
	  selectedResource: [{}, Validators.required]
  })

  existing: UserRegister;
  roles = [
    {value: 'ROLE_MANAGER',name: 'label.manager'},
    {value: 'ROLE_MANAGER_ASSISTANT',name: 'label.managerAssistant'},
    {value: 'ROLE_ADMIN',name: 'label.admin'},
    {value: 'ROLE_INSTRUCTOR',name: 'label.instructor'},
	  {value: 'ROLE_SITE_MANAGER',name: 'label.siteManager'},
	  {value: 'ROLE_SITE_MANAGER_INSTRUCTOR',name: 'label.siteManagerInstructor'}
  ];

	constructor(
    	private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<UserRegisterDialogComponent>,
		private dialogService: DialogService,
		private resourceService: ResourceApiService,
		private userService: UsersService
	) {
	}

	ngOnInit() {
		if(this.data){
			this.resourceService.findResourceByRegistry(this.data.registry,true).subscribe( response => {
				this.userRegisterForm.get('resource').setValue(response.name);
				this.userRegisterForm.get('selectedResource').setValue(response);
				this.userRegisterForm.get('username').setValue(this.data.username);
				this.userRegisterForm.get('role').setValue(this.data.role);
				this.userRegisterForm.get('registry').setValue(this.data.registry,{emitEvent: false});
			});
		}
		this.userRegisterForm.get('registry')
			.valueChanges
			.pipe(
				debounceTime(2000),
			)
			.subscribe( value => {
				this.resourceService.findResourceByRegistry(value,false).subscribe( response => {
            if(response.hasUsername){
                this.dialogService.openAlertDialog('error.resourceAlredyRegistered',undefined,'error.resourceAlredyRegisteredSubtitle');
            }else{
                this.userRegisterForm.get('resource').setValue(response.name);
                this.userRegisterForm.get('selectedResource').setValue(response);
                const username = this.validUsername(response.email);
                if(username){
                    this.userRegisterForm.get('username').setValue(username);
                } else {
                    this.userRegisterForm.get('username').setValue('');
                    this.dialogService.openAlertDialog('error.resourceEmailNotFound',undefined,'error.resourceEmailNotFoundSubtitle');
                }
            }
				},
				error => {
					this.dialogService.openAlertDialog('error.resourceRegistryNotFound');
				});
			})
	}

    validUsername(value:string){
        if(value != null || value != undefined){
            const email = value.split('@');
            if(email[1] == 'kuehne-nagel.com'){
                return email[0];
            }
        }
        return '';
    }

	save() {
		if(this.data){
			const { username, role, selectedResource } = this.userRegisterForm.value;
			this.userService.update({username,role,id: this.data.id}).subscribe( value => {
				this.dialogService.openInformationDialog("message.savedUser",username);
				this.close();
			}, error => {
				this.dialogService.openAlertDialog("error.unableRegisterUser");
			});
		}else{
			const { username, role, selectedResource } = this.userRegisterForm.value;
			this.userService.save({username,role,resourceId: selectedResource.id}).subscribe( value => {
				this.dialogService.openInformationDialog("message.savedUser",username,"message.savedUserSubtitle");
				this.close();
			}, error => {
				this.dialogService.openAlertDialog("error.unableRegisterUser");
			});
		}
	}

	close(): void {
		this.dialogRef.close();
	}

}
