import { ChartDataSets, RadialChartOptions, ChartType } from 'chart.js';
import { Component, OnChanges, Input } from '@angular/core';
import { DialogService } from 'src/app/shared/dialog.service';
import { Label } from 'ng2-charts';
import { FormControl } from '@angular/forms';
import { PositionSite } from 'src/app/models/position-site';
import { Resource } from 'src/app/models/resource';
import { SkillCompanyUnit } from 'src/app/models/skill-company-unit';

@Component({
  selector: 'compare-general-radar',
  templateUrl: './compare-general-radar.component.html',
  styleUrls: ['./compare-general-radar.component.css']
})
export class CompareGeneralRadarComponent implements OnChanges {

  @Input() freePosition: PositionSite;
  @Input() radarResources: Resource[];
  @Input() radarPositions: any[];

  radarPositionNames: string[];

  type: FormControl = new FormControl('all');

  constructor(private dialogService: DialogService) { }

  ngOnChanges() {
    this.refreshData('all');
  }

  ngOnInit() {
    this.type.valueChanges.subscribe(value => {
      this.refreshData(value);
    });

    this.refreshData('all');
  }

  getIcon(resource) {
    let icon = {
      'fa fa-frown': resource.performance < 45,
      'fa fa-smile': resource.performance > 85,
      'fa fa-meh': resource.performance > 45 && resource.performance < 85,
    };
    return icon;
  }

  getColor(resource) {
    if (resource.performance < 45) {
      return 'red';
    } else if (resource.performance > 85) {
      return 'lime';
    } else {
      return 'yellow';
    }
  }

  refreshData(skillType: string) {

    if (this.freePosition && this.radarResources && this.freePosition.skillCompanyUnits) {
      this.radarResources.sort((a, b) => b.performance - a.performance);
      let skillsFreePosition = this.freePosition.skillCompanyUnits;
      let skillsResourceRadarPositions = this.radarResources.filter(r => r.skillProficiencies);
      if (skillType !== 'all')
        skillsFreePosition = skillsFreePosition.filter(s => s['skillType'] === skillType.toUpperCase());

      this.radarPositionNames = Array.from(new Set(this.radarResources.map(r => r.position.position.name)));
      this.chartLabels = skillsFreePosition.map(s => s['skillAcronym']);

      let positionData: SkillCompanyUnit[] = skillsFreePosition;

      let positionSkillsId: number[] = skillsFreePosition.map(s => s['skillId']);

      this.chartData = [];

      skillsResourceRadarPositions.forEach(resource => {
        let resourceSkillsId = resource.skillProficiencies.map(skillProficiency =>
          skillProficiency.proficiency && skillProficiency.skill && (skillType == 'all' || skillProficiency.skill.type == skillType.toUpperCase()) ?
            { skillId: skillProficiency.skill.id, skillLevel: skillProficiency.proficiency.level } : {});

        this.chartData.push({
          data: positionSkillsId.map(skillId => {
            let matchedSkill = resourceSkillsId.find(resourceSkill => resourceSkill.skillId == skillId);
            if (matchedSkill) {
              return matchedSkill.skillLevel;
            } else {
              return 1;
            }
          }),
          label: resource.name,
          pointRadius: 3,
        });
      });

      this.chartData.push({
        data: positionData.map(s => s.proficiency.level),
        label: this.freePosition.position.name,
        pointRadius: 3,
      });
    }
  }

  chartOptions: RadialChartOptions = {
    responsive: true,
    legend: {
      position: 'bottom',
      display: true,
    },
    tooltips: {
      mode: 'point',
      intersect: true,
      callbacks: {
        label: function (tooltipItem, data) {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';
          if (label) {
            label += ': Nível ';
            label += data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          }
          return label;
        },
      }
    },
    scale: {
      pointLabels: {
        fontSize: 16
      },
      ticks: {
        beginAtZero: true,
        stepSize: 1,
        max: 5
      }
    }
  };

  chartLabels: Label[] = [];
  chartData: ChartDataSets[] = [];
  chartType: ChartType = 'radar';

}


