import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'tooltip',
  template: `
    <div class="tooltip" [style.display]="open ? 'display: block' : 'display: none'">
      {{ title }} | {{ txt }}
    </div>
  `,
  styles: [`
    .tooltip {
      position: absolute;
      background: #222222;
      padding: 30px;
      border-radius: 10px;
      box-shadow: 0 0 2px rgba(0, 0, 0, .3);
      color: rgba(255, 255, 255, .8);
      z-index: 9999;
      cursor: pointer;
      transition: all .2s ease;
    }
  `]
})
export class Tooltip implements OnInit {

  constructor() { }

  @Input() txt: string
  @Input() title: string
  @Input() open: boolean

  ngOnInit() {
    this.open = false;
  }

  openTooltip() {
    this.open = true;
  }

  closeTooltip() {
    this.open = false;
  }

}
