import { COURSE_MATRIX_REPORT, PRINT_COURSE_MASTER_LIST, PRINT_COURSE_MATRIX } from './../../api-urls';
import { Component, OnInit } from '@angular/core';
import { SearchField } from 'src/app/models/search-field.';
import { TalentApiService } from 'src/app/shared/api.service';
import { DialogService } from 'src/app/shared/dialog.service';
import { InputComponent } from 'src/app/components/dialog/input/input.component';
import { downloadFromUrl } from 'src/app/shared/downloader';
import { AuthService } from 'src/app/shared/auth.service';

@Component({
  selector: 'app-course-matrix',
  templateUrl: './course-matrix.component.html',
  styleUrls: ['./course-matrix.component.css']
})
export class CourseMatrixComponent implements OnInit {

  searchUrl = COURSE_MATRIX_REPORT;

  courses: any = [];
  columns: any[] = [];
  filter: SearchField[];
  filterFields = [
    'companyUnitRequired',
    'department',
    'position'
  ];

  constructor(
    private apiService: TalentApiService<void>,
    private dialogService: DialogService,
    private authService: AuthService) { }

  token = this.authService.getToken();

  ngOnInit() {
  }

  results(response) {
    let result = response.result;
    this.filter = response.filter;

    this.columns = [];
    this.courses = [];

    if(Array.isArray(result) && result.length) {
      this.columns.push('course_matrix.courseId');
      this.columns.push('course_matrix.courseName');
      this.columns.push('course_matrix.skillName');

      this.courses = result;
      for(let course of result) {
        if(course.positions) {
          for(let position of course.positions) {
            if(!this.columns.includes(position)) {
              this.columns.push(position);
            }
            course[position] = '\u2713';
          }
        }
      }
    }
  }

  generateExcel() {
    this.dialogService.openCustomDialog(InputComponent, true, { inputName: 'label.fileName' }).afterClosed().subscribe(fileName => {
      const reportUrl = this.apiService.buildUrl(PRINT_COURSE_MATRIX + "/" + fileName, this.filter,fileName,'xls');
    });
  }

}
