import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Input, Inject, Output, Optional } from '@angular/core';
import { EventEmitter } from 'events';

@Component({
  selector: 'base-dialog',
  templateUrl: './base-dialog.component.html',
  styleUrls: ['./base-dialog.component.css']
})
export class BaseDialogComponent {

  @Input() saveClick() { console.log('Not implemented') };
  @Input() title: string;
  @Input() subtitle: string;
  @Input() width: string;
  @Input() height: string;
  @Input() disableSave: boolean = false;
  @Input() buttonRight: string;
  @Input() buttonLeft: string;
  @Input() leftAlignedButtons: boolean = false;

  constructor(public dialogRef: MatDialogRef<BaseDialogComponent>) {
  }

  @Input() close(): void {
    this.dialogRef.close();
  }

}
